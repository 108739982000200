* {
    box-sizing: border-box;
}

.annuitySearchBarTextField input {
    padding: 10px 18px !important;
}

.annuitySearchBarTextField {
    background-color: rgb(241, 241, 241);
}

.annuitySearchBarTextField fieldset {
    border: none;
}

.annuity_application_table_root {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;
    margin: 0 auto;
}

.page_title_annuity_application {
    background: #D9A22E !important;
}

.annuity_application_table {
    /* margin-top: 20px; */
    /* border:1px solid black; */
}

.annuity_application_table {
    white-space: nowrap;
}

.annuity_application_table thead {
    margin-bottom: 10px;
}

.annuity_application_table thead tr {
    border-radius: 50px !important;
}

.annuity_application_table {
    /* margin-top: 20px; */
    border-collapse: separate !important;
    border-spacing: 0 15px !important;
}

.annuity_application_table_head th:nth-of-type(1) {
    border-radius: 8px 0 0 8px;
}

.annuity_application_table_head th:last-of-type {
    border-radius: 0 8px 8px 0;
}

.annuity_application_table_head th {
    background-color: #D9A22E !important;
    color: #fff;
    /* font-weight: 600; */
    font-size: 16px;
    padding: 10px;
    /* border-bottom: 10px solid #fff; */
}

.annuity_application_table_head th:nth-of-type(1) {
    width: 30%;
}

.annuity_application_table_head th:nth-of-type(2) {
    width: 20%;
}

.annuity_application_table_head th:nth-of-type(3) {
    width: 20%;
}

.annuity_application_table_head th:nth-of-type(4) {
    width: 15%;
}

.annuity_application_table_head th:nth-of-type(5) {
    width: 15%;
}


.annuity_application_view_button button {
    background-color: #D9A22E !important;
    color: #fff;
    text-transform: capitalize;
    border-radius: 8px;
    padding: 2px 12px 2px 12px;
    margin-right: 10px;
}

.annuity_application_view_button button:disabled {
    background-color: #074a6e;
    color: #fff;
    opacity: 0.5;
}

.annuity_application_view_button button:hover {
    background-color: #074a6e;
    color: #fff;
    text-transform: capitalize;
    border-radius: 8px;
    padding: 2px 12px 2px 12px;
}

.annuity_application_table_data {
    background-color: #f1f1f1;
    margin-top: 10px;
    border-radius: 50px;
}

.annuity_application_table_data td {
    padding: 5px 10px;
    font-size: 15px;
    /* border-bottom: 10px solid #fff; */
}

.annuity_application_table_data td:nth-of-type(1) {
    border-radius: 8px 0 0 8px;
}

.annuity_application_table_data td:last-of-type {
    border-radius: 0 8px 8px 0;
}


@media only screen and (max-width: 1150px) {
    .annuity_application_table_root {
        width: 80%;
    }
}

@media only screen and (max-width: 930px) {
    .annuity_application_table_root {
        width: 90%;
    }
}

@media only screen and (max-width:800px) {
    .annuity_application_table {
        min-width: 730px;
    }
}