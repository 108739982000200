.disclosure_form_radio_button {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 80%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuity_disclosure_desc {
    width: 80%;
    display: flex;
    flex-direction: column;
}

.annuity_disclosure_desc_1 {
    color: #606060;
}

.annuity_disclosure_desc_2 {
    color: #606060;
    margin-top: 5px !important;
}

.radio_group_shift_right {
    margin-left: 15px;
}