* {
    box-sizing: border-box;
}

.full_name {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.current_age,
.gender,
.employment_status,
.current_occupation,
.dependents {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;

}

.radio_group_shift_right {
    margin-left: 15px !important;
}

.btn_want {
    display: flex;
    justify-content: flex-end !important;
    font-size: small;
    padding: 0;
    margin-right: 10% !important;
    color: #074a6e !important;
    cursor: pointer;
}

.Financial_Information_Of_Owner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.left_part {
    width: 33%;
    display: flex;
    justify-content: flex-start;
}

.middle_part {
    width: 33%;
    display: flex;
    justify-content: flex-start;
}

.right_part {
    width: 33%;
    display: flex;
    justify-content: flex-start;
}

.producer_disclosure_page_root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.application_form {
    width: 50%;
    margin-top: 30px;
    margin-bottom: 10px;
    /* border:2px solid #074a6e; */
    border-radius: 5px;
    display: flex;
    justify-content: center;
    /* filter: drop-shadow(0px 0px 2px #074a6e); */
}

.Current_Insurance_Company {
    margin-top: -8px !important;
    font-size: 0.701rem;
    line-height: 1.7rem;
}

.plans_label {
    left: -14px !important;
}

.plans_transfer_request {
    margin-top: 5px;
}

.personal_information_main .gender .middle_part .input_field_width,
.personal_information_main .gender .right_part .input_field_width,
.personal_information_main .employment_status .middle_part .input_field_width,
.personal_information_main .employment_status .right_part .input_field_width {
    min-width: 80% !important;
    max-width: 80% !important;
}

.application_form_card {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.application_form_head {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    color: #074a6e;
    font-weight: 600;
    font-size: 20px;
    width: 100%;
}

.application_form_head_bottom_line {
    height: 20px;
    width: 80%;
    margin: 0 auto;
    margin-top: 10px;
}

.application_form_head_bottom_line hr {
    color: #074a6e;
    background-color: #074a6e;
}

.section_head_root {
    margin-top: 15px;
    display: flex;
    width: 80%;
    font-size: 15px;
    font-weight: 600;
    justify-content: space-between;
    align-items: center;
    color: #074a6e;
}

.annuitant_input_fields {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 80%;
}

.annuitant_fullname_and_ssn_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

::placeholder {
    color: #B3B3B3;
    text-align: left;
}

.existing ::placeholder {
    font-size: 12px;
}

.new_existing_account .MuiFormGroup-root .MuiInputBase-root ::placeholder {
    font-size: 14px;
}

.annuitant_gender_selection {
    min-width: 200px;
    border: none;
}

.annuitant_dateofbirth_field {
    display: flex;
    justify-content: flex-start;
}

.annuitant_dateofbirth_field fieldset {
    /* min-width: 300px; */
    border: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid #B3B3B3 !important;
}

.annuitant_gender_and_dateofbirth_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_streetaddress_and_zip_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_city_and_state_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_phone_and_email_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_owner_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    flex-direction: column;
}

.left_section {
    width: 50%;
    display: flex;
    justify-content: flex-start;
}

.input_field_width {
    min-width: 95% !important;
}

.right_section {
    display: flex;
    justify-content: flex-end;
    width: 50%;
}

.plan_rider_section {
    width: 80%;
}

.plan_rider_checkboxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.not_required label::after {
    content: "" !important;
}

@media screen and (min-width:0px) and (max-width: 420px) {

    .Owner_Full_Name,
    .Owner_Tax_Id_or_SSN,
    .Street_Address,
    .Zip,
    .City,
    .State,
    .Annuitant_Insured,
    .Current_Insurance_Company,
    .Phone,
    .Account_Number {
        flex-direction: row !important;
        width: 100% !important;
    }

    .MuiInput-root {
        width: 100% !important;
    }

    .Current_Insurance_Company {
        margin-top: 2px !important;
    }

    .existing_annuity_status {
        margin-top: 25px !important;
    }

    .type_of_transfer_rollover {
        margin-top: 25px !important;
        margin-bottom: 25px !important;
    }

    .qualified_events_for_rollover_funds {
        margin-top: 15px !important;
        margin-bottom: 15px !important;
    }

    .non_qualified_or {
        margin: 2px 15px 0 -5px !important;
    }

    .previous_next_btn {
        flex-direction: column;
    }

    .btn_prev {
        margin: 15px 10px !important;
        width: 100%;

    }

    /* .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
        width: 100% !important;
    } */
    .btn_next {
        margin: 15px 10px !important;
        width: 250%;
        left: -80%;

    }

    /* .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
         width: 100% !important;
     } */
    .internal_exchange_1 {
        display: flex;
        align-items: center;
        width: 100%;
        flex-wrap: wrap;
    }

    .internal_exchange_2 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .btn_want {
        margin-right: 10% !important;
        margin-top: 6px !important;
    }
}

@media screen and (min-width:401px) and (max-width: 820px) {
    .application_form {
        margin-top: 20px !important;
    }

    .previous_next_btn {
        flex-direction: column;
    }

    .previous_next_btn .btn_next {
        margin-top: 20px !important;
    }

    .previous_next_btn .btn_prev {
        margin: 0px 20px !important;
    }

    .btn_want {
        margin-right: 10% !important;
        margin-top: 6px !important;
    }
}