.login_page_title {
    background: #D9A22E !important;
}

.login_page_form {
    display: flex;
    justify-content: center;
}

.login_page_card {
    margin-top: 30px;
    width: 60%;
    height: 550px;
    display: flex;
    /* justify-content: center; */
    border: 2px solid #074a6e;
}

.login_page_card_left {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #fff; */
    padding: 0;
    background: rgba(0, 0, 0, 0.5) url("../../assets/welcomeImg.png");
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    background-size: 100% 100%;
    background-origin: content-box;
}

.login_left_img_text {
    color: #fff;
    font-weight: 500;
    font-size: 25px;
    text-align: center;
}

.login_page_card_right {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* background-color: #074a6e; */
    position: relative;
}

.flic_logo {
    position: absolute;
    top: 10px;
    right: 10px;

}

.login_page_email_root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-bottom: 20px;
    margin-top: 20px;
}

.login_page_email_icon {
    margin-right: 20px;
}

.login_page_email_icon img {
    height: 45px;
    width: 45px;
}

.login_page_card_right .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill {
    border-radius: 30px;
}

.login_page_email_field input {
    background-color: #fff !important;
    border-radius: 10px;
    height: 16px;
    min-width: 300px;
}

.login_page_email_field fieldset {
    /* background-color: #fff; */
    border-radius: 30px;
    border: none;
    box-shadow: 0px 0px 4px #D9A22E;
}

.login_page_password_root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-bottom: 20px;
}

.login_page_password_icon {
    margin-right: 20px;
}

.login_page_password_icon img {
    width: 50px;
    height: 50px;
}

.login_page_password_field input {
    background-color: #fff;
    border-radius: 10px;
    height: 16px;
    min-width: 269px;
    color: #000;
}

.login_page_password_field fieldset {
    /* background-color: #fff; */
    border: none;
    box-shadow: 0px 0px 4px #D9A22E;
    border-radius: 30px;
}

.login_page_submit_root button {
    margin-top: 20px;
    background-color: #D9A22E;
    color: #fff;
    border-radius: 25px;
    padding: 10px 35px;
    font-size: 16px;
    font-weight: 600;
}

.login_page_submit_root button:hover {
    margin-top: 20px;
    background-color: #D9A22E;
    color: #fff;
    border-radius: 25px;
    padding: 10px 35px;
    font-size: 16px;
    font-weight: 600;
}

.login_page_card_right_other {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: #D9A22E;
    margin-top: 20px;
}

.login_forgot_password {
    cursor: pointer;
}

.login_submit_btn:disabled {
    opacity: 0.5;
}

@media screen and (max-width:1200px) {
    .login_page_card {
        width: 90%;
    }
}

@media screen and (min-width:1201px) and (max-width:1400px) {
    .login_page_card {
        width: 90%;
    }

}


@media screen and (max-width:900px) {
    .login_page_card {
        width: 70%;
        height: 500px;
    }

    .login_page_card_left {
        display: none;
    }

    .login_page_card_right {
        padding-top: 20px;
        width: 100%;
        padding-bottom: 20px;
    }

    .errLogin,
    .msgLogin {
        width: 80%;
        margin: 0 auto;
        margin-top: 100px;
    }
}


@media screen and (max-width:600px) {
    .login_page_password_field input {
        min-width: 211px;
    }

    .login_page_email_field input {
        min-width: 240px;
    }

    .errLogin,
    .msgLogin {
        width: 80%;
        margin: 0 auto;
        margin-top: 100px;
    }
}

@media screen and (max-width:500px) {
    .login_page_card {
        width: 90%;
    }

    .login_page_card_left img {
        width: 75%;
    }

    .errLogin,
    .msgLogin {
        width: 80%;
        margin: 0 auto;
        margin-top: 100px;
    }
}


@media screen and (max-width:380px) {
    .login_page_password_field input {
        min-width: 180px;
    }

    .login_page_email_field input {
        min-width: 205px;
    }

    .errLogin,
    .msgLogin {
        width: 80%;
        margin: 0 auto;
        margin-top: 100px;
    }
}

.errLogin,
.msgLogin {

    margin: 0 auto;

}