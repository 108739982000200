.StateName, .YearName{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    font-weight: 600
}
.MuiModal-root .MuiDialog-container .MuiPaper-root button{
    background-color: #D9A22E;
}
.grid_height{
    margin: 5px !important;
}
.delete{
    cursor: pointer;
}
.con_main_box_first_box {
    width: 90%;
    margin: 0 auto;
}
.popup_box{
    width: 500px;
    height: auto;
}

@media screen and (min-width:0px) and  (max-width:420px){
    .YearNameDiv{
        height: auto;
    }  
    .popup_box{
    width: 71vw;
    }
.main_box{
    display: flex;
    justify-content: center;
}
.first_name{
    width: 98%;

}
.state_grid{
    width: 96% !important;
    padding: 0 !important;
}
.con_main_box_first_box{
    /* max-width: 1070px;
    min-width: 820px; */
    width: 900px;
    margin: 10px 30px !important;
}
.period_main_grid{
    width: 98%;
}
.period_main_grid .MuiBox-root{
    padding: 20px !important;
}
.con_main_box_first_grid{
    /* width: 1220px !important;
    display: flex !important; */
    margin-left: 4%;
    justify-content: flex-start !important;
    /* margin: 10px 20px !important; */
}
.state_box{
    padding: 50px 10px !important;
}
}
@media screen and (min-width:421px) and  (max-width:820px){  
    .con_main_box_first_box{
       /* width: fit-content; */
       width: 900px;
        margin: 10px 30px !important;
    }
    .YearNameDiv{
        height: auto;
    }
    .con_main_box_first_grid{
        width: 1220px !important;
        display: flex !important;
        justify-content: flex-start !important;
        margin: 10px 20px !important;
    }
    .state_grid {
        width: 100%;
    }
    .state_box{
        padding: 20px !important;
    }
    .period_main_grid{
        width: 96%;
    }
}
.YearNameDiv{
    height: 400px;
}

@media screen and (min-width:821px) and  (max-width:900px){  
    .con_main_box_first_box{
        /* max-width: 90px;
        min-width: 800px; */
        width: fit-content;
        margin: 10px 30px !important;
    }
    .YearNameDiv{
        height: auto;
    }
    .con_main_box_first_grid{
        width: 1520px !important;
        display: flex !important;
        justify-content: flex-start !important;
        margin: 10px 20px !important;
    }
    .state_box{
        padding: 50px 40px !important;
    }
    .period_main_grid{
        width: 96%;
    }
}
.btn_div1_admin{
    display: flex;
  
    width: 60%;
    margin: 10px auto;
    margin-bottom: 20px;

}
.btn_div1_admin button , .btn_div1_admin button:hover{
    background-color: #D9A22E;
}

.errAdmin, .msgAdmin{
    display: flex;
    justify-content: left;
    flex-direction: row;
    min-height: 50px;
    margin-bottom: 10px;
}
.state {
    position: sticky;
    top: 0;
}
@media screen and (min-width:421px) and (max-width:630px){
    .popup_box{
        width: 70vw ;  
    }
} 
