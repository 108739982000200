@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&display=swap');


*,
*::before,
*::after {
    font-family: "roboto", 'Open Sans', sans-serif !important;
}

/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&display=swap');

*,
*::before,
*::after,
*:before,
*:after,
html,
head,
body {
    font-family: "Open Sans", sans-serif !important;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
} */