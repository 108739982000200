.MuiModal-root .MuiDialog-container .MuiPaper-root{
    min-width: 300px;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100px;
}
.MuiModal-root .MuiDialog-container .MuiPaper-root p{
    font-size: 20px;
    margin: 20px;
}
.MuiModal-root .MuiDialog-container .MuiPaper-root button{
    margin-top: 10px;
    color: #fff;
    background-color: #04385d;
    margin-bottom: 20px;
}
@media screen and (max-width: 400px) {
    .MuiModal-root .MuiDialog-container .MuiPaper-root p{
        font-size: 17px;
        text-align: center;
    }
    .MuiModal-root .MuiDialog-container .MuiPaper-root{
        min-width: 350px;
    }
}
@media screen and (max-width: 360px) {
    .MuiModal-root .MuiDialog-container .MuiPaper-root{
        min-width: 300px;
    }
}