* {
    box-sizing: border-box;
}

.page_title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 55px;
    background: #074a6e;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 23px;
}

.page_title_register,
.page_title_Annuty {
    background: #D9A22E !important;
}

.first_name_root {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
}

.last_name_root {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.email_root {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.password_root {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.confirm_password_root {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.phone_root {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.website_root {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.register_page_root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.register_form {
    width: 40%;
    margin-top: 30px;
    margin-bottom: 10px;
    border: 2px solid #D9A22E;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    /* filter: drop-shadow(0px 0px 2px #D9A22E); */
}

.form_card {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 25px;
}

.registerInput input {
    padding: 0;
    border-radius: 10px;
    height: 25px;
    font-size: 15px;
    padding: 3px 12px 3px 12px;
    min-width: 300px;
}

.registerInputPassword input {
    padding: 0;
    border-radius: 10px;
    height: 25px;
    font-size: 15px;
    padding: 3px 12px 3px 12px;
    min-width: 258px;
}

.registerInput fieldset.Mui-focused {
    border-color: #000 !important;
}

.registerInputPassword fieldset.Mui-focused {
    border-color: #000 !important;
}

.registerInput fieldset {
    border-radius: 10px;
}

.registerInputPassword fieldset {
    border-radius: 10px;
}

.registerInput ::placeholder {
    font-size: 13px;
    /* padding:5px 10px 5px 5px; */
}

.registerInputPassword ::placeholder {
    font-size: 13px;
    /* padding:5px 10px 5px 5px; */
}


.register_Phone input {
    min-width: 248px !important;
    padding: 0;
    border-radius: 10px;
    height: 25px;
    font-size: 15px;
    padding: 3px 12px 3px 12px;
}

.register_Phone fieldset.Mui-focused {
    border-color: #000 !important;
}

.register_Phone fieldset {
    border-radius: 10px;
}

.register_Phone ::placeholder {
    font-size: 13px;
    /* padding:5px 10px 5px 5px; */
}

.create_account_button button {
    margin-top: 20px;
    background-color: #D9A22E;
    text-transform: capitalize;
    font-size: 14px;
    border-radius: 7px;
    padding: 4px 20px 4px 20px;
}

.create_account_button button:hover {
    background-color: #D9A22E;
    cursor: pointer;
}

.login_button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    text-align: center;
    flex-wrap: wrap;
}

.login_button div {
    padding-top: 10px;
    font-size: 14px;
}

.login_button button {
    font-size: 14px;
    text-transform: capitalize;
    padding: 0;
    margin: 0;
    color: #D9A22E;
    min-width: 12px;
}

.field_name {
    width: 40%;
    display: flex;
    justify-content: flex-start;
    text-align: left;
}

.field_name div:first-of-type {
    font-size: 17px !important;
}

.required_field {
    color: red;
}

@media screen and (min-width: 1051px) {
    .first_name_root {
        display: flex !important;
        flex-wrap: nowrap !important;
        justify-content: space-between !important;
    }

    .website_root .first_name_root .field_name {
        width: 70% !important;
    }

    .form_card {
        width: 90%;
    }
}

@media screen and (max-width: 1670px) {

    .form_card {
        width: 95%;
    }

    .register_form {
        width: 45%;
    }
}

@media screen and (max-width: 1400px) {
    .register_form {
        width: 50%;
    }
}

@media screen and (max-width: 1270px) {
    .register_form {
        width: 60% !important;
    }
}

@media screen and (max-width: 1050px) {
    .first_name_root {
        display: flex !important;
        flex-wrap: wrap !important;
        justify-content: flex-start !important;
    }

    .website_root .first_name_root .field_name,
    .last_name_root .first_name_root .field_name,
    .email_root .first_name_root .field_name,
    .password_root .field_name,
    .confirm_password_root .field_name {
        width: 100% !important;
        margin-bottom: 5px;
    }

    .form_card {
        width: 90%;
    }

    .field_name div:first-of-type {
        font-size: 15px !important;
    }
}

@media screen and (max-width: 700px) {
    .register_form {
        width: 75% !important;
    }
}

@media screen and (max-width: 550px) {
    .register_form {
        width: 90% !important;
    }

    .form_card {
        padding: 20px 0;
        margin: 10px auto;
    }

    .field_name div:first-of-type {
        font-size: 13px !important;
    }
}

@media screen and (max-width: 440px) {
    .form_card input {
        min-width: 250px;
    }

    .register_Phone input {
        min-width: 200px !important;
    }
}

@media screen and (max-width: 320px) {
    .form_card input {
        min-width: 180px;
    }

    .register_Phone input {
        min-width: 100px !important;
    }
}

/* 
@media only screen and (max-width: 992px) {
    .register_form{
        width: 60%;
    }
}
@media only screen and (max-width: 834px) {
    .register_form{
        width: 70%;
    }
}

@media only screen and (max-width: 704px) {
    .register_form{
        width: 80%;
    }
}
@media only screen and (max-width: 570px) {
    .form_card{
        width: 90%;
    }
    .register_form{
        width: 90%;
    }
}
@media only screen and (max-width: 490px) {
    .form_card input{
        min-width: 130px;
    }
    .form_card{
        width: 100%;
    }
}
@media only screen and (max-width: 360px) {
    .first_name_root{
       flex-direction: column;
    }
    .field_name div:first-of-type{
        min-width:150px ;
    }
} */