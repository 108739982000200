.App-link {
    color: #61dafb;
}

.App-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 1.5vmin);
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.characters {
    list-style: none;
    padding-left: 0;
}

.characters li {
    display: flex;
    align-items: center;
    border: solid 2px #d0d0d0;
    border-radius: .2em;
    padding: .5em .8em .5em .5em;
    margin-bottom: 1em;
}

.characters p {
    max-width: none;
    font-weight: bold;
    margin: 0;
}

.characters-thumb {
    overflow: hidden;
    flex-shrink: 0;
    width: 2em;
    height: 2em;
    background-color: #e8e8e8;
    padding: .5em;
    margin-right: .5em;
}

.characters-thumb img {
    display: block;
    width: 100%;
    height: auto;
}




















/* .list_item_resource {
    padding-top: 0;
}

.application_box_root_resource {
    border-radius: 10px;
    background-color: #074A6E !important;
    color: #fff !important;
    margin-bottom: 10px !important;
    height: max-content !important;
}

.application_box_root_resource .list_item {
    padding-bottom: 0;
}

.application_box_root_resource a {
    text-decoration: none;
    color: #fff;
}

.application_box_root_resource a:hover {
    text-decoration: none;
    color: #D9A22E !important;
}


.application_box_root_resource {

    margin-left: 20px !important;
    height: max-content !important;
    WIDTH: 440px !important;
    margin-right: 10px !important;

}

.application_box_root_resource {
    max-width: 350px !important;
    min-width: 350px !important;
}


.resource_table {
    border-collapse: separate;
    border-spacing: 0 15px;
    width: 100%;
}

.resource_name {
    margin-bottom: 15px !important;
} */