.previous_next_btn {
    display: flex;
    justify-content: space-between;
    padding-left: 120px;
    padding-right: 120px;
}


* {
    box-sizing: border-box;
}

.radio_group_shift_right {
    margin-left: 15px !important;
}

.suitability_full_name {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.current_age,
.suitability_full_name,
.gender,
.employment_status,
.current_occupation,
.dependents {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.Financial_Information_Of_Owner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.suitability_left_part {
    width: 33%;
    padding-top: 17px;
    display: flex;
    justify-content: flex-start !important;
}

.middle_part {
    width: 33%;
    display: flex;
    justify-content: flex-start;
}

.suitability_middle_part {
    width: 33%;
    display: flex;
    justify-content: flex-start;
}

.suitability_right_part {
    width: 33%;
    display: flex;
    justify-content: flex-start;
}

.suitability_analysis_page_root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.financial_situation_and_need_of_owner {
    margin-bottom: -20px;
}

.application_form {
    width: 50%;
    margin-top: 30px;
    margin-bottom: 10px;
    /* border:2px solid #074a6e; */
    border-radius: 5px;
    display: flex;
    justify-content: center;
    /* filter: drop-shadow(0px 0px 2px #074a6e); */
}

.first_question {
    margin-bottom: -5px !important;
}


.personal_information_main .gender .suitability_middle_part .suitability_middle_part_input_field_width,
.personal_information_main .gender .suitability_right_part .suitability_right_part_input_field_width,
.personal_information_main .employment_status .suitability_middle_part .suitability_middle_part_input_field_width,
.personal_information_main .employment_status .suitability_right_part .suitability_right_part_input_field_width {
    width: 195px !important;
}

.personal_information_main .current_age .suitability_middle_part .input_field_width,
.personal_information_main .current_age .suitability_right_part .input_field_width {
    min-width: 50% !important;
    max-width: 50% !important;
}

.application_form_card {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.application_form_head {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    color: #074a6e;
    font-weight: 600;
    font-size: 20px;
    width: 100%;
}

.application_form_head_bottom_line {
    height: 20px;
    width: 80%;
    margin: 0 auto;
    margin-top: 10px;
}

.application_form_head_bottom_line hr {
    color: #074a6e;
    background-color: #074a6e;
}

.suitability_section_head_root {
    margin-top: 15px !important;
    display: flex;
    width: 80%;
    font-size: 15px;
    font-weight: 600;
    justify-content: space-between;
    align-items: center;
    color: #074a6e;
}

.annuitant_input_fields {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 80%;
}

.annuitant_fullname_and_ssn_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

::placeholder {
    color: #B3B3B3;
    text-align: left;
}

.annuitant_gender_selection {
    min-width: 200px;
    border: none;
}

.annuitant_dateofbirth_field {
    display: flex;
    justify-content: flex-start;
}

.annuitant_dateofbirth_field fieldset {
    /* min-width: 300px; */
    border: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid #B3B3B3 !important;
}

.annuitant_gender_and_dateofbirth_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_streetaddress_and_zip_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_city_and_state_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_phone_and_email_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_owner_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    flex-direction: column;
}

.financial_section_labels label {
    color: #000;
    font-size: 15px;
}

.left_section {
    width: 50%;
    display: flex;
    justify-content: flex-start;
}

.input_field_width {
    min-width: 15% !important;
}

.right_section {
    display: flex;
    justify-content: flex-end;
    width: 50%;
}

.plan_rider_section {
    width: 80%;
}

.plan_rider_checkboxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.suitability_section_width {
    width: 80%;
}

.third_question_proper {
    flex-direction: column !important;
}

.third_question_proper1 {
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}

.third_question_proper2 {
    vertical-align: middle !important;
}

.first_question,
.second_question,
.third_question,
.fourth_question,
.fifth_question {
    margin-bottom: 15px !important;
}

.third_question {
    margin-bottom: -3px !important;
}

.first_question_bottom {
    margin-bottom: -5px !important;
}

.fourth_question_top {
    margin-top: 15px !important;
}

.seventh_question {
    margin-bottom: 15px !important;
}

.seventh_question_top {
    margin-bottom: 5px !important;
}

@media screen and (min-width: 0px) and (max-width: 420px) {

    .income_tax_bracket,
    .total_outstanding_debt,
    .annual_household_income,
    .source_of_income {
        width: 100% !important;
        justify-content: center !important;
    }

    #menu- .MuiPaper-root {
        width: 70% !important;

    }

    .previous_next_btn {
        flex-direction: column;
    }

    .btn_prev {
        margin: 15px 10px !important;
        width: 100%;
    }

    .label_field label {
        font-size: 0.901rem;
        line-height: 2rem;
    }

    .req_fields label::after {
        content: '*';
        color: red;
    }

    .right_space {
        margin-right: 8px;
        margin-left: 0px !important;
    }

    .fourth_question {
        margin-top: 25px !important;
    }

    .seventh_question {
        margin-top: 15px !important;
    }

    .first_question_top {
        margin-top: 10px !important;
    }

    .first_question_sutability {
        margin-top: 20px !important;
    }

    .the_product_purchase {
        margin-bottom: 15px;
    }

    .second_question_text_field {
        width: 85% !important;
        padding-left: 0px;
    }

    .btn_next {
        margin: 15px 10px !important;
        width: 250%;
        left: -80%;
    }

    .suitability_section_width {
        width: 85% !important;
    }

    .financial_information_of_owner_main {
        margin-top: -5px;
        margin-bottom: 10px;
    }

    .financial_situation_and_need_of_owner,
    .the_product_purchase,
    .existing_account_information,
    .other {
        width: 85% !important;
    }

    .first_question_btn {
        flex-direction: row !important;
    }

    .second_question_1 {
        display: flex;
        align-items: center;
        width: 100%;
        flex-wrap: wrap;
    }

    .second_question_2 {
        margin-top: -18px;
        padding-left: 87%;
        padding-bottom: 5px;
    }

    .suitability_section_head_root {

        width: 85%;
        font-size: 14px;
        margin-bottom: 10px;
        margin-top: 10px !important;
    }

    .suitability_section_head_root_top {
        margin-top: 10px !important;
    }

    .Financial_Information_Of_Owner {
        flex-direction: column !important;

    }

    .financial_section_labels,
    .income_tax_bracket {
        margin-bottom: 20px;
    }

    /* .css-qfz70r-MuiFormGroup-root {
    flex-direction: column !important;
    margin-top: 10px    ;
} */

    .annual_household_income {
        margin-bottom: 20px;
    }
}

@media screen and (min-width: 0px) and (max-width: 420px) {
    #menu- .MuiPaper-root {
        width: 70% !important;
    }
}

@media screen and (min-width :421px) and (max-width: 820px) {
    #menu- .MuiPaper-root {
        width: 25% !important;

        min-width: 0 !important;
    }
}

@media screen and (min-width:401px) and (max-width: 820px) {
    .application_form {
        margin-top: 70px !important;
    }

}

@media screen and (max-width: 1250px) {
    .suitability_left_part {
        width: 28%;
    }

    .suitability_right_part {
        width: 36%;
    }
}

@media screen and (max-width:525px) {
    .suitability_left_part {
        width: 100%;
    }

    .suitability_middle_part {
        width: 100%;
    }

    .suitability_right_part {
        width: 100%;
    }

    .suitability_left_part {
        padding-top: 5px;
    }

    .suitability_middle_part_input_field_width {
        margin-top: 8px !important;
        min-width: 100% !important;
    }

    .suitability_right_part_input_field_width {
        margin-top: 8px !important;
        min-width: 100% !important;
    }

    .current_age,
    .suitability_full_name,
    .gender,
    .employment_status,
    .current_occupation,
    .dependents {
        margin-top: 5px;
    }
}

.MuiPaper-elevation8 {
    min-width: 200px;
}

@media screen and (min-width: 1200px) {
    .btn_next .btn_prev {
        width: 100%;
    }
}