@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&display=swap);
* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    /* font-family: 'Arimo', sans-serif; */
}

.global_header {
    width: 100%;
    height: 100px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.company_logo img {
    height: 90px;
    padding-left: 25px;
    margin-top: 10px;
}

.my_bussiness_btn {
    padding: 0px !important;
    background-color: #D9A22E !important;
    border: none !important;
}

.my_bussiness_link {
    padding: 5px 15px !important;
    color: #fff !important;
}

.selected_page {
    color: #074a6e !important;
}

.header_right_section {
    padding-right: 10px;
}

.header_right_section a {
    padding: 10px 15px;
    text-transform: capitalize;
    text-decoration: none;
    color: #000000;
    font-size: 15px;
}

#user_options_menu .MuiPaper-root {
    top: 7% !important;
    left: 89% !important;
}

#history_options_menu .MuiPaper-root {
    top: 7% !important;
    left: 74% !important;
}

#history_options_menu .MuiPaper-elevation8 {
    min-width: 230px !important;
}

#history_options_menu_agent .MuiPaper-root {
    top: 7% !important;
    left: 83% !important;
}

#history_options_menu_agent .MuiPaper-elevation8 {
    min-width: 230px !important;
}

.menu_item_list a {
    text-decoration: none;
    color: #000000;
}

#user_options_menu .MuiPaper-root ul li a {
    text-decoration: none;
}


@media screen and (min-width:0px) and (max-width: 400px) {
    #user_options_menu .MuiPaper-root {
        top: 8% !important;
        left: 42% !important;
    }
}

@media screen and (min-width:400px) and (max-width: 500px) {
    #user_options_menu .MuiPaper-root {
        top: 8% !important;
        left: 47% !important;
    }
}

@media screen and (min-width:500px) and (max-width: 630px) {
    #user_options_menu .MuiPaper-root {
        top: 8% !important;
        left: 55% !important;
    }
}

@media screen and (min-width:631px) and (max-width: 700px) {
    #user_options_menu .MuiPaper-root {
        top: 8% !important;
        left: 71% !important;
    }
}

@media screen and (min-width:700px) and (max-width: 800px) {
    #user_options_menu .MuiPaper-root {
        top: 8% !important;
        left: 75% !important;
    }
}

@media screen and (min-width:800px) and (max-width: 900px) {
    #user_options_menu .MuiPaper-root {
        top: 8% !important;
        left: 78% !important;
    }

    #history_options_menu .MuiPaper-root {
        top: 6% !important;
        left: 50% !important;
    }

    #history_options_menu_agent .MuiPaper-root {
        top: 8% !important;
        left: 70% !important;
    }
}

@media screen and (min-width:900px) and (max-width: 1000px) {
    #user_options_menu .MuiPaper-root {
        top: 8% !important;
        left: 81% !important;
    }

    #history_options_menu .MuiPaper-root {
        top: 6% !important;
        left: 45% !important;
    }

    #history_options_menu_agent .MuiPaper-root {
        top: 8% !important;
        left: 65% !important;
    }
}

@media screen and (min-width:1000px) and (max-width: 1100px) {
    #user_options_menu .MuiPaper-root {
        top: 8% !important;
        left: 80% !important;
        position: absolute;
        right: 2%;
    }

    #history_options_menu .MuiPaper-root {
        top: 8% !important;
        left: 50% !important;
    }

    #history_options_menu_agent .MuiPaper-root {
        top: 8% !important;
        left: 68% !important;
    }
}

@media screen and (min-width:1100px) and (max-width: 1200px) {
    #user_options_menu .MuiPaper-root {
        top: 8% !important;
        left: 84% !important;
    }

    #history_options_menu .MuiPaper-root {
        top: 8% !important;
        left: 55% !important;
    }

    #history_options_menu_agent .MuiPaper-root {
        top: 8% !important;
        left: 70% !important;
    }
}


@media screen and (min-width:1200px) and (max-width: 1300px) {
    #user_options_menu .MuiPaper-root {
        top: 8% !important;
        left: 85% !important;
    }

    #history_options_menu .MuiPaper-root {
        top: 8% !important;
        left: 60% !important;
    }

    #history_options_menu_agent .MuiPaper-root {
        top: 8% !important;
        left: 75% !important;
    }
}

@media screen and (min-width:1300px) and (max-width: 1450px) {
    #user_options_menu .MuiPaper-root {
        top: 8% !important;
        left: 87% !important;
    }
}

@media screen and (min-width:1300px) and (max-width: 1499px) {
    #history_options_menu .MuiPaper-root {
        top: 8% !important;
        left: 65% !important;
    }

    #history_options_menu_agent .MuiPaper-root {
        top: 8% !important;
        left: 78% !important;
    }
}

@media screen and (min-width:1500px) and (max-width: 1770px) {
    #history_options_menu .MuiPaper-root {
        top: 8% !important;
        left: 70% !important;
    }

    #history_options_menu_agent .MuiPaper-root {
        top: 8% !important;
        left: 80% !important;
    }
}

@media screen and (max-width: 550px) {
    .header_right_section a {
        padding: 10px 5px !important;
        font-size: 13px;
    }

    .company_logo img {
        padding-left: 2px;
        width: 300px;
    }
}

@media screen and (max-width: 420px) {
    .company_logo img {
        width: 230px;
    }
}

@media screen and (max-width: 350px) {
    .global_header {
        flex-direction: column;
        height: 130px;
    }

    #user_options_menu .MuiPaper-root {
        top: 18% !important;
        left: 32% !important;
    }

    .company_logo img {
        width: 230px;
    }
}


/* scrollbar */

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #D9A22E;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #D9A22E;
}
* {
    box-sizing: border-box;
}

.page_title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 55px;
    background: #074a6e;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 23px;
}

.page_title_register,
.page_title_Annuty {
    background: #D9A22E !important;
}

.first_name_root {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
}

.last_name_root {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.email_root {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.password_root {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.confirm_password_root {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.phone_root {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.website_root {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.register_page_root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.register_form {
    width: 40%;
    margin-top: 30px;
    margin-bottom: 10px;
    border: 2px solid #D9A22E;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    /* filter: drop-shadow(0px 0px 2px #D9A22E); */
}

.form_card {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 25px;
}

.registerInput input {
    padding: 0;
    border-radius: 10px;
    height: 25px;
    font-size: 15px;
    padding: 3px 12px 3px 12px;
    min-width: 300px;
}

.registerInputPassword input {
    padding: 0;
    border-radius: 10px;
    height: 25px;
    font-size: 15px;
    padding: 3px 12px 3px 12px;
    min-width: 258px;
}

.registerInput fieldset.Mui-focused {
    border-color: #000 !important;
}

.registerInputPassword fieldset.Mui-focused {
    border-color: #000 !important;
}

.registerInput fieldset {
    border-radius: 10px;
}

.registerInputPassword fieldset {
    border-radius: 10px;
}

.registerInput ::-webkit-input-placeholder {
    font-size: 13px;
    /* padding:5px 10px 5px 5px; */
}

.registerInput ::placeholder {
    font-size: 13px;
    /* padding:5px 10px 5px 5px; */
}

.registerInputPassword ::-webkit-input-placeholder {
    font-size: 13px;
    /* padding:5px 10px 5px 5px; */
}

.registerInputPassword ::placeholder {
    font-size: 13px;
    /* padding:5px 10px 5px 5px; */
}


.register_Phone input {
    min-width: 248px !important;
    padding: 0;
    border-radius: 10px;
    height: 25px;
    font-size: 15px;
    padding: 3px 12px 3px 12px;
}

.register_Phone fieldset.Mui-focused {
    border-color: #000 !important;
}

.register_Phone fieldset {
    border-radius: 10px;
}

.register_Phone ::-webkit-input-placeholder {
    font-size: 13px;
    /* padding:5px 10px 5px 5px; */
}

.register_Phone ::placeholder {
    font-size: 13px;
    /* padding:5px 10px 5px 5px; */
}

.create_account_button button {
    margin-top: 20px;
    background-color: #D9A22E;
    text-transform: capitalize;
    font-size: 14px;
    border-radius: 7px;
    padding: 4px 20px 4px 20px;
}

.create_account_button button:hover {
    background-color: #D9A22E;
    cursor: pointer;
}

.login_button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    text-align: center;
    flex-wrap: wrap;
}

.login_button div {
    padding-top: 10px;
    font-size: 14px;
}

.login_button button {
    font-size: 14px;
    text-transform: capitalize;
    padding: 0;
    margin: 0;
    color: #D9A22E;
    min-width: 12px;
}

.field_name {
    width: 40%;
    display: flex;
    justify-content: flex-start;
    text-align: left;
}

.field_name div:first-of-type {
    font-size: 17px !important;
}

.required_field {
    color: red;
}

@media screen and (min-width: 1051px) {
    .first_name_root {
        display: flex !important;
        flex-wrap: nowrap !important;
        justify-content: space-between !important;
    }

    .website_root .first_name_root .field_name {
        width: 70% !important;
    }

    .form_card {
        width: 90%;
    }
}

@media screen and (max-width: 1670px) {

    .form_card {
        width: 95%;
    }

    .register_form {
        width: 45%;
    }
}

@media screen and (max-width: 1400px) {
    .register_form {
        width: 50%;
    }
}

@media screen and (max-width: 1270px) {
    .register_form {
        width: 60% !important;
    }
}

@media screen and (max-width: 1050px) {
    .first_name_root {
        display: flex !important;
        flex-wrap: wrap !important;
        justify-content: flex-start !important;
    }

    .website_root .first_name_root .field_name,
    .last_name_root .first_name_root .field_name,
    .email_root .first_name_root .field_name,
    .password_root .field_name,
    .confirm_password_root .field_name {
        width: 100% !important;
        margin-bottom: 5px;
    }

    .form_card {
        width: 90%;
    }

    .field_name div:first-of-type {
        font-size: 15px !important;
    }
}

@media screen and (max-width: 700px) {
    .register_form {
        width: 75% !important;
    }
}

@media screen and (max-width: 550px) {
    .register_form {
        width: 90% !important;
    }

    .form_card {
        padding: 20px 0;
        margin: 10px auto;
    }

    .field_name div:first-of-type {
        font-size: 13px !important;
    }
}

@media screen and (max-width: 440px) {
    .form_card input {
        min-width: 250px;
    }

    .register_Phone input {
        min-width: 200px !important;
    }
}

@media screen and (max-width: 320px) {
    .form_card input {
        min-width: 180px;
    }

    .register_Phone input {
        min-width: 100px !important;
    }
}

/* 
@media only screen and (max-width: 992px) {
    .register_form{
        width: 60%;
    }
}
@media only screen and (max-width: 834px) {
    .register_form{
        width: 70%;
    }
}

@media only screen and (max-width: 704px) {
    .register_form{
        width: 80%;
    }
}
@media only screen and (max-width: 570px) {
    .form_card{
        width: 90%;
    }
    .register_form{
        width: 90%;
    }
}
@media only screen and (max-width: 490px) {
    .form_card input{
        min-width: 130px;
    }
    .form_card{
        width: 100%;
    }
}
@media only screen and (max-width: 360px) {
    .first_name_root{
       flex-direction: column;
    }
    .field_name div:first-of-type{
        min-width:150px ;
    }
} */
.login_page_title {
    background: #D9A22E !important;
}

.login_page_form {
    display: flex;
    justify-content: center;
}

.login_page_card {
    margin-top: 30px;
    width: 60%;
    height: 550px;
    display: flex;
    /* justify-content: center; */
    border: 2px solid #074a6e;
}

.login_page_card_left {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #fff; */
    padding: 0;
    background: rgba(0, 0, 0, 0.5) url(/static/media/welcomeImg.2bbd9e35.png);
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    background-size: 100% 100%;
    background-origin: content-box;
}

.login_left_img_text {
    color: #fff;
    font-weight: 500;
    font-size: 25px;
    text-align: center;
}

.login_page_card_right {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* background-color: #074a6e; */
    position: relative;
}

.flic_logo {
    position: absolute;
    top: 10px;
    right: 10px;

}

.login_page_email_root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-bottom: 20px;
    margin-top: 20px;
}

.login_page_email_icon {
    margin-right: 20px;
}

.login_page_email_icon img {
    height: 45px;
    width: 45px;
}

.login_page_card_right .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill {
    border-radius: 30px;
}

.login_page_email_field input {
    background-color: #fff !important;
    border-radius: 10px;
    height: 16px;
    min-width: 300px;
}

.login_page_email_field fieldset {
    /* background-color: #fff; */
    border-radius: 30px;
    border: none;
    box-shadow: 0px 0px 4px #D9A22E;
}

.login_page_password_root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-bottom: 20px;
}

.login_page_password_icon {
    margin-right: 20px;
}

.login_page_password_icon img {
    width: 50px;
    height: 50px;
}

.login_page_password_field input {
    background-color: #fff;
    border-radius: 10px;
    height: 16px;
    min-width: 269px;
    color: #000;
}

.login_page_password_field fieldset {
    /* background-color: #fff; */
    border: none;
    box-shadow: 0px 0px 4px #D9A22E;
    border-radius: 30px;
}

.login_page_submit_root button {
    margin-top: 20px;
    background-color: #D9A22E;
    color: #fff;
    border-radius: 25px;
    padding: 10px 35px;
    font-size: 16px;
    font-weight: 600;
}

.login_page_submit_root button:hover {
    margin-top: 20px;
    background-color: #D9A22E;
    color: #fff;
    border-radius: 25px;
    padding: 10px 35px;
    font-size: 16px;
    font-weight: 600;
}

.login_page_card_right_other {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: #D9A22E;
    margin-top: 20px;
}

.login_forgot_password {
    cursor: pointer;
}

.login_submit_btn:disabled {
    opacity: 0.5;
}

@media screen and (max-width:1200px) {
    .login_page_card {
        width: 90%;
    }
}

@media screen and (min-width:1201px) and (max-width:1400px) {
    .login_page_card {
        width: 90%;
    }

}


@media screen and (max-width:900px) {
    .login_page_card {
        width: 70%;
        height: 500px;
    }

    .login_page_card_left {
        display: none;
    }

    .login_page_card_right {
        padding-top: 20px;
        width: 100%;
        padding-bottom: 20px;
    }

    .errLogin,
    .msgLogin {
        width: 80%;
        margin: 0 auto;
        margin-top: 100px;
    }
}


@media screen and (max-width:600px) {
    .login_page_password_field input {
        min-width: 211px;
    }

    .login_page_email_field input {
        min-width: 240px;
    }

    .errLogin,
    .msgLogin {
        width: 80%;
        margin: 0 auto;
        margin-top: 100px;
    }
}

@media screen and (max-width:500px) {
    .login_page_card {
        width: 90%;
    }

    .login_page_card_left img {
        width: 75%;
    }

    .errLogin,
    .msgLogin {
        width: 80%;
        margin: 0 auto;
        margin-top: 100px;
    }
}


@media screen and (max-width:380px) {
    .login_page_password_field input {
        min-width: 180px;
    }

    .login_page_email_field input {
        min-width: 205px;
    }

    .errLogin,
    .msgLogin {
        width: 80%;
        margin: 0 auto;
        margin-top: 100px;
    }
}

.errLogin,
.msgLogin {

    margin: 0 auto;

}
* {
  box-sizing: border-box;
}

.annuitySearchBarTextField input {
  padding: 10px 18px !important;
}

.annuitySearchBarTextField {
  background-color: rgb(241, 241, 241);
}

.annuitySearchBarTextField fieldset {
  border: none;
}

.annuity_application_table_root {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75%;
  margin: 0 auto;
}

.page_title_annuity_application {
  background: #D9A22E !important;
}

.annuity_application_table {
  /* margin-top: 20px; */
  /* border:1px solid black; */
}

.annuity_application_table {
  white-space: nowrap;
}

.annuity_application_table thead {
  margin-bottom: 10px;
}

.annuity_application_table thead tr {
  border-radius: 50px !important;
}

.annuity_application_table {
  /* margin-top: 20px; */
  border-collapse: separate !important;
  border-spacing: 0 15px !important;
}

.annuity_application_table_head th:nth-of-type(1) {
  border-radius: 8px 0 0 8px;
}

.annuity_application_table_head th:last-of-type {
  border-radius: 0 8px 8px 0;
}

.annuity_application_table_head th {
  background-color: #D9A22E !important;
  color: #fff;
  /* font-weight: 600; */
  font-size: 16px;
  padding: 10px;
  /* border-bottom: 10px solid #fff; */
}

.annuity_application_table_head th:nth-of-type(1) {
  width: 30%;
}

.annuity_application_table_head th:nth-of-type(2) {
  width: 20%;
}

.annuity_application_table_head th:nth-of-type(3) {
  width: 20%;
}

.annuity_application_table_head th:nth-of-type(4) {
  width: 15%;
}

.annuity_application_table_head th:nth-of-type(5) {
  width: 15%;
}


.annuity_application_view_button button {
  background-color: #D9A22E !important;
  color: #fff;
  text-transform: capitalize;
  border-radius: 8px;
  padding: 2px 12px 2px 12px;
  margin-right: 10px;
}

.annuity_application_view_button button:disabled {
  background-color: #074a6e;
  color: #fff;
  opacity: 0.5;
}

.annuity_application_view_button button:hover {
  background-color: #074a6e;
  color: #fff;
  text-transform: capitalize;
  border-radius: 8px;
  padding: 2px 12px 2px 12px;
}

.annuity_application_table_data {
  background-color: #f1f1f1;
  margin-top: 10px;
  border-radius: 50px;
}

.annuity_application_table_data td {
  padding: 5px 10px;
  font-size: 15px;
  /* border-bottom: 10px solid #fff; */
}

.annuity_application_table_data td:nth-of-type(1) {
  border-radius: 8px 0 0 8px;
}

.annuity_application_table_data td:last-of-type {
  border-radius: 0 8px 8px 0;
}


@media only screen and (max-width: 1150px) {
  .annuity_application_table_root {
    width: 80%;
  }
}

@media only screen and (max-width: 930px) {
  .annuity_application_table_root {
    width: 90%;
  }
}

@media only screen and (max-width:800px) {
  .annuity_application_table {
    min-width: 730px;
  }
}
* {
  box-sizing: border-box;
}

.page_title {
  background: #D9A22E !important
}

.agentsSearchBarBox {
  width: 52%;
  text-align: center;
  margin-top: 20px;
}

.agentsSearchBarTextField input {
  padding: 10px 18px !important;
}

.agentsSearchBarTextField {
  background-color: rgb(241, 241, 241);
}

.agentsSearchBarTextField fieldset {
  border: none;
}

.agents_table_root {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin: 10px auto;
}

.agents_table {
  /* margin-top: 20px; */
  border-collapse: separate !important;
  border-spacing: 0 15px !important;
}

.agents_table {
  white-space: nowrap;
}

.agents_table thead {
  margin-bottom: 10px;
}

.agents_table thead tr {
  border-radius: 50px !important;
}

.agents_table_head th {
  background-color: #D9A22E !important;
  color: #fff;
  font-size: 16px;
  padding: 8px;
}

.agents_table_head th:nth-of-type(1) {
  border-radius: 8px 0 0 8px;
}

.agents_table_head th:last-of-type {
  border-radius: 0 8px 8px 0;
}

.agents_status_button button {
  color: #000;
  text-transform: capitalize;
  border-radius: 8px;
  padding: 2px;
}

.agents_table_data {
  background-color: #f1f1f1;
  margin-top: 10px;
  border-radius: 50px;
}

.agents_status_selection {
  width: 100px;
}

.agents_status_selection::after {
  border-bottom: none !important;
}

.agents_status_selection select {
  padding-right: 0 !important;
}

.agents_status_selection:before {
  border-bottom: 0 !important;
}

.agents_status label {
  display: none;
}

.agents_status_select {
  margin-top: 0 !important;
}

.agents_status_select:before {
  border: none !important;
}

.agent_status_option {
  padding: 10px !important;
}

.agent_status label {
  margin-top: -10px;
}

/* .agent_status .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
display: none;
} */
.agents_status_select div:first-of-type {
  min-width: 40px !important;
}

.agents_status_select:after {
  border: none !important;
}

.agents_table_data td {
  padding: 5px 10px;
  font-size: 16px;
}

.agents_table_data td:nth-of-type(1) {
  border-radius: 8px 0 0 8px;
}

.agents_table_data td:last-of-type {
  border-radius: 0 8px 8px 0;
}

.status_selection fieldset {
  border: none;
}

.status_selection div:first-of-type {
  padding: 0;
}


@media only screen and (max-width: 1150px) {
  .agents_table_root {
    width: 70%;
  }
}

@media only screen and (max-width: 950px) {
  .agents_table_root {
    width: 80%;
  }
}

@media only screen and (max-width: 580px) {
  .agents_table_root {
    width: 90%;
  }

  .agents_table {
    min-width: 470px;
    overflow-x: auto;
  }
}
/* .home_page_banner_root{
    background:url(../../assets/FarmersLife_Homepage_banner_field.png);
    background-repeat: no-repeat;
    height: 301px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin:auto 0;
} */

body {
    height: 100vh;

}

.home_page_banner_root {
    width: 100%;
    position: relative;
}


.start-e-app-btn-myga {
    background-color: #FCF6EB;
    border-radius: 20px;
    cursor: pointer;
}

.start-e-app-btn-fia {
    background-color: #e6edf0;
    border-radius: 20px;
    cursor: pointer;
}

.start_text_item {
    padding-bottom: 23px;
}

.start_text_myga {
    color: #D9A22E !important;
    font-size: 20px !important;
    font-weight: 500 !important;
    text-align: center;
}

.start_text_fia {
    color: #074A6E !important;
    font-size: 20px !important;
    font-weight: 500 !important;
    text-align: center;
}

.form_type {
    color: #000000 !important;
    font-weight: 500 !important;
    font-size: 20px !important;
}

.bottom_section {
    background-color: #FCF6EB !important;
    margin-top: 40px !important;
}

.resource_btns {
    font-size: 14px !important;
    color: white !important;
    text-transform: capitalize !important;
}


.illustration_btn,
.config_btn {
    background-color: #074A6E !important;
}

.history_btn,
.upload_btn {
    background-color: #D9A22E !important;
}

.illustration_btn_item {
    margin-right: 10px !important;
}


.btn_grp1 {
    width: 40%;
    display: flex;
    justify-content: start;
}

.btn_grp2 {
    width: 40%;
    display: flex;
    justify-content: center;
}

.go_file_select .MuiSelect-select {
    padding: 14px 14px 15px 14px !important;
}

.not_required_go_file label::after {
    content: "" !important;
}

/* .bold_application .css-10hburv-MuiTypography-root:hover{
    font-weight: bold !important;
} */

.myga_illu_btn {
    font-size: 14px !important;
    color: white !important;
    text-transform: capitalize !important;
    background-color: #D9A22E !important;
    width: 100% !important;
    white-space: nowrap;
}

.fia_illu_btn {
    font-size: 14px !important;
    color: white !important;
    text-transform: capitalize !important;
    background-color: #074A6E !important;
    width: 100% !important;
    white-space: nowrap;
}

.myga_start_btn {
    font-size: 14px !important;
    color: white !important;
    text-transform: capitalize !important;
    background-color: #D9A22E !important;
    width: 100% !important;
    white-space: nowrap;
}

.fia_start_btn {
    font-size: 14px !important;
    color: white !important;
    text-transform: capitalize !important;
    background-color: #074A6E !important;
    width: 100% !important;
    white-space: nowrap;
}

.btngroup {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 75%;
    margin-top: 30px;
    padding-right: 40px;
}

.btn_grp1 .MuiButton-root {
    margin-right: 5%;
}

.btn_grp2 .btn {
    margin-right: 5%;
}

.btn_new,
.btn_view {
    background-color: #D9A22E !important;
    text-transform: none !important;
    color: black !important;
    border: none !important;
    font-size: 18px !important;
    margin-bottom: 10px !important;
}

.btn_new {
    border: 3px solid #074A6E !important;
    background-color: #074A6E !important;
}

.btn_new1,
.btn_view1 {
    text-decoration: none;
    color: black !important;
}

.btn_new1 {
    color: #fff !important;
}

.btn_new:hover {
    background-color: #074A6E !important;
}

.btn_new:hover .btn_new1 {
    color: white !important;
}

.btn_view1:hover {
    color: white !important;
}

.btn_view:hover {
    background-color: #074A6E !important;
}

.home_page_banner_root img {
    width: 100%;
    height: 300px;
}

.home_page_banner_text {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.home_page_banner_text {
    color: #fff;
    font-size: 30px;
    margin-top: 20px;
    font-weight: 500;
}

.home_page_main_content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.description_about_company {
    margin-top: 10px;
    width: 70%;
    font-size: 15px;
    font-weight: 400;
    text-align: left;
}

.btn_new_Config {
    padding: 10px;
    height: 50px;
    color: #074A6E !important;
    border: 1px solid #074A6E !important;
}

.group_of_box_root {
    /* width: 95%; */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 40px;
    margin-bottom: 20px;
}

.application_box_root {
    /* border: 1px solid #000; */
    border-radius: 10px;
    background-color: #074A6E !important;
    color: #fff !important;
    margin-bottom: 10px !important;
    height: -webkit-max-content !important;
    height: max-content !important;
}

.application_box_header {
    background-color: #D9A22E !important;
    border-radius: 10px 10px 0 0;
    text-align: center;
    font-size: 17px !important;
    color: #000000 !important;
    position: relative !important;
}

.application_box_root .list_item {
    padding-bottom: 0;
}

.application_box_root a {
    text-decoration: none;
    color: #fff;
}

.application_box_root a:hover {
    text-decoration: none;
    color: #D9A22E !important;
    cursor: pointer;
}

.application_box_root .list_item:hover {
    cursor: context-menu !important;
}

.home_page_footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: absolute;
    /* bottom:-245px; */
    left: 0;
    width: 100%;
    background: #074A6E;
    padding: 10px 20px;
    border-top: 5px solid #D9A22E;
}

.contact_us_section_footer {
    min-width: 34%;
    max-width: 25%;
    display: flex;
    padding-left: 40px;
    /* justify-content: center; */
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
    color: #FFFFFF;
}

.contact_detail_footer {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 200px;
}

.imp_link_section1_footer {
    border: none;
    min-width: 33%;
    max-width: 25%;
    list-style: none;
    color: #fff;
    display: flex;
    justify-content: center;
}

.imp_link_section1_footer ul {
    display: flex;
    flex-direction: column;

}

.imp_link_section1_footer li {
    border-left: none;
    list-style: disc;
    color: #D9A22E;
    padding-bottom: 0;
    width: auto;
}

.imp_link_section1_footer ul,
.imp_link_section2_footer ul {
    padding-top: 0;
    margin-top: 0;
}

.imp_link_section1_footer li span {
    color: #fff;
}

.imp_link_section2_footer {
    border: none;
    min-width: 25%;
    max-width: 25%;
    list-style: none;
    color: #fff;
    display: flex;
    justify-content: flex-start;

}

.imp_link_section2_footer li {
    border-left: none;
    list-style: disc;
    color: #D9A22E;
    padding-bottom: 0;

}

.imp_link_section2_footer li span {
    color: #fff;
}

.financial_rating_desc {
    margin-top: 20px !important;
    font-size: 16px;
    text-align: left;
    margin-bottom: 20px !important;
}

.copyright_info {
    /* width: 90%; */
    margin: 0 auto;
    text-align: center;
    color: #fff;
}

.financial_section_footer {
    min-width: 33%;
    max-width: 25%;
    display: flex;
    padding-left: 10px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}

.footer_links_with_hover {
    color: #fff !important;
    text-decoration: none !important;
}

.footer_links_with_hover:hover {
    color: #D9A22E !important;
    cursor: pointer !important;
}

.application_box_root {
    /* margin-left: 20px !important; */
    height: -webkit-max-content !important;
    height: max-content !important;
    width: 20vw !important;
}

.box_margin_right {
    margin-right: 10px !important;
}

.group_of_box_root .MuiBox-root {
    display: flex;
    flex-direction: row;
}

.notice_icon .MuiListItemIcon-root {
    min-width: 21px !important;
}

.notice_icon {
    min-width: 21px !important;
}

.Config {

    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

@media screen and (max-width: 900px) {
    .application_box_root {
        width: 42vw !important;
    }
}

@media screen and (max-width: 470px) {
    .group_of_box_root {
        justify-content: center !important;
    }
}

@media (max-width:900px) {
    .contact_us_section_footer {
        min-width: 50%;
        max-width: 50%;
        padding: 0;
    }

    .imp_link_section1_footer {
        min-width: 50%;
        max-width: 50%;
        justify-content: flex-start;
    }

    .imp_link_section2_footer {
        min-width: 50%;
        max-width: 50%;
        margin-top: 20px;
    }

    .financial_section_footer {
        max-width: 50%;
        min-width: 50%;
        margin-top: 20px;
        justify-content: flex-start;
        align-items: flex-start;
    }




    .btn_grp1 {
        width: 100%;
        justify-content: space-evenly;
        display: flex;
    }

    .btn_grp2 {
        width: 100%;
        justify-content: space-evenly;
        display: flex;
    }

    .btngroup {
        flex-direction: column;
        padding: 0;
        justify-content: center;
    }

    .btn_grp1 .MuiButton-root {
        margin-right: 0;
    }

    .btn_grp2 .btn {
        margin-right: 0;
    }
}

@media (max-width:470px) {
    .contact_us_section_footer {
        min-width: 100%;
        max-width: 100%;
        padding: 0;
    }

    .Config {
        width: 80%;
        display: flex;
        margin: 0 auto;
        flex-direction: column;
        margin-bottom: 10px;
    }

    .contact_detail_footer {
        width: 100%;
    }

    .imp_link_section1_footer {
        min-width: 100%;
        max-width: 100%;
        justify-content: center;
        margin-top: 20px;
    }

    .imp_link_section2_footer {
        min-width: 50%;
        max-width: 50%;
        margin-top: 20px;
    }

    .financial_section_footer {
        max-width: 100%;
        min-width: 100%;
        margin-top: 20px;
        justify-content: center;
        align-items: center;
    }

    .application_box_root {
        max-width: 350px !important;
        min-width: 350px !important;
    }

    .btn_grp1 {
        width: 80%;
        justify-content: space-between;
        display: flex;
        margin: 0 auto;
        flex-direction: column;
    }

    .btn_grp2 {
        width: 80%;
        justify-content: center;
        display: flex;
        margin: 0 auto;
        flex-direction: column;
    }

    .btngroup {
        flex-direction: column;
        padding: 0;
        justify-content: center;
    }

    .btn_grp1 .MuiButton-root {
        margin-right: 0;
    }

    .btn_grp2 .btn {
        margin-right: 0;
    }
}

@media screen and (min-width:0px) and (max-width:420px) {
    .description_about_company {
        width: 83%;
        text-align: justify;
    }

    .Config {
        width: 80%;
        display: flex;
        margin: 0 auto;
        flex-direction: column;
        margin-bottom: 10px;
    }

}

@media screen and (min-width:377px) and (max-width:899px) {
    .illustration_btn {
        width: 105% !important;
    }

    .myga_illu_btn {
        width: 90% !important;
    }

    .config_btn {
        width: 100% !important;
    }

    .myga_start_btn {
        width: 90% !important;
    }

    .fia_start_btn {
        width: 90% !important;
    }

    .fia_illu_btn {
        width: 90% !important;
    }

    .upload_btn {
        width: 106% !important;
    }
}

@media screen and (min-width:0px) and (max-width:376px) {
    .illustration_btn {
        width: 90vw !important;
    }

    .myga_illu_btn {
        width: 70vw !important;
    }

    .config_btn {
        width: 90vw !important;
        margin-bottom: 5px !important;
    }

    .myga_start_btn {
        width: 70vw !important;
        margin-bottom: 5px !important;
    }

    .fia_start_btn {
        width: 70vw !important;
        margin-bottom: 5px !important;
    }

    .fia_illu_btn {
        width: 70vw !important;
    }

    .start_text_fia {
        padding: 0 10px;
    }

    .upload_btn {
        width: 90vw !important;
    }

    .history_btn {
        width: 90vw !important;
    }
}

@media screen and (min-width:900px) and (max-width:1077px) {
    .btngroup {
        width: 84%;
    }

    .description_about_company {
        width: 84%;
        text-align: justify;
    }

}

@media screen and (min-width:901px) and (max-width:1350px) {
    .btngroup {
        flex-wrap: unset;
    }

    .btn_grp1 {
        width: 45%;
    }

    .Config .MuiButtonBase-root {
        height: 75px;
        align-items: center;
        display: flex;
        margin-right: 5%;
    }
}

.listItemIcon {
    padding-left: 6px;
}

.listItemIcon svg {
    margin-right: 5px !important;
}

.listItemButton {
    margin: 0px -20px !important;
}
@media screen and (min-width:0px) and (max-width: 400px) {
    .financial_rating_desc {
        width: 100% !important;
    }
}
* {
    box-sizing: border-box;

}

.alertIllinois {
    background-color: #f1e9df70 !important;
}

.linkIl {
    text-decoration: underline;
    color: #014361 !important;
    font-size: 16px;
}

.ownerReplacementContainer {
    margin-top: 10px;
}

.radio_group_shift_right {
    margin-left: 15px;
}

.replacementTableHead {
    background-color: #074A6E !important;
    border-radius: 5px !important;
}

.replacementTableHead th {
    color: white !important;
    padding: 10px 10px 10px 14px;
}

.replacementTableRow {
    background-color: #F1F1F1 !important;
}

.replacementTableContainer {
    border-radius: 5px 5px 5px 5px !important;
    margin-top: 18px;
}

.replacementTableCell {
    border-bottom: 5px solid white !important;
    padding: 0px !important;
}

.replacementTableCellDisableColor {
    color: rgb(189, 189, 189) !important;
}

.annuitant_gender_and_dateofbirth_row .annuitant_dateofbirth_field input {
    width: 100%;
    color: black;

}

.dateCloseIcon {
    -webkit-transform: translateX(-30px);
            transform: translateX(-30px);
    z-index: 1;
}

.annuitant_gender_and_dateofbirth_row .annuitant_dateofbirth_field {
    width: 47.3%;
}

.annuitant_gender_and_dateofbirth_row .annuitant_dateofbirth_field .MuiFormControl-root {
    width: 100%;
}

.annuitant_input_fields .annuitant_gender_and_dateofbirth_row .annuitant_dateofbirth_field label,
.annuitant_owner_section .annuitant_gender_and_dateofbirth_row .annuitant_dateofbirth_field label {
    color: rgba(0, 0, 0, 0.6);
    bottom: 20px !important;
    left: -16px !important;
}

.dobColorClass label {
    color: rgba(0, 0, 0, 0.38) !important;
}

.DatePickerCrossIcon:hover {
    border: none;
    background-color: transparent !important;
}

.annuitant_owner_section .annuitant_gender_and_dateofbirth_row .annuitant_gender_selection .input_field_width .MuiInput-root {
    width: 100% !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    min-width: 400px;
}

.application_page_root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

/* @media screen and (max-width: 520px) {
    .application_page_root{margin-top: 100px !important;}
} */

.application_form {
    width: 50%;
    margin-top: 30px;
    margin-bottom: 10px;
    /* border: 2px solid #074a6e; */
    border-radius: 5px;
    display: flex;
    justify-content: center;
    /* filter: drop-shadow(0px 0px 2px #074a6e); */
}


.application_form_card {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.application_form_head {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    color: #074a6e;
    font-weight: 600;
    font-size: 20px;
    width: 100%;
}

.application_form_head_bottom_line {
    height: 20px;
    width: 80%;
    margin: 0 auto;
    margin-top: 10px;
}

.application_form_head_bottom_line hr {
    color: #074a6e;
    background-color: #074a6e;
}

.annuitant_head_root {
    margin-top: 15px;
    display: flex;
    width: 80%;
    font-size: 15px;
    font-weight: 600;
    justify-content: space-between;
    align-items: center;
    color: #074a6e;
}

#head_root {
    width: 100%;
    margin-top: 15px;
    display: flex;
    font-size: 15px;
    font-weight: 600;
    justify-content: space-between;
    align-items: center;
    color: #074a6e;
    /* margin-bottom: 20px; */
}

.annuitant_input_fields {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 80%;
}

.annuitant_fullname_and_ssn_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_gender_and_dateofbirth_row,
.annuitant_streetaddress_and_zip_row,
.annuitant_city_and_state_row,
.annuitant_phone_and_email_row,
.annuitant_fullname_and_ssn_row {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

/* .annuitant_dateofbirth_field{
    height: 0em;
} */
::-webkit-input-placeholder {
    color: #B3B3B3;
    text-align: left;
}
::placeholder {
    color: #B3B3B3;
    text-align: left;
}

.annuitant_gender_selection {
    min-width: 215px !important;
    border: none;
    width: 50% !important;
}

.annuitant_dateofbirth_field {
    display: flex;
    justify-content: flex-start;

}

.beneficiaries_field_width_name {
    width: 150px !important;
}

.beneficiaries_field_width_per {
    width: 50px !important;
}

.beneficiaries_field_width_relation {
    width: 100px !important;
}

.beneficiaries_field_width_ssn {
    width: 150px !important;
}

.annuitant_gender_selection_gender3 {
    width: 100% !important;
}

.annuitant_gender_selection_gender1 {
    width: 25% !important;
}

.annuitant_gender_selection_gender2 {
    width: 95% !important;
}


.annuitant_dateofbirth_field fieldset {
    /* min-width: 300px; */
    border: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid #B3B3B3 !important;
}

.annuitant_gender_and_dateofbirth_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_streetaddress_and_zip_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_city_and_state_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

/* .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper{
    max-width: 360px !important;
} */
.annuitant_phone_and_email_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_owner_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    flex-direction: column;
}

.left_section {
    width: 50%;
    display: flex;
    justify-content: flex-start;
}

.annuitant_gender_selection .annuitant_dateofbirth_field {
    width: 100%;
}

.input_field_width {
    min-width: 95% !important;

}

.date_field {
    width: 100%;
    border: 2px solid red;

}

/* .annuitant_head_root{
    margin-bottom: 10px;
} */

.right_section {
    display: flex;
    justify-content: flex-end;
    width: 50%;
}

.nor_states_select {
    display: flex;
    justify-content: flex-end;
    width: 55%;
    /* margin-left: 10px; */
}

/* .owner_replacement_first_question{
    font-family: sans-serif;
} */

.plan_rider_section {
    width: 80%;
    margin-top: 10px;
    margin-bottom: -10px;
}

.rider_section {
    margin-top: 10px;
}

.beneficiaries_section1 {
    margin-bottom: 10px;
}

.plan_rider_checkboxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: -15px !important;
}

.beneficiaries_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    flex-direction: column;
    margin-top: 10px !important;

}

.annuitant_dateofbirth_field label {
    margin-top: 15px;
}

.annuitant_dateofbirth_field input {
    padding-left: 0;
    padding-right: 14px;
    padding-bottom: 6px;
    margin-top: 10px;
}

.annuitant_dateofbirth_field {
    margin-bottom: 8px;
}

.annuitant_dateofbirth_field button {
    padding-bottom: 0px;
    padding-top: 21px;

}

.beneficiaries_section_fields {
    width: 100% !important;
    padding-top: 15px;
}

.beneficiaries_section_row_1,
.beneficiaries_section_row_2,
.beneficiaries_section_row_3 {
    padding-right: 10px;
}

.beneficiaries_section_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding-bottom: 10px;
}

.annuitant_streetaddress_and_zip_row,
.annuitant_gender_and_dateofbirth_row,
.annuitant_fullname_and_ssn_row,
.annuitant_city_and_state_row,
.annuitant_phone_and_email_row,
.policy_company_number_array_fields_row,
.policy_company_number_array_fields_row_2 {
    padding-top: 10px;
}

.isContractsHasBeenInvolved {
    width: 80%;
}

.req_fields label::after {
    content: '*';
    color: red;
}

.owner_replacement_not_required input {
    padding: 10px 14px !important;
    font-size: 15px !important;
}

/* .not_required input {
    padding: 10px 14px !important;
    font-size: 15px !important;
} */

.not_required fieldset {
    border: none !important;
}

.not_required label::after {
    content: '';
}

.ptqs_qualified_options>div>label,
.ptqs_non_qualified_options>div>label {
    margin-right: 50px !important;
}

.ptqs_non_qualified_options,
.ptqs_qualified_options {
    padding: 0 !important;
}

.annuitant_head_root:hover {
    cursor: pointer !important;
}

.section_open_close:hover {
    cursor: pointer;
}


.owner_replacement_question_margin_top {
    margin-top: 15px;
}

/* .policy_company_number_array_fields {
    margin-top: -10px;
} */
@media only screen and (min-width: 821px) and (max-width: 3000px) {
    .application_form {
        border: 2px solid #074a6e;
        margin-top: 70px !important;
    }
}

@media only screen and (min-width: 1000px) and (max-width: 3000px) {
    .dateCloseIcon {
        display: none !important;
    }
}

@media only screen and (min-width: 821px) and (max-width: 1050px) {
    .application_form {
        width: 95% !important;
        margin-top: 80px !important;

    }

    .owner_replacement_question_main {
        margin-top: 15px;
        margin-bottom: -10px;
    }

    .new_application_form .btn_send_this_link_to_applicant {
        display: flex;
        justify-content: center;
        margin-top: 5px;
    }

    .application_form .application_form_card .application_form_head {
        margin-top: 30px;
    }

    .application_form .application_form_card .application_form_head .application_form_head_bottom_line {
        width: 90%;
    }

    .application_form .application_form_card .annuitant_input_fields,
    .application_form .application_form_card .annuitant_owner_section,
    .application_form .application_form_card .plan_rider_section,
    .application_form .application_form_card .beneficiaries_section,
    .application_form .application_form_card .premium_and_tax_qualification_status_main {
        width: 88%;
    }

    .application_form .application_form_card .owner_replacement_question,
    .application_form .application_form_card .annuitant_head_root {
        width: 88% !important;

    }

    .application_form .application_form_card .annuitant_head_root {
        width: 88%;

    }

    .application_form .application_form_card .application_form_head .application_form_head_1 {
        font-size: revert;
    }

    .application_form .application_form_card .annuitant_input_fields .annuitant_gender_and_dateofbirth_row .annuitant_dateofbirth_field .input_field_width {
        width: 500px;
    }

    .application_form .application_form_card .safeguard_plus_width {
        width: 88% !important;
    }
}


@media only screen and (min-width:1050px) and (max-width: 1300px) {
    .application_form {
        border: 0 !important;
        margin-top: 80px;
    }
}

@media screen and (min-width:401px) and (max-width: 820px) {
    .application_form {
        margin-top: 70px !important;
    }

    .application_form .application_form_card .annuitant_owner_section_bottom {
        margin-bottom: 10px !important;
    }

    .not_required input {
        font-size: 14px !important;
    }


    .beneficiaries_field_width_name {
        width: 100% !important;
    }

    .beneficiaries_field_width_per {
        width: 100% !important;
    }

    .beneficiaries_field_width_relation {
        width: 100% !important;
    }

    .beneficiaries_field_width_ssn {
        width: 100% !important;
    }

    .annuitant_gender_selection_gender2 {
        width: 100% !important;
    }

    .annuitant_gender_selection_gender3 {
        width: 98% !important;
    }

}

@media screen and (min-width:350px) and (max-width:820px) {
    .ptqs_qualified_box {
        width: 95% !important;
    }
}


@media only screen and (min-width:0px) and (max-width: 420px) {
    .application_form {
        width: 95% !important;
        margin-top: 30px !important;
    }

    .not_required input {
        font-size: 13px !important;
    }

    .beneficiaries_field_width_name {
        width: 100% !important;
    }

    .beneficiaries_field_width_per {
        width: 100% !important;
    }

    .beneficiaries_field_width_relation {
        width: 100% !important;
    }

    .beneficiaries_field_width_ssn {
        width: 100% !important;
    }

    .annuitant_gender_selection_gender2 {
        width: 100% !important;
    }

    .annuitant_gender_selection_gender3 {
        width: 98% !important;
    }

    /* .application_link_button{
            left: 42.5% !important;
        } */
    .beneficiaries_section_row {
        padding-bottom: 0px !important;
    }

    .application_form .application_form_card .annuitant_input_fields .annuitant_gender_and_dateofbirth_row .annuitant_dateofbirth_field {
        width: 95%;
        margin-bottom: 0;
    }

    .application_form .application_form_card .header_1 {
        margin-top: 0px !important;
    }

    .application_form .application_form_card .annuitant_owner_section .annuitant_gender_and_dateofbirth_row .annuitant_dateofbirth_field .input_field_width {
        width: 90%;
    }

    .application_form .application_form_card .premium_and_tax_qualification_status_main .amount_to_paid_with_application {
        margin: 15px 0px !important;
    }

    .annuitant_streetaddress_and_zip_row .right_section,
    .annuitant_gender_and_dateofbirth_row .right_section,
    .annuitant_fullname_and_ssn_row .right_section,
    .annuitant_city_and_state_row .right_section,
    .annuitant_phone_and_email_row .right_section,
    .beneficiaries_section_row .right_section,
    .policy_company_number_array_fields_row .right_section,
    .policy_company_number_array_fields_row_2 .right_section {
        padding-top: 10px;
    }

    .application_form .application_form_card .owner_replacement_question_main {
        margin-top: 15px;
    }

    /* .application_form .application_form_card   .owner_replacement_question .policy_company_number_array_fields{
    margin-top: -20px;
} */
    .application_form .application_form_card .annuitant_input_fields .annuitant_gender_and_dateofbirth_row .annuitant_dateofbirth_field .input_field_width {
        width: 100%;
    }

    .application_form .application_form_card .annuitant_input_fields {
        width: 95%;
    }

    .application_form .application_form_card .annuitant_input_fields .annuitant_fullname_and_ssn_row,
    .application_form .application_form_card .annuitant_input_fields .annuitant_gender_and_dateofbirth_row,
    .application_form .application_form_card .annuitant_input_fields .annuitant_streetaddress_and_zip_row,
    .application_form .application_form_card .annuitant_input_fields .annuitant_city_and_state_row,
    .application_form .application_form_card .annuitant_input_fields .annuitant_phone_and_email_row {
        width: 100%;
        justify-content: center;
        flex-direction: column;
        margin-top: 5px;
        margin-bottom: -5px;
    }

    .application_form .application_form_card .application_form_head {
        margin-top: 20px;
    }

    .application_form .application_form_card .annuitant_owner_section {
        width: 100%;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .application_form .application_form_card .annuitant_head_root {
        margin-top: 20px;
        width: 90%;
        font-size: 14px;
    }

    .application_form .application_form_card .beneficiaries_section .beneficiaries_section_fields .beneficiaries_section_row .beneficiaries_section_row_1,
    .application_form .application_form_card .beneficiaries_section .beneficiaries_section_fields .beneficiaries_section_row .beneficiaries_section_row_2,
    .application_form .application_form_card .beneficiaries_section .beneficiaries_section_fields .beneficiaries_section_row .beneficiaries_section_row_3,
    .application_form .application_form_card .beneficiaries_section .beneficiaries_section_fields .beneficiaries_section_row .annuitant_gender_selection {
        width: 100% !important;
        margin-top: 10px !important;

    }

    .application_form .application_form_card .policy_company_number_array_fields .policy_company_number_array_fields_row {
        width: 100%;
        margin-top: 0px !important;

    }

    .application_form .application_form_card .safeguard_plus_qnnuity_wth_guarantee_period {
        width: 90% !important;
        margin-top: 15px !important;
    }

    /* .owner_replacement_question{
       margin-bottom: -27px !important;
   }   */

    .application_form .application_form_card .annuitant_input_fields .annuitant_gender_and_dateofbirth_row.annuitant_dateofbirth_field .input_field_width {
        width: 97% !important;
    }


    /* .policy_company_number_array_fields_row_1 .css-1u3bzj6-MuiFormControl-root-MuiTextField-root ,
    .application_form .application_form_card  .policy_company_number_array_fields  .policy_company_number_array_fields_row  
    .policy_company_number_array_fields_row_2 .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
        width: 100% !important;
     }
        .application_form .application_form_card  .beneficiaries_section  .beneficiaries_section_fields .beneficiaries_section_row 
         .annuitant_gender_selection .css-1nrlq1o-MuiFormControl-root .css-1ft4ntv-MuiInputBase-root-MuiInput-root{
             width: 100%;
    } */
    .new_application_form .btn_send_this_link_to_applicant {
        display: flex;
        justify-content: center;
        margin-top: -5px;
    }

    .application_form .application_form_card .beneficiaries_section,
    .application_form .application_form_card .plan_rider_section,
    .application_form .application_form_card .premium_and_tax_qualification_status_main,
    .application_form .application_form_card .owner_replacement_question {
        width: 90% !important;
        margin-top: 10px;
    }

    .application_form .application_form_card .owner_replacement_question {
        margin-top: 5px !important;
    }

    .application_form .application_form_card .premium_and_tax_qualification_status_main .ptqs_row .MuiFormGroup-root .ptqs_qualified_box {
        margin-top: 10px;
    }

    .application_form .application_form_card .annuitant_input_fields .annuitant_gender_and_dateofbirth_row .annuitant_dateofbirth_field .input_field_width label {
        left: -11px !important;
        bottom: 20px;
    }

    .application_form .application_form_card .annuitant_input_fields .annuitant_fullname_and_ssn_row .right_section,
    .application_form .application_form_card .annuitant_input_fields .annuitant_fullname_and_ssn_row .left_section,
    .application_form .application_form_card .annuitant_input_fields .annuitant_gender_and_dateofbirth_row .left_section,
    .application_form .application_form_card .annuitant_input_fields .annuitant_gender_and_dateofbirth_row .right_section,
    .application_form .application_form_card .annuitant_input_fields .annuitant_streetaddress_and_zip_row .left_section,
    .application_form .application_form_card .annuitant_input_fields .annuitant_streetaddress_and_zip_row .right_section,
    .application_form .application_form_card .annuitant_input_fields .annuitant_city_and_state_row .left_section,
    .application_form .application_form_card .annuitant_input_fields .annuitant_city_and_state_row .right_section,
    .application_form .application_form_card .annuitant_input_fields .annuitant_phone_and_email_row .left_section,
    .application_form .application_form_card .annuitant_input_fields .annuitant_phone_and_email_row .right_section {
        justify-content: center;
        width: 95% !important;
    }

    .application_form .application_form_card .annuitant_owner_section .annuitant_fullname_and_ssn_row,
    .application_form .application_form_card .annuitant_owner_section .annuitant_gender_and_dateofbirth_row,

    .application_form .application_form_card .annuitant_owner_section .annuitant_city_and_state_row,
    .application_form .application_form_card .annuitant_owner_section .annuitant_phone_and_email_row {
        width: 95%;
        justify-content: center;
        flex-direction: column;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
        /* margin-top: -5px;
    margin-bottom: -5px; */
    }

    .application_form .application_form_card .annuitant_owner_section .annuitant_streetaddress_and_zip_row {
        width: 95%;
        justify-content: center;
        flex-direction: column;
        margin-top: -8px !important;
        margin-bottom: 0px !important;
    }

    .application_form .application_form_card .annuitant_owner_section .annuitant_fullname_and_ssn_row .right_section,
    .application_form .application_form_card .annuitant_owner_section .annuitant_fullname_and_ssn_row .left_section,
    .application_form .application_form_card .annuitant_owner_section .annuitant_gender_and_dateofbirth_row .right_section,
    .application_form .application_form_card .annuitant_owner_section .annuitant_streetaddress_and_zip_row .left_section,
    .application_form .application_form_card .annuitant_owner_section .annuitant_streetaddress_and_zip_row .right_section,
    .application_form .application_form_card .annuitant_owner_section .annuitant_city_and_state_row .left_section,
    .application_form .application_form_card .annuitant_owner_section .annuitant_city_and_state_row .right_section,
    .application_form .application_form_card .annuitant_owner_section .annuitant_phone_and_email_row .left_section,
    .application_form .application_form_card .annuitant_owner_section .annuitant_phone_and_email_row .right_section {
        justify-content: center;
        width: 95%;
    }

    .application_form .application_form_card .annuitant_owner_section .annuitant_gender_and_dateofbirth_row .left_section {
        justify-content: center;
        padding-left: 1px !important;
        width: 95% !important;
    }

    .application_form .application_form_card .annuitant_owner_section .annuitant_gender_and_dateofbirth_row .left_section .input_field_width {
        width: 100%;
    }

    .application_form .application_form_card .annuitant_owner_section .annuitant_gender_and_dateofbirth_row .right_section {
        justify-content: center;
        width: 95% !important;
        padding-top: 0px;
    }

    .application_form .application_form_card .beneficiaries_section .beneficiaries_section_fields .beneficiaries_section_row {
        width: 100%;
        justify-content: center;
        flex-direction: column;
    }

    .policy_company_number_array_fields_row {
        margin-bottom: 10px;
    }

    .policy_company_number_array_fields_row_1 label {
        font-size: 0.901rem;
    }

    /* .application_form .application_form_card  .beneficiaries_section  .beneficiaries_section_fields .beneficiaries_section_row .MuiBox-root .css-wh8j8b-MuiFormControl-root-MuiTextField-root,
    .application_form .application_form_card  .beneficiaries_section  .beneficiaries_section_fields .beneficiaries_section_row .MuiBox-root .css-1iezzy6-MuiFormControl-root-MuiTextField-root ,
    .application_form .application_form_card  .beneficiaries_section  .beneficiaries_section_fields .beneficiaries_section_row .MuiBox-root .css-xmbdxf-MuiFormControl-root-MuiTextField-root ,
    .application_form .application_form_card  .beneficiaries_section  .beneficiaries_section_fields .beneficiaries_section_row .annuitant_gender_selection .input_field_width 
    {
        width: 100% ;
    }  */

    /* .application_form .application_form_card  .beneficiaries_section  .beneficiaries_section_fields .owner_replacement_question 
    .policy_company_number_array_fields .policy_company_number_array_fields_row .css-1u3bzj6-MuiFormControl-root-MuiTextField-root 
    {
        width: 100%;
    } */
    /* 
    .application_form {
        margin-top: 15px;
    }
    .annuitant_head_root {
        margin-top: 0px;
        width: 90%;
        font-size: 13px;
    }
    
    .annuitant_gender_selection.left_section{
        width: 95%;
        margin-left: 20px;
    }
    .MuiTypography-root {
        margin: 0;
        width: 100%;
    }

    
    

    .css-wh8j8b-MuiFormControl-root-MuiTextField-root{
        width: 100%;
    }
    #head_root {
        font-size: 13px;
        padding-bottom: 10px;
    }
    element.style .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root 
    {
        margin: 0px 5px;
        width: 100%;
    }
     */

}

/* 
@media screen and (min-width:1050px) and (max-width: 1300px)
{
    .application_form {
        width: 95% !important;
        margin-top: 20px !important;
        
    }
   
} */
@media only screen and (min-width: 1050px) and (max-width: 1300px) {
    .application_form {
        width: 95% !important;
        margin-top: 100px !important;
        border: 2px solid #074a6e !important;
    }

    .application_form_head {
        margin-top: 30px;
    }
}

@media only screen and (min-width: 401px) and (max-width: 820px) {
    .application_form {
        width: 85% !important;
        margin-top: 30px !important;

    }

    .application_form_head {
        margin-top: 20px;
    }

    .application_form .application_form_card .premium_and_tax_qualification_status_main .ptqs_row .MuiFormGroup-root .ptqs_qualified_box {
        margin-top: 15px !important;
    }
}

@media only screen and (min-width: 421px) and (max-width: 820px) {
    .application_form .application_form_card .beneficiaries_section .beneficiaries_section_fields .beneficiaries_section_row {
        width: 100%;
        justify-content: center;
        flex-direction: column;
    }

    .application_form .application_form_card .beneficiaries_section .beneficiaries_section_fields .beneficiaries_section_row .beneficiaries_section_row_1,
    .application_form .application_form_card .beneficiaries_section .beneficiaries_section_fields .beneficiaries_section_row .beneficiaries_section_row_2,
    .application_form .application_form_card .beneficiaries_section .beneficiaries_section_fields .beneficiaries_section_row .beneficiaries_section_row_3,
    .application_form .application_form_card .beneficiaries_section .beneficiaries_section_fields .beneficiaries_section_row .annuitant_gender_selection {
        width: 100% !important;
        margin-top: 10px !important;

    }

    .annuitant_gender_selection_gender2 {
        width: 100% !important;
    }
}

@media screen and (min-width:1300px) and (max-width:1613px) {
    .ptqs_qualified_box {
        margin-top: 10px;
    }

    .beneficiaries_field_width_name {
        width: 130px !important;
    }

    .beneficiaries_field_width_per {
        width: 30px !important;
    }

    .beneficiaries_field_width_relation {
        width: 100px !important;
    }

    .beneficiaries_field_width_ssn {
        width: 100px !important;
    }

    .annuitant_gender_selection_gender2 {
        width: 70% !important;
    }

    .annuitant_gender_selection_gender3 {
        width: 160px !important;
    }
}
.previous_next_btn {
    display: flex;
    justify-content: space-between;
    padding-left: 120px;
    padding-right: 120px;
}


* {
    box-sizing: border-box;
}

.radio_group_shift_right {
    margin-left: 15px !important;
}

.suitability_full_name {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.current_age,
.suitability_full_name,
.gender,
.employment_status,
.current_occupation,
.dependents {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.Financial_Information_Of_Owner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.suitability_left_part {
    width: 33%;
    padding-top: 17px;
    display: flex;
    justify-content: flex-start !important;
}

.middle_part {
    width: 33%;
    display: flex;
    justify-content: flex-start;
}

.suitability_middle_part {
    width: 33%;
    display: flex;
    justify-content: flex-start;
}

.suitability_right_part {
    width: 33%;
    display: flex;
    justify-content: flex-start;
}

.suitability_analysis_page_root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.financial_situation_and_need_of_owner {
    margin-bottom: -20px;
}

.application_form {
    width: 50%;
    margin-top: 30px;
    margin-bottom: 10px;
    /* border:2px solid #074a6e; */
    border-radius: 5px;
    display: flex;
    justify-content: center;
    /* filter: drop-shadow(0px 0px 2px #074a6e); */
}

.first_question {
    margin-bottom: -5px !important;
}


.personal_information_main .gender .suitability_middle_part .suitability_middle_part_input_field_width,
.personal_information_main .gender .suitability_right_part .suitability_right_part_input_field_width,
.personal_information_main .employment_status .suitability_middle_part .suitability_middle_part_input_field_width,
.personal_information_main .employment_status .suitability_right_part .suitability_right_part_input_field_width {
    width: 195px !important;
}

.personal_information_main .current_age .suitability_middle_part .input_field_width,
.personal_information_main .current_age .suitability_right_part .input_field_width {
    min-width: 50% !important;
    max-width: 50% !important;
}

.application_form_card {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.application_form_head {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    color: #074a6e;
    font-weight: 600;
    font-size: 20px;
    width: 100%;
}

.application_form_head_bottom_line {
    height: 20px;
    width: 80%;
    margin: 0 auto;
    margin-top: 10px;
}

.application_form_head_bottom_line hr {
    color: #074a6e;
    background-color: #074a6e;
}

.suitability_section_head_root {
    margin-top: 15px !important;
    display: flex;
    width: 80%;
    font-size: 15px;
    font-weight: 600;
    justify-content: space-between;
    align-items: center;
    color: #074a6e;
}

.annuitant_input_fields {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 80%;
}

.annuitant_fullname_and_ssn_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

::-webkit-input-placeholder {
    color: #B3B3B3;
    text-align: left;
}

::placeholder {
    color: #B3B3B3;
    text-align: left;
}

.annuitant_gender_selection {
    min-width: 200px;
    border: none;
}

.annuitant_dateofbirth_field {
    display: flex;
    justify-content: flex-start;
}

.annuitant_dateofbirth_field fieldset {
    /* min-width: 300px; */
    border: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid #B3B3B3 !important;
}

.annuitant_gender_and_dateofbirth_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_streetaddress_and_zip_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_city_and_state_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_phone_and_email_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_owner_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    flex-direction: column;
}

.financial_section_labels label {
    color: #000;
    font-size: 15px;
}

.left_section {
    width: 50%;
    display: flex;
    justify-content: flex-start;
}

.input_field_width {
    min-width: 15% !important;
}

.right_section {
    display: flex;
    justify-content: flex-end;
    width: 50%;
}

.plan_rider_section {
    width: 80%;
}

.plan_rider_checkboxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.suitability_section_width {
    width: 80%;
}

.third_question_proper {
    flex-direction: column !important;
}

.third_question_proper1 {
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}

.third_question_proper2 {
    vertical-align: middle !important;
}

.first_question,
.second_question,
.third_question,
.fourth_question,
.fifth_question {
    margin-bottom: 15px !important;
}

.third_question {
    margin-bottom: -3px !important;
}

.first_question_bottom {
    margin-bottom: -5px !important;
}

.fourth_question_top {
    margin-top: 15px !important;
}

.seventh_question {
    margin-bottom: 15px !important;
}

.seventh_question_top {
    margin-bottom: 5px !important;
}

@media screen and (min-width: 0px) and (max-width: 420px) {

    .income_tax_bracket,
    .total_outstanding_debt,
    .annual_household_income,
    .source_of_income {
        width: 100% !important;
        justify-content: center !important;
    }

    #menu- .MuiPaper-root {
        width: 70% !important;

    }

    .previous_next_btn {
        flex-direction: column;
    }

    .btn_prev {
        margin: 15px 10px !important;
        width: 100%;
    }

    .label_field label {
        font-size: 0.901rem;
        line-height: 2rem;
    }

    .req_fields label::after {
        content: '*';
        color: red;
    }

    .right_space {
        margin-right: 8px;
        margin-left: 0px !important;
    }

    .fourth_question {
        margin-top: 25px !important;
    }

    .seventh_question {
        margin-top: 15px !important;
    }

    .first_question_top {
        margin-top: 10px !important;
    }

    .first_question_sutability {
        margin-top: 20px !important;
    }

    .the_product_purchase {
        margin-bottom: 15px;
    }

    .second_question_text_field {
        width: 85% !important;
        padding-left: 0px;
    }

    .btn_next {
        margin: 15px 10px !important;
        width: 250%;
        left: -80%;
    }

    .suitability_section_width {
        width: 85% !important;
    }

    .financial_information_of_owner_main {
        margin-top: -5px;
        margin-bottom: 10px;
    }

    .financial_situation_and_need_of_owner,
    .the_product_purchase,
    .existing_account_information,
    .other {
        width: 85% !important;
    }

    .first_question_btn {
        flex-direction: row !important;
    }

    .second_question_1 {
        display: flex;
        align-items: center;
        width: 100%;
        flex-wrap: wrap;
    }

    .second_question_2 {
        margin-top: -18px;
        padding-left: 87%;
        padding-bottom: 5px;
    }

    .suitability_section_head_root {

        width: 85%;
        font-size: 14px;
        margin-bottom: 10px;
        margin-top: 10px !important;
    }

    .suitability_section_head_root_top {
        margin-top: 10px !important;
    }

    .Financial_Information_Of_Owner {
        flex-direction: column !important;

    }

    .financial_section_labels,
    .income_tax_bracket {
        margin-bottom: 20px;
    }

    /* .css-qfz70r-MuiFormGroup-root {
    flex-direction: column !important;
    margin-top: 10px    ;
} */

    .annual_household_income {
        margin-bottom: 20px;
    }
}

@media screen and (min-width: 0px) and (max-width: 420px) {
    #menu- .MuiPaper-root {
        width: 70% !important;
    }
}

@media screen and (min-width :421px) and (max-width: 820px) {
    #menu- .MuiPaper-root {
        width: 25% !important;

        min-width: 0 !important;
    }
}

@media screen and (min-width:401px) and (max-width: 820px) {
    .application_form {
        margin-top: 70px !important;
    }

}

@media screen and (max-width: 1250px) {
    .suitability_left_part {
        width: 28%;
    }

    .suitability_right_part {
        width: 36%;
    }
}

@media screen and (max-width:525px) {
    .suitability_left_part {
        width: 100%;
    }

    .suitability_middle_part {
        width: 100%;
    }

    .suitability_right_part {
        width: 100%;
    }

    .suitability_left_part {
        padding-top: 5px;
    }

    .suitability_middle_part_input_field_width {
        margin-top: 8px !important;
        min-width: 100% !important;
    }

    .suitability_right_part_input_field_width {
        margin-top: 8px !important;
        min-width: 100% !important;
    }

    .current_age,
    .suitability_full_name,
    .gender,
    .employment_status,
    .current_occupation,
    .dependents {
        margin-top: 5px;
    }
}

.MuiPaper-elevation8 {
    min-width: 200px;
}

@media screen and (min-width: 1200px) {
    .btn_next .btn_prev {
        width: 100%;
    }
}
.treeview {
  right: -10px;
  width: 335px;
  padding: 15px 20px 30px 5px;
  font-size: 15px;
}

.border {
  border-left: rgb(189, 189, 189);
}

.main_component {
  padding-right: 40px;
}

.editable_button:hover {
  color: #fff !important;
  background: #074A6E !important;

}

.spn {
  color: rgb(189, 189, 189);
}

.right_section_title {
  font-size: 18px;
}

.right_section_title:hover {
  cursor: pointer;
}

.application_form_section_name {
  padding: 2px;
}

.treeview ul {
  float: left;
  width: 100%;
  position: relative;
  border-left: 8px solid rgb(189, 189, 189);
  height: 50px;
  border-radius: 3px;
  padding: 10px 10px 5px 15px;

}

.application_link_button {
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  top: 180px;
  right: 5%;
}

.middle_line {
  font-weight: 600;
  vertical-align: super;
}

.individual_annuity_application_right_section {
  margin-top: 60px;
  padding-top: 5px;
}

.suitability_analysis_right_section {
  margin-top: 385px;
}

.producer_disclosure_right_section {
  margin-top: 1000px;
}

.notice_of_replacement_right_section {
  margin-top: 388px;
}

.transfer_exchange_right_section {
  margin-top: 905px;
}

.individual_annuity_application_right_section li:last-of-type {
  height: 27px;
}

.notice_of_replacement_right_section li:last-of-type {
  height: 27px;
}

.suitability_analysis_right_section li:last-of-type {
  height: 27px;
}

.producer_disclosure_right_section li:last-of-type {
  height: 27px;
}

.suitability_analysis_right_section li:first-of-type {
  height: 43px;
}

.producer_disclosure_right_section li:first-of-type {
  height: 43px;
}

.suitability_analysis_right_section_exception li:first-of-type {
  height: 43px;
}

.producer_disclosure_right_section_exception li:first-of-type {
  height: 43px;
}

.transfer_exchange_right_section li:last-of-type {
  height: 27px;
}

.transfer_exchange_right_section_exception {
  margin-top: 765px;
}

.transfer_exchange_right_section_exception li:last-of-type {
  height: 27px;
}

.suitability_analysis_right_section_exception {
  margin-top: 510px;
}

.producer_disclosure_right_section_exception {
  margin-top: 1150px;
}

.suitability_analysis_right_section_exception li:last-of-type {
  height: 27px;
}

.producer_disclosure_right_section_exception li:last-of-type {
  height: 27px;
}

/* .current_page{
  color: #1976d2 !important;
} */
li {
  float: left;
  width: 100%;
  border-left: 2px solid rgb(189, 189, 189);
  padding: 10px 0;
  list-style-type: none;
}

@media screen and (max-width: 1450px) {
  .formsitemap .application_link_button .btn_send_this_link_to_applicant {
    position: relative;
    top: 3px;
    left: -24%;
  }
}

@media screen and (max-width: 1300px) {
  .formsitemap .application_link_button .btn_send_this_link_to_applicant {
    position: relative;
    top: 3px;
    left: -68%;
  }

  .editable_button {
    right: 3%;
  }
}

@media screen and (max-width: 1200px) {
  .formsitemap .application_link_button .btn_send_this_link_to_applicant {
    position: relative;
    top: 3px;
    left: -80%;
  }
}

@media screen and (max-width: 1100px) {
  .formsitemap .application_link_button .btn_send_this_link_to_applicant {
    position: relative;
    top: 3px;
    left: -63%;
  }
}

@media screen and (max-width: 800px) {
  .btn_send_this_link_to_applicant {
    position: relative;
    top: 38%;
    left: -80% !important;
  }
}

@media screen and (max-width: 1300px) {
  .editable_button {
    right: 3% !important;
    position: absolute !important;
  }
}


@media screen and (max-width: 520px) {
  .editable_button {
    top: calc(100px + 55px + 90px) !important;
    right: 35% !important;
    position: absolute;
  }
}

@media screen and (max-width: 590px) {
  .btn_send_this_link_to_applicant {
    position: relative;
    top: 38%;
    left: -60% !important;
  }
}

@media screen and (max-width: 520px) {
  .btn_send_this_link_to_applicant {
    position: relative;
    left: -20% !important;
  }
}

@media screen and (max-width: 1020px) {
  .formsitemap .application_link_button .btn_send_this_link_to_applicant {
    position: relative;
    top: 3px;
    left: -23%;
  }
}


@media screen and (max-width: 1800px) {
  .application_link_button {
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    top: 150px;
    right: 3%;
  }
}

@media screen and (max-width: 1650px) {
  .application_link_button {
    top: 150px;
    right: 2%;
  }
}

@media screen and (max-width: 1550px) {
  .application_link_button {
    top: 176px;
    right: 0;
  }
}

/* @media screen and (min-width: 1551px) {
  .application_link_button{
    top: 176px;
    right:0;
  }
} */

@media screen and (max-width: 1420px) {
  /* .application_link_button{
    top: 150px;
    right:-35px;
  } */
}

@media screen and (max-width: 1450px) {
  .treeview {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .btn_send_this_link_to_applicant {
    display: flex;
    flex-direction: column;
    /* position: absolute; */
    /* top: 39%;
    right: 30%; */

  }
}

/* @media screen and (max-width: 1050px) {
  .btn_send_this_link_to_applicant {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 38%;
    right: 30%;

   }
 }*/
/* @media screen and (min-width: 0px)  and (max-width: 500px) {  
  .btn_send_this_link_to_applicant {
    left:41%;
  }
}
 
 @media screen and (max-width: 1300px) {
  .btn_send_this_link_to_applicant {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 38%;
    right: 33%;

   }
 } */

@media screen and (min-width: 0px) and (max-width: 370px) {

  .application_link_button {
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    top: 198px;
    right: 33%;
    left: 33%;
    /* right: 100px;
    width: 250px; */
    /* left:40%; */
  }
}

@media screen and (min-width: 370px) and (max-width: 400px) {

  .application_link_button {
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    top: 168px;
    right: 33%;
    left: 33%;
    /* right: 100px;
    width: 250px; */
    /* left:40%; */
  }
}

@media screen and (min-width: 500px) and (max-width: 600px) {
  .application_link_button {
    left: 40%;
  }
}

@media screen and (min-width:600px) and (max-width: 700px) {
  .application_link_button {
    left: 49.5%;
  }
}

@media screen and (min-width:700px) and (max-width: 800px) {
  .application_link_button {
    left: 53%;
  }
}

@media screen and (min-width:801px) and (max-width: 925px) {
  .application_link_button {
    left: 35%;
  }
}

@media screen and (min-width:925px) and (max-width: 1020px) {
  .application_link_button {
    /* left:38%; */
    right: 32%;
  }
}

@media screen and (min-width:1020px) and (max-width: 1100px) {
  .application_link_button {
    left: 40%;
  }
}

@media screen and (min-width:1100px) and (max-width: 1200px) {
  .application_link_button {
    left: 46%;
  }
}

@media screen and (min-width:1200px) and (max-width: 1300px) {
  .application_link_button {
    left: 42%;
  }
}

@media screen and (min-width:1040px) {
  .application_form {
    margin-top: 80px !important;
  }
}

@media screen and (min-width:1301px) and (max-width:1449px) {
  .application_link_button {
    left: 39.5%;
  }

  .application_form {
    margin-top: 90px !important;
  }
}

@media screen and (min-width:1551px) and (max-width:1800px) {
  .application_link_button {
    margin-top: 25px;
  }

}
* {
    box-sizing: border-box;
}

.full_name {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.current_age,
.gender,
.employment_status,
.current_occupation,
.dependents {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;

}

.radio_group_shift_right {
    margin-left: 15px !important;
}

.btn_want {
    display: flex;
    justify-content: flex-end !important;
    font-size: small;
    padding: 0;
    margin-right: 10% !important;
    color: #074a6e !important;
    cursor: pointer;
}

.Financial_Information_Of_Owner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.left_part {
    width: 33%;
    display: flex;
    justify-content: flex-start;
}

.middle_part {
    width: 33%;
    display: flex;
    justify-content: flex-start;
}

.right_part {
    width: 33%;
    display: flex;
    justify-content: flex-start;
}

.transfer_exchange_request_page_root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.application_form {
    width: 50%;
    margin-top: 30px;
    margin-bottom: 10px;
    /* border:2px solid #074a6e; */
    border-radius: 5px;
    display: flex;
    justify-content: center;
    /* filter: drop-shadow(0px 0px 2px #074a6e); */
}

.Current_Insurance_Company {
    margin-top: -8px !important;
    font-size: 0.701rem;
    line-height: 1.7rem;
}

.plans_label {
    left: -14px !important;
}

.plans_transfer_request {
    margin-top: 5px;
}

.personal_information_main .gender .middle_part .input_field_width,
.personal_information_main .gender .right_part .input_field_width,
.personal_information_main .employment_status .middle_part .input_field_width,
.personal_information_main .employment_status .right_part .input_field_width {
    min-width: 80% !important;
    max-width: 80% !important;
}

.application_form_card {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.application_form_head {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    color: #074a6e;
    font-weight: 600;
    font-size: 20px;
    width: 100%;
}

.application_form_head_bottom_line {
    height: 20px;
    width: 80%;
    margin: 0 auto;
    margin-top: 10px;
}

.application_form_head_bottom_line hr {
    color: #074a6e;
    background-color: #074a6e;
}

.section_head_root {
    margin-top: 15px;
    display: flex;
    width: 80%;
    font-size: 15px;
    font-weight: 600;
    justify-content: space-between;
    align-items: center;
    color: #074a6e;
}

.annuitant_input_fields {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 80%;
}

.annuitant_fullname_and_ssn_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

::-webkit-input-placeholder {
    color: #B3B3B3;
    text-align: left;
}

::placeholder {
    color: #B3B3B3;
    text-align: left;
}

.existing ::-webkit-input-placeholder {
    font-size: 12px;
}

.existing ::placeholder {
    font-size: 12px;
}

.new_existing_account .MuiFormGroup-root .MuiInputBase-root ::-webkit-input-placeholder {
    font-size: 14px;
}

.new_existing_account .MuiFormGroup-root .MuiInputBase-root ::placeholder {
    font-size: 14px;
}

.annuitant_gender_selection {
    min-width: 200px;
    border: none;
}

.annuitant_dateofbirth_field {
    display: flex;
    justify-content: flex-start;
}

.annuitant_dateofbirth_field fieldset {
    /* min-width: 300px; */
    border: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid #B3B3B3 !important;
}

.annuitant_gender_and_dateofbirth_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_streetaddress_and_zip_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_city_and_state_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_phone_and_email_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_owner_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    flex-direction: column;
}

.left_section {
    width: 50%;
    display: flex;
    justify-content: flex-start;
}

.input_field_width {
    min-width: 95% !important;
}

.right_section {
    display: flex;
    justify-content: flex-end;
    width: 50%;
}

.plan_rider_section {
    width: 80%;
}

.plan_rider_checkboxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.not_required label::after {
    content: "" !important;
}

@media screen and (min-width:0px) and (max-width: 420px) {

    .Owner_Full_Name,
    .Owner_Tax_Id_or_SSN,
    .Street_Address,
    .Zip,
    .City,
    .State,
    .Annuitant_Insured,
    .Current_Insurance_Company,
    .Phone,
    .Account_Number {
        flex-direction: row !important;
        width: 100% !important;
    }

    .MuiInput-root {
        width: 100% !important;
    }

    .Current_Insurance_Company {
        margin-top: 2px !important;
    }

    .existing_annuity_status {
        margin-top: 25px !important;
    }

    .type_of_transfer_rollover {
        margin-top: 25px !important;
        margin-bottom: 25px !important;
    }

    .qualified_events_for_rollover_funds {
        margin-top: 15px !important;
        margin-bottom: 15px !important;
    }

    .non_qualified_or {
        margin: 2px 15px 0 -5px !important;
    }

    .previous_next_btn {
        flex-direction: column;
    }

    .btn_prev {
        margin: 15px 10px !important;
        width: 100%;

    }

    /* .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
        width: 100% !important;
    } */
    .btn_next {
        margin: 15px 10px !important;
        width: 250%;
        left: -80%;

    }

    /* .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
         width: 100% !important;
     } */
    .internal_exchange_1 {
        display: flex;
        align-items: center;
        width: 100%;
        flex-wrap: wrap;
    }

    .internal_exchange_2 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .btn_want {
        margin-right: 10% !important;
        margin-top: 6px !important;
    }
}

@media screen and (min-width:401px) and (max-width: 820px) {
    .application_form {
        margin-top: 20px !important;
    }

    .previous_next_btn {
        flex-direction: column;
    }

    .previous_next_btn .btn_next {
        margin-top: 20px !important;
    }

    .previous_next_btn .btn_prev {
        margin: 0px 20px !important;
    }

    .btn_want {
        margin-right: 10% !important;
        margin-top: 6px !important;
    }
}
* {
    box-sizing: border-box;
}

.full_name {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.current_age,
.gender,
.employment_status,
.current_occupation,
.dependents {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;

}

.radio_group_shift_right {
    margin-left: 15px !important;
}

.btn_want {
    display: flex;
    justify-content: flex-end !important;
    font-size: small;
    padding: 0;
    margin-right: 10% !important;
    color: #074a6e !important;
    cursor: pointer;
}

.Financial_Information_Of_Owner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.left_part {
    width: 33%;
    display: flex;
    justify-content: flex-start;
}

.middle_part {
    width: 33%;
    display: flex;
    justify-content: flex-start;
}

.right_part {
    width: 33%;
    display: flex;
    justify-content: flex-start;
}

.transfer_exchange_request_page_root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.application_form {
    width: 50%;
    margin-top: 30px;
    margin-bottom: 10px;
    /* border:2px solid #074a6e; */
    border-radius: 5px;
    display: flex;
    justify-content: center;
    /* filter: drop-shadow(0px 0px 2px #074a6e); */
}

.Current_Insurance_Company {
    margin-top: -8px !important;
    font-size: 0.701rem;
    line-height: 1.7rem;
}

.plans_label {
    left: -14px !important;
}

.plans_transfer_request {
    margin-top: 5px;
}

.personal_information_main .gender .middle_part .input_field_width,
.personal_information_main .gender .right_part .input_field_width,
.personal_information_main .employment_status .middle_part .input_field_width,
.personal_information_main .employment_status .right_part .input_field_width {
    min-width: 80% !important;
    max-width: 80% !important;
}

.application_form_card {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.application_form_head {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    color: #074a6e;
    font-weight: 600;
    font-size: 20px;
    width: 100%;
}

.application_form_head_bottom_line {
    height: 20px;
    width: 80%;
    margin: 0 auto;
    margin-top: 10px;
}

.application_form_head_bottom_line hr {
    color: #074a6e;
    background-color: #074a6e;
}

.section_head_root {
    margin-top: 15px;
    display: flex;
    width: 80%;
    font-size: 15px;
    font-weight: 600;
    justify-content: space-between;
    align-items: center;
    color: #074a6e;
}

.annuitant_input_fields {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 80%;
}

.annuitant_fullname_and_ssn_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

::-webkit-input-placeholder {
    color: #B3B3B3;
    text-align: left;
}

::placeholder {
    color: #B3B3B3;
    text-align: left;
}

.existing ::-webkit-input-placeholder {
    font-size: 12px;
}

.existing ::placeholder {
    font-size: 12px;
}

.new_existing_account .MuiFormGroup-root .MuiInputBase-root ::-webkit-input-placeholder {
    font-size: 14px;
}

.new_existing_account .MuiFormGroup-root .MuiInputBase-root ::placeholder {
    font-size: 14px;
}

.annuitant_gender_selection {
    min-width: 200px;
    border: none;
}

.annuitant_dateofbirth_field {
    display: flex;
    justify-content: flex-start;
}

.annuitant_dateofbirth_field fieldset {
    /* min-width: 300px; */
    border: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid #B3B3B3 !important;
}

.annuitant_gender_and_dateofbirth_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_streetaddress_and_zip_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_city_and_state_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_phone_and_email_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_owner_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    flex-direction: column;
}

.left_section {
    width: 50%;
    display: flex;
    justify-content: flex-start;
}

.input_field_width {
    min-width: 95% !important;
}

.right_section {
    display: flex;
    justify-content: flex-end;
    width: 50%;
}

.plan_rider_section {
    width: 80%;
}

.plan_rider_checkboxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.not_required label::after {
    content: "" !important;
}

@media screen and (min-width:0px) and (max-width: 420px) {

    .Owner_Full_Name,
    .Owner_Tax_Id_or_SSN,
    .Street_Address,
    .Zip,
    .City,
    .State,
    .Annuitant_Insured,
    .Current_Insurance_Company,
    .Phone,
    .Account_Number {
        flex-direction: row !important;
        width: 100% !important;
    }

    .MuiInput-root {
        width: 100% !important;
    }

    .Current_Insurance_Company {
        margin-top: 2px !important;
    }

    .existing_annuity_status {
        margin-top: 25px !important;
    }

    .type_of_transfer_rollover {
        margin-top: 25px !important;
        margin-bottom: 25px !important;
    }

    .qualified_events_for_rollover_funds {
        margin-top: 15px !important;
        margin-bottom: 15px !important;
    }

    .non_qualified_or {
        margin: 2px 15px 0 -5px !important;
    }

    .previous_next_btn {
        flex-direction: column;
    }

    .btn_prev {
        margin: 15px 10px !important;
        width: 100%;

    }

    /* .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
        width: 100% !important;
    } */
    .btn_next {
        margin: 15px 10px !important;
        width: 250%;
        left: -80%;

    }

    /* .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
         width: 100% !important;
     } */
    .internal_exchange_1 {
        display: flex;
        align-items: center;
        width: 100%;
        flex-wrap: wrap;
    }

    .internal_exchange_2 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .btn_want {
        margin-right: 10% !important;
        margin-top: 6px !important;
    }
}

@media screen and (min-width:401px) and (max-width: 820px) {
    .application_form {
        margin-top: 20px !important;
    }

    .previous_next_btn {
        flex-direction: column;
    }

    .previous_next_btn .btn_next {
        margin-top: 20px !important;
    }

    .previous_next_btn .btn_prev {
        margin: 0px 20px !important;
    }

    .btn_want {
        margin-right: 10% !important;
        margin-top: 6px !important;
    }
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    overflow-x: hidden !important;
}

.css-ypiqx9-MuiDialogContent-root,
.errorValidationTableContainer,
/* tableContainer */
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    overflow-x: hidden !important;
}

.validationPopupHeaderRow th {
    padding: 10px 16px !important;
    color: white !important;
    background-color: #D9A22E !important;
}

.validationPopupBodyRow td {
    padding: 6px 16px !important;
    background-color: #F1F1F1 !important;
}
.disclosure_form_radio_button {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 80%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuity_disclosure_desc {
    width: 80%;
    display: flex;
    flex-direction: column;
}

.annuity_disclosure_desc_1 {
    color: #606060;
}

.annuity_disclosure_desc_2_v2 {
    color: #606060;
    margin-top: 5px !important;
}

.radio_group_shift_right {
    margin-left: 15px;
}
.MuiModal-root .MuiDialog-container .MuiPaper-root{
    min-width: 300px;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100px;
}
.MuiModal-root .MuiDialog-container .MuiPaper-root p{
    font-size: 20px;
    margin: 20px;
}
.MuiModal-root .MuiDialog-container .MuiPaper-root button{
    margin-top: 10px;
    color: #fff;
    background-color: #04385d;
    margin-bottom: 20px;
}
@media screen and (max-width: 400px) {
    .MuiModal-root .MuiDialog-container .MuiPaper-root p{
        font-size: 17px;
        text-align: center;
    }
    .MuiModal-root .MuiDialog-container .MuiPaper-root{
        min-width: 350px;
    }
}
@media screen and (max-width: 360px) {
    .MuiModal-root .MuiDialog-container .MuiPaper-root{
        min-width: 300px;
    }
}
.noticeOfReplacementHead th {
    white-space: nowrap;
}

.notice_of_replace_date fieldset {
    border: none !important;
}

.notice_of_replace_date input {
    padding: 10px 14px !important;
    font-size: 15px !important;
}

.ma_textfield_one input {
    width: 175px !important;
}

.ma_textfield_two input {
    width: 130px !important;
}

.ma_textfield_three input {
    width: 300px !important;
}

.ma_textfield_four input {
    width: 140px !important;
}

.ma_textfield_five input {
    width: 88px !important;
}

.ma_textfield_six input {
    width: 80px !important;
}

.ma_textfield_five div {
    padding: 0px !important;
}

.ma_tbl {
    border-collapse: unset !important;
    border-spacing: 2px !important;
}

.mv_tbl_cell {
    border-bottom: none !important;
}

.notice_of_replace_date button {
    padding: 10px 0px 0px 0px !important;
}

.notice_of_replace_date svg {
    display: none !important;
}

.owner_replacement_not_required_amt input {
    padding: 10px 5px !important;
    font-size: 15px !important;
}


.important_notice_detail {
    width: 80%;
    display: flex;
    flex-direction: column;
}

.notice_detail_desc {
    color: #606060;
    margin-bottom: 15px !important;
}

.question_A_important_notice {
    color: #000;
    font-weight: 500;
}

.question_B_important_notice {
    color: #000;
    font-weight: 500;
}

.question_C_important_notice {
    color: #000;
    font-weight: 500;
}

.important_notice_question_c_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding-bottom: 10px;
}

.question_D_important_notice {
    color: #000;
    font-weight: 500;
    margin-top: 25px !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.question_d_input_field {
    margin-top: -22px !important;
    margin-left: 10px !important;
}

.broker_section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 80%;
}

.req_fields label::after {
    content: '*';
    color: red;
}

.radio_group_shift_right {
    margin-left: 15px !important;
}

@media (max-width:1580px) and (min-width:1301px) {
    .question_d_input_field {
        margin-top: 0px !important;
    }
}

@media (max-width:831px) {
    .question_d_input_field {
        margin-top: -2px !important;
    }
}
.verify_popup_title {
    margin-top: 20px !important;
    color: #D9A22E !important;
    font-weight: 600 !important;

}

.verify_popup_item {
    text-align: center;
    margin-bottom: 20px !important;
}

.verify_popup_phone_label {
    text-align: start;
    margin-bottom: 10px !important;
}

.verify_popup_phone_input {
    text-align: start;
    margin-bottom: 20px !important;
}

.verify_popup_phone_input button {
    background-color: white !important;
    margin-bottom: 10px !important;
}

.verify_popup_phone_img {}
.disclosure_form_radio_button {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 80%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuity_disclosure_desc {
    width: 80%;
    display: flex;
    flex-direction: column;
}

.annuity_disclosure_desc_1 {
    color: #606060;
}

.annuity_disclosure_desc_2 {
    color: #606060;
    margin-top: 10px !important;
}

.radio_group_shift_right {
    margin-left: 15px;
}
* {
    box-sizing: border-box;
}

.full_name {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.current_age,
.gender,
.employment_status,
.current_occupation,
.dependents {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;

}

.radio_group_shift_right {
    margin-left: 15px !important;
}

.btn_want {
    display: flex;
    justify-content: flex-end !important;
    font-size: small;
    padding: 0;
    margin-right: 10% !important;
    color: #074a6e !important;
    cursor: pointer;
}

.Financial_Information_Of_Owner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.left_part {
    width: 33%;
    display: flex;
    justify-content: flex-start;
}

.middle_part {
    width: 33%;
    display: flex;
    justify-content: flex-start;
}

.right_part {
    width: 33%;
    display: flex;
    justify-content: flex-start;
}

.producer_disclosure_page_root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.application_form {
    width: 50%;
    margin-top: 30px;
    margin-bottom: 10px;
    /* border:2px solid #074a6e; */
    border-radius: 5px;
    display: flex;
    justify-content: center;
    /* filter: drop-shadow(0px 0px 2px #074a6e); */
}

.Current_Insurance_Company {
    margin-top: -8px !important;
    font-size: 0.701rem;
    line-height: 1.7rem;
}

.plans_label {
    left: -14px !important;
}

.plans_transfer_request {
    margin-top: 5px;
}

.personal_information_main .gender .middle_part .input_field_width,
.personal_information_main .gender .right_part .input_field_width,
.personal_information_main .employment_status .middle_part .input_field_width,
.personal_information_main .employment_status .right_part .input_field_width {
    min-width: 80% !important;
    max-width: 80% !important;
}

.application_form_card {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.application_form_head {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    color: #074a6e;
    font-weight: 600;
    font-size: 20px;
    width: 100%;
}

.application_form_head_bottom_line {
    height: 20px;
    width: 80%;
    margin: 0 auto;
    margin-top: 10px;
}

.application_form_head_bottom_line hr {
    color: #074a6e;
    background-color: #074a6e;
}

.section_head_root {
    margin-top: 15px;
    display: flex;
    width: 80%;
    font-size: 15px;
    font-weight: 600;
    justify-content: space-between;
    align-items: center;
    color: #074a6e;
}

.annuitant_input_fields {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 80%;
}

.annuitant_fullname_and_ssn_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

::-webkit-input-placeholder {
    color: #B3B3B3;
    text-align: left;
}

::placeholder {
    color: #B3B3B3;
    text-align: left;
}

.existing ::-webkit-input-placeholder {
    font-size: 12px;
}

.existing ::placeholder {
    font-size: 12px;
}

.new_existing_account .MuiFormGroup-root .MuiInputBase-root ::-webkit-input-placeholder {
    font-size: 14px;
}

.new_existing_account .MuiFormGroup-root .MuiInputBase-root ::placeholder {
    font-size: 14px;
}

.annuitant_gender_selection {
    min-width: 200px;
    border: none;
}

.annuitant_dateofbirth_field {
    display: flex;
    justify-content: flex-start;
}

.annuitant_dateofbirth_field fieldset {
    /* min-width: 300px; */
    border: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid #B3B3B3 !important;
}

.annuitant_gender_and_dateofbirth_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_streetaddress_and_zip_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_city_and_state_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_phone_and_email_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_owner_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    flex-direction: column;
}

.left_section {
    width: 50%;
    display: flex;
    justify-content: flex-start;
}

.input_field_width {
    min-width: 95% !important;
}

.right_section {
    display: flex;
    justify-content: flex-end;
    width: 50%;
}

.plan_rider_section {
    width: 80%;
}

.plan_rider_checkboxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.not_required label::after {
    content: "" !important;
}

@media screen and (min-width:0px) and (max-width: 420px) {

    .Owner_Full_Name,
    .Owner_Tax_Id_or_SSN,
    .Street_Address,
    .Zip,
    .City,
    .State,
    .Annuitant_Insured,
    .Current_Insurance_Company,
    .Phone,
    .Account_Number {
        flex-direction: row !important;
        width: 100% !important;
    }

    .MuiInput-root {
        width: 100% !important;
    }

    .Current_Insurance_Company {
        margin-top: 2px !important;
    }

    .existing_annuity_status {
        margin-top: 25px !important;
    }

    .type_of_transfer_rollover {
        margin-top: 25px !important;
        margin-bottom: 25px !important;
    }

    .qualified_events_for_rollover_funds {
        margin-top: 15px !important;
        margin-bottom: 15px !important;
    }

    .non_qualified_or {
        margin: 2px 15px 0 -5px !important;
    }

    .previous_next_btn {
        flex-direction: column;
    }

    .btn_prev {
        margin: 15px 10px !important;
        width: 100%;

    }

    /* .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
        width: 100% !important;
    } */
    .btn_next {
        margin: 15px 10px !important;
        width: 250%;
        left: -80%;

    }

    /* .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
         width: 100% !important;
     } */
    .internal_exchange_1 {
        display: flex;
        align-items: center;
        width: 100%;
        flex-wrap: wrap;
    }

    .internal_exchange_2 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .btn_want {
        margin-right: 10% !important;
        margin-top: 6px !important;
    }
}

@media screen and (min-width:401px) and (max-width: 820px) {
    .application_form {
        margin-top: 20px !important;
    }

    .previous_next_btn {
        flex-direction: column;
    }

    .previous_next_btn .btn_next {
        margin-top: 20px !important;
    }

    .previous_next_btn .btn_prev {
        margin: 0px 20px !important;
    }

    .btn_want {
        margin-right: 10% !important;
        margin-top: 6px !important;
    }
}
*,
*::before,
*::after {
    font-family: "roboto", 'Open Sans', sans-serif !important;
}

/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&display=swap');

*,
*::before,
*::after,
*:before,
*:after,
html,
head,
body {
    font-family: "Open Sans", sans-serif !important;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
} */
.page_title{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 55px;
    background: #074a6e;
    color:#FFFFFF;
    font-weight: 500;
    font-size: 23px;
}
.page_title_Annuty{
    background: #D9A22E !important;
}
.resources_main_part{
    padding: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.resources_main_part .replacement_form_card{
    width: 170px;
    height: 190px;
    border-radius: 10px;
    border: 1px solid #D9A22E;
    ;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.resources_main_part .replacement_form_card a{
    width: 100%;
}
.resources_main_part .replacement_form_card .box_hover_section{
    display: none;
    opacity: 0;
}
.resources_main_part .replacement_form_card .replacement_form_card_image{
    height: 150px;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.resources_main_part .replacement_form_card .replacement_form_card_bottom{
    height: 40px;
    width: 100%;
    text-align: center;
    background: #D9A22E !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
}
.resources_main_part .replacement_form_card .replacement_form_card_bottom p{
    color: #fff;
    font-weight: 500;
}
.resources_main_part .replacement_form_card:hover .box_hover_section{
    opacity: 1;
    background: rgba(0,0,0, .8) !important;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    border-radius: 10px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 50%;
    left: 50%;
    z-index: 11;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

@media screen and (max-width: 420px){
    .resources_main_part{
        justify-content: center;
    }
}
.upload_tool_page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
}

.upload_tool_type {
    text-align: left;
    color: black !important;
    font-size: 15px !important;
    /* border-bottom: 3px solid #d9a22e !important; */
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    padding-left: 15px !important;
    font-weight: 700 !important;
}

.upload_tool_type_item :hover {
    cursor: pointer !important;
    /* border-left: 2px solid #dcb664 !important; */
}

.upload_tool_type {
    border-radius: 5px !important;
}

.upload_tool_type_container {
    max-height: 200px;
    border-radius: 10px;
    padding: 10px 0px;
}

.form_type_change_select fieldset {
    border: none !important;
}

.form_type_change_select {
    background-color: #fcf6eb !important;
}

.form_type_change_select .MuiSelect-outlined {
    padding: 10px 14px;
}

.upload_tool_left_border {
    border-left: 4px solid #d9a22e !important;
    background-color: #FCF6EB !important;
}

.upload_tool_underline {
    border-bottom: 3px solid #d9a22e !important;
}

.upload_nav_item {
    height: 50px;
}

.iFrameBox {
    width: 40% !important;
    /* border-radius: 18px 18px 0px 0px !important; */
    /* box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.15) !important; */
}

@media screen and (min-width:500px) and (max-width:1000px) {
    .iFrameBox {
        width: 70% !important;
    }
}

@media screen and (min-width:0px) and (max-width:499px) {
    .iFrameBox {
        width: 90% !important;
    }
}

/* .tab_1 {
    padding: 0px 40px 0px 40px !important;
}

.tab_2 {
    padding: 0px 40px 0px 40px !important;
}

.tab_2 {
    padding: 0px 40px 0px 40px !important;
} */
.img{
    width: 100%;
    margin-bottom: 20px;
}
.img_text1{
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 45%;
    left: 50%;
    color: white;
    position: absolute;
    font-size: 30px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}
.color{
    background-color: #F1F1F1 !important;
}
.third_box {
    display: flex;
    justify-content: flex-end;
    width: 97%;
}
.third_box button{
    min-width: 136px !important;
}
.img_text2{
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 55%;
    left: 50%;
    position: absolute;
    color: white;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}
.img_div{
    position: relative;
    text-align: center;
}
.main_container{
    height: -webkit-max-content;
    height: max-content;
    width: 60%;
    margin: 0 auto !important;
    box-shadow: 0px 0px 4px #D9A22E;
   
}
.first_div{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55px;
    color: white;
    font-size: larger;

    background-color: #D9A22E;
}
.home_page_footer{
    margin-top: 40px;
}
.first_part{
    display: flex;
    justify-content: space-around;
}
.first_box{
  /* padding: 32px;
    width: 60%; */
    /* height: 330px; */
    padding: 10px 3px 32px 3px;
    width: 50%;
}
.second_box{
    /* margin: 20px 45px 45px 45px;
    border: 2px solid #F1F1F1;
    background-color: #F1F1F1 ;
    width: 40%;
    height: 230px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center; */

        margin: 20px 5px 45px 5px;
        border: 2px solid #F1F1F1;
        background-color: #F1F1F1;
        width: 90%;
        height: 230px;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
}
.input1,.input2,.input3{
    padding: 5px;
    display: flex;
    justify-content: center;
    width: 100%;
}
.input4{
    padding-left: 17px;
}
.input1 .MuiFormControl-root,
.input2 .MuiFormControl-root,
.input3 .MuiFormControl-root{
    width: 85%;
}
.input2 .MuiFormControl-root .MuiAutocomplete-root .MuiFormControl-fullWidth
{
    width: 100% !important;
   color: black;
}

.img_text1 ,.img_text2{
    margin-bottom: 20px;
    display: inline-block ;
    color: white !important;
}
.btn_for_Calaculator{
    padding: 4px;
    margin: 5px;
    width: 80px;
    height: 30px;
    border-radius: 7px;
    background-color: #F1F1F1;
    border: 0px;
  
}
.period_text{
  margin-left: 30px;
  /* margin-top: 20px; */
  font-size: small;
}

.btn_div{
    margin-left: 20px;
    margin-top: 10px;
}
.grid_item1{
    background-color: rgb(217, 162, 46);
    margin-right: 4% !important;
}
.item{
    border-radius: 10px;
}
.grid_item2{
    background-color: rgb(217, 162, 46);
    justify-content: center;
    align-items: center;
    margin-right: 10px !important;
}
.itemtextcenter{
    text-align: center;
}
.grid_item3{
    background-color: rgb(217, 162, 46);
    justify-content: center;
    align-items: center;
}
.grid_item{
    background-color: rgb(241, 241, 241);
    justify-content: center;
    align-items: center;
    margin-right: 10px !important ;
}
button{
    cursor: pointer;
}
.chart{
    margin-top: 20px;
}
.main_container  .MuiDivider-fullWidth {
    width: 90% !important;
    border-color: rgb(217, 162, 46) !important;
    margin: 0 auto !important;
}
.chart canvas{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 70% !important;
    height: 10% !important;
    margin: auto ;
}

.con_main {
    margin: 20px 0 !important;
    padding-bottom: 20px;
    padding-right: 25px;
    /* max-height: 1400px;
    min-height: fit-content;
    height: 500px; */
    justify-content: space-evenly;
}
.box_height{
    text-align: center !important;
    padding: 7px !important;
    margin:auto ;
}
h2{
    margin: 0px !important;
}
.first {
    display: flex;
}
.first_box1 {
    /* width: 60%;
    padding: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column; */
    width: 52%;
    padding: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.first_box3{
    width: 52%;
    padding: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.input11 .MuiTextField-root{
    width: 85% !important;
    padding-left: 12px !important;
}
.period_text,.Client_age , .Client_name{
    /* margin-left: 5px !important; */
    color: grey;
}
.first_box2 {
    /* width: 40%;
    padding: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column; */
    width: 50%;
    padding: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.age_state{
    display: flex;
}
.first_box1 .input1 .MuiFormControl-root { 
    padding-left: 12px;  
}
.first_box2 .input1 .MuiFormControl-root { 
    /* padding-right: 23px;   */
    padding-right: 11px;
}
.Client_name {
    /* margin-left: 40px;
    font-size: small; */
    margin-left: 35px;
    font-size: small;
}
.Prepared_By{
    color: grey;
    font-size: small;
    margin-left: 25px;
}
.Client_age {
    /* margin-left: 15px; */
    /* width: 85%;
    margin: 0 auto;
    font-size: small; */
    margin-left: 40px;
    font-size: small;
}
.first_box4{
    width: 50%;
    padding: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.input11{
    width: 100%;
    margin: 0 auto;
    padding: 5px;
    display: flex;
    justify-content: center;
}
/* .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
    border-color: none !important;
} */
.savebtn{
    display: flex !important;
    background-color:  rgb(217, 162, 46) !important;
    justify-content: center !important;
    margin: 0 auto !important;
    margin-top: 20px !important;

}
.secBox :nth-child(2){
    justify-content: center;
    display: flex;
}
.btn_div1{
    display: flex;
    /* justify-content: end; */
    width: 60%;
    margin: 0 auto;
    margin-bottom: 20px;
}
.btn_div1 button{
    background-color: #D9A22E ;
    margin-right: 20px;
}
.download_btn{
    width: 10% !important;
    margin-top: 15px !important;
    background-color: #D9A22E !important;
}
.btn_btn{
    padding: 0 !important;
    border: none !important;
    margin-top: 45px !important;
}
@media screen and (min-width:1000px) and (max-width:1670px) {
    .main_container{
        width: 90%;
    }
    .btn_div1 {
        width: 90%;
    }
}

@media screen and (min-width:0px) and (max-width:614px) {
    .first {
        display: flex;
        flex-direction: column;
    }
    .first_box1,.first_box2 {
        width: 100%;
    }
    .btn_div1 {
       
        width: 90%;
      
    }
    .Client_age , .Client_name{
        margin-left: 5px !important;
        color: grey;
    }
    .first_box1 .input1 .MuiFormControl-root {
        padding-left: 0px;
    }
    .first_box2 .input1 .MuiFormControl-root {
        padding-right: 0px;
    }
    .main_container {
        width: 90%;
        margin-top: 30px !important;
    }
    .first_part {
        display: flex;
        flex-direction: column;
    }
    .first_box  {
        width: 100%;
        padding: 15px;
    }
    .second_box {
        width: 90%;
        margin: 0 auto;
        align-items: center;
        margin-bottom: 20px;
    }
    .chart{
        height: 200px !important;
    }
    .chart canvas{
        height: 210px !important;
        width: 90% !important;
    }
    /* .css-n2ywgu-MuiGrid-root, .css-1thszbi-MuiGrid-root{
        text-align: left !important;
        } */
    .img_div{
        display: none;
    }
.Prepared_By{
    margin-left: 0px
}
    .Client_name {
        /* margin-left: 5px; */
        display: flex;
    width: 85%;
    /* margin-left: 8% !important; */
    }
    .input11 .MuiTextField-root {
        width: 100% !important ; 
        padding-left: 0px !important;
    }
    .form_control{
        margin: 0px !important;
    }
    .input4 {
         padding-left: 0px; 
    }
    .period_text {
        margin-left: 5px;
        margin-top: 6px;
        font-size: small;
    }
    .btn_div{
        margin-left: 0px;
    }
    .con_main_box {
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
    }
    .first_box1 .input1  .MuiFormControl-root .MuiInputBase-root input {
        padding-left: 3px !important;
    }
    .con_main {
        width: 1000px !important;
    }
    .grid_item1 {
        /* background-color: rgb(217, 162, 46); */
        margin-right: 4% !important;
    }
    /* .con_main .MuiGrid-root .MuiGrid-root {
        margin: 5px !important;
    } */
    .rightfirstcomponent {
        width: 90% !important;
        padding: 10px 0px 0px 0px;
        margin: 0 auto;
    }
    .rightbox {
        width: 100% !important;
        margin: 0 auto;
    }
    .age_state {
        display: block;
    }
    .first_box3 ,.first_box4{
        width: 100%;
    }

}
.btn_div1 button:hover {
    background-color: #D9A22E;
}
.rightbox{
    width: 40%;
}
.rider_header{
    color: grey;
    font-size: smaller;
}
.rightfirstcomponent {
    width: 100%;
    padding: 10px 0px 0px 0px;
}
.check_box {
    display: flex;
    width: 107%;
}
.fm1, .fm2{
    width: 57%;
}

@media screen and (min-width:421px) and (max-width:614px) {
    .input1 .MuiFormControl-root, .input2 .MuiFormControl-root, .input3 .MuiFormControl-root {
        width: 100% !important;
    }
    .btn_div1 {
       
        width: 90%;
      
    }
}

@media screen and (min-width:615px) and (max-width:999px) {
    .con_main_box {
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
    }
    .con_main {
        width: 1000px !important;
    }
    .btn_div1 {
       
        width: 90%;
      
    }
    .main_container{
        width: 90%;
    }
}
.secBox{
    text-align: center;
}
@media screen and (min-width: 1201px) and (max-width: 1600px){

.main_container_history, .btn_div1 {
    width: 90% !important;
}
.btn_div1 {
       
    width: 90%;
  
}
}
.errCalculator{
    width: 85%;
    margin: 7px auto;
    display: flex;
    justify-content: start !important;
}
/* .css-wgai2y-MuiFormLabel-asterisk{
    display: none;
} */
.itemtextcenter div{
    min-height: 25px;
}
.main_container_history {
    height: -webkit-max-content;
    height: max-content;
    width: 60%;
    margin: 0 auto !important;
    box-shadow: 0px 0px 4px #D9A22E;

}

.Grid_main {
    margin: 20px auto !important;
    padding-bottom: 20px;
    width: 95% !important;
    /* padding-right: 25px; */
    justify-content: space-evenly;
}

.item1 {
    border-radius: 10px;
}

.itemtextcenter11 {
    color: white !important;
}

.item1 .MuiGrid-container {
    align-items: center;
    height: 35px !important;
    /* padding: 7px; */
    justify-content: space-between !important;

}

.itemtextcenter1 {
    /* text-align: center; */
    text-align: left !important;
    width: 70px !important;
}

.head {
    background-color: #D9A22E !important;
}

.calc_history_tbl {
    border-collapse: separate !important;
    border-spacing: 0px 8px !important;
    padding: 0px 15px 0px 15px;
}

.calc_history_tbl_header th {
    background-color: #D9A22E;
    color: white;
    padding: 13px 10px 13px 17px;
    white-space: nowrap;
    font-size: 16px;
}

.header_radius_left {
    border-radius: 10px 0px 0px 10px;
}

.header_radius_right {
    border-radius: 0px 10px 10px 0px;
}

.calc_history_tbl_row {
    background-color: #f1f1f1;
}

.calc_history_tbl_row td {
    padding: 10px 10px 10px 16px;
    font-size: 16px;
    /* white-space: nowrap; */
}

.grid_item {
    background-color: rgb(241, 241, 241);
    justify-content: center;
    align-items: center;

}

.view_btn {
    width: 100% !important;
    background-color: #D9A22E !important;
    color: white !important;
    padding: 2px !important;
    position: static !important;
}

.grid_item1 {
    background-color: #D9A22E !important;

}

.box_height,
.box_height_Cal_history {
    text-align: center !important;
    padding: 7px !important;
    margin-bottom: 10px !important;
    align-items: center;
    display: flex;

}

.btn_div1_history button {
    background-color: #D9A22E !important;
    margin-left: 10px;
}

.btn_div1_history {
    width: 60%;
    margin: 10px auto;
    justify-content: flex-end;
    display: flex;

}

/* .css-1loofyr-MuiGrid-root{
position: unset !important;
} */
@media screen and (min-width: 0px) and (max-width: 900px) {

    .Grid_box {
        margin-top: 20px !important;
        overflow-x: scroll !important;
        /* overflow-y: hidden !important; */
        overflow-y: auto;
        /* white-space: nowrap !important; */
    }

    .main_container_history,
    .btn_div1_history {
        width: 90%;
    }

    /* .grid_item .MuiGrid-container {
    width: 900px !important;
}  */
    .grid_item {
        width: 1500px;

    }
}

@media screen and (min-width:900px) and (max-width:1200px) {

    .main_container_history,
    .btn_div1_history {
        width: 90%;
    }
}

@media screen and (min-width:1201px) and (max-width:1600px) {

    .main_container_history,
    .btn_div1_history {
        width: 75%;
    }
}

@media screen and (min-width: 0px) and (max-width: 425px) {
    .btn_div1_history {
        display: flex;
        flex-direction: column !important;
    }

    .btn_div1_history button {
        margin: 0 auto 10px auto;
    }
}

.box_height_Cal_history {
    text-align: center !important;
    padding: 7px !important;
    margin-bottom: 10px !important;
    align-items: center;
    display: flex;
}

@media screen and (min-width:0px) and (max-width:900px) {
    .head .MuiGrid-container {
        padding: 7px;
    }
}

@media screen and (min-width:0px) and (max-width:900px) {
    .Grid_main_Pdf_config {
        margin: 20px auto !important;
        padding-bottom: 20px;
        justify-content: space-evenly;
        overflow-y: scroll;
        width: 95% !important;
        overflow-y: hidden;
    }
}
.initialAccountTableCell {
    border-bottom: 2px solid white !important;
    padding: 5px 0px 5px 5px !important;
}

.initialAccountTableCell2 {
    border-bottom: 2px solid white !important;
    padding: 5px 0px 5px 10px !important;
}

.insuranceProducerTableCell {
    border-bottom: 2px solid white !important;
}

.initialAccountTextField fieldset {
    border: none !important;
}

.initialAccountTextField input {
    padding: 3px 0px !important;
    text-align: center;
}

.radio_group_shift_right_fixed {
    margin-left: 40px !important;
}

.beneficiaries_1 {
    width: 1% !important;
}

.beneficiaries_2 {
    width: 20% !important;
}

.beneficiaries_3 {
    width: 10% !important;
}

.beneficiaries_4 {
    width: 1% !important;
}

.beneficiaries_5 {
    width: 20% !important;
}

.beneficiaries_6 {
    width: 1% !important;
}
.treeview {
    right: -10px;
    width: 335px;
    padding: 15px 20px 30px 5px;
    font-size: 15px;
}

.border {
    border-left: rgb(189, 189, 189);
}

.main_component {
    padding-right: 40px;
}

.editable_button:hover {
    color: #fff !important;
    background: #074A6E !important;

}

.spn {
    color: rgb(189, 189, 189);
}

.right_section_title {
    font-size: 18px;
}

.right_section_title:hover {
    cursor: pointer;
}

.application_form_section_name {
    padding: 2px;
}

.treeview ul {
    float: left;
    width: 100%;
    position: relative;
    border-left: 8px solid rgb(189, 189, 189);
    height: 50px;
    border-radius: 3px;
    padding: 10px 10px 5px 15px;

}

.application_link_button {
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    top: 180px;
    right: 5%;
}

.middle_line {
    font-weight: 600;
    vertical-align: super;
}

.individual_annuity_application_right_section {
    margin-top: 60px;
    padding-top: 5px;
}

.suitability_analysis_right_section_fixed {
    margin-top: 495px;
}

.fia_notice_of_replacement_right_section {
    margin-top: 493px;
}

.fia_transfer_exchange_right_section {
    margin-top: 1005px;
}

.individual_annuity_application_right_section li:last-of-type {
    height: 27px;
}

.fia_notice_of_replacement_right_section li:last-of-type {
    height: 27px;
}

.suitability_analysis_right_section_fixed li:last-of-type {
    height: 27px;
}

.suitability_analysis_right_section_fixed li:first-of-type {
    height: 43px;
}

.fia_suitability_analysis_right_section_exception li:first-of-type {
    height: 43px;
}

.fia_transfer_exchange_right_section li:last-of-type {
    height: 27px;
}

.transfer_exchange_right_section_exception_fixed {
    margin-top: 885px;
}

.transfer_exchange_right_section_exception_fixed li:last-of-type {
    height: 27px;
}

.fia_suitability_analysis_right_section_exception {
    margin-top: 615px;
}

.fia_suitability_analysis_right_section_exception li:last-of-type {
    height: 27px;
}

/* .current_page{
    color: #1976d2 !important;
  } */
li {
    float: left;
    width: 100%;
    border-left: 2px solid rgb(189, 189, 189);
    padding: 10px 0;
    list-style-type: none;
}
.fia_producer_disclosure_right_section {
    margin-top: 1130px;
  }

  .fia_producer_disclosure_right_section li:last-of-type {
    height: 27px;
  }
  .fia_producer_disclosure_right_section li:first-of-type {
    height: 43px;
  }
  .fia_producer_disclosure_right_section_exception li:first-of-type {
    height: 43px;
  }
  .fia_producer_disclosure_right_section_exception {
    margin-top: 1240px;
  }
  .fia_producer_disclosure_right_section_exception li:last-of-type {
    height: 27px;
  }
@media screen and (max-width: 1450px) {
    .formsitemap .application_link_button .btn_send_this_link_to_applicant {
        position: relative;
        top: 3px;
        left: -24%;
    }
}

@media screen and (max-width: 1300px) {
    .formsitemap .application_link_button .btn_send_this_link_to_applicant {
        position: relative;
        top: 3px;
        left: -68%;
    }

    .editable_button {
        right: 3%;
    }
}

@media screen and (max-width: 1200px) {
    .formsitemap .application_link_button .btn_send_this_link_to_applicant {
        position: relative;
        top: 3px;
        left: -80%;
    }
}

@media screen and (max-width: 1100px) {
    .formsitemap .application_link_button .btn_send_this_link_to_applicant {
        position: relative;
        top: 3px;
        left: -63%;
    }
}

@media screen and (max-width: 800px) {
    .btn_send_this_link_to_applicant {
        position: relative;
        top: 38%;
        left: -80% !important;
    }
}

@media screen and (max-width: 1300px) {
    .editable_button {
        right: 3% !important;
        position: absolute !important;
    }
}


@media screen and (max-width: 520px) {
    .editable_button {
        top: calc(100px + 55px + 90px) !important;
        right: 35% !important;
        position: absolute;
    }
}

@media screen and (max-width: 590px) {
    .btn_send_this_link_to_applicant {
        position: relative;
        top: 38%;
        left: -60% !important;
    }
}

@media screen and (max-width: 520px) {
    .btn_send_this_link_to_applicant {
        position: relative;
        left: -20% !important;
    }
}

@media screen and (max-width: 1020px) {
    .formsitemap .application_link_button .btn_send_this_link_to_applicant {
        position: relative;
        top: 3px;
        left: -23%;
    }
}


@media screen and (max-width: 1800px) {
    .application_link_button {
        display: flex;
        flex-direction: column;
        position: absolute;
        justify-content: center;
        top: 150px;
        right: 3%;
    }
}

@media screen and (max-width: 1650px) {
    .application_link_button {
        top: 150px;
        right: 2%;
    }
}

@media screen and (max-width: 1550px) {
    .application_link_button {
        top: 176px;
        right: 0;
    }
}

/* @media screen and (min-width: 1551px) {
    .application_link_button{
      top: 176px;
      right:0;
    }
  } */

@media screen and (max-width: 1420px) {
    /* .application_link_button{
      top: 150px;
      right:-35px;
    } */
}

@media screen and (max-width: 1450px) {
    .treeview {
        display: none;
    }
}

@media screen and (max-width: 450px) {
    .btn_send_this_link_to_applicant {
        display: flex;
        flex-direction: column;
        /* position: absolute; */
        /* top: 39%;
      right: 30%; */

    }
}

/* @media screen and (max-width: 1050px) {
    .btn_send_this_link_to_applicant {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 38%;
      right: 30%;
  
     }
   }*/
/* @media screen and (min-width: 0px)  and (max-width: 500px) {  
    .btn_send_this_link_to_applicant {
      left:41%;
    }
  }
   
   @media screen and (max-width: 1300px) {
    .btn_send_this_link_to_applicant {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 38%;
      right: 33%;
  
     }
   } */

@media screen and (min-width: 0px) and (max-width: 370px) {

    .application_link_button {
        display: flex;
        flex-direction: column;
        position: absolute;
        justify-content: center;
        top: 198px;
        right: 33%;
        left: 33%;
        /* right: 100px;
      width: 250px; */
        /* left:40%; */
    }
}

@media screen and (min-width: 370px) and (max-width: 400px) {

    .application_link_button {
        display: flex;
        flex-direction: column;
        position: absolute;
        justify-content: center;
        top: 168px;
        right: 33%;
        left: 33%;
        /* right: 100px;
      width: 250px; */
        /* left:40%; */
    }
}

@media screen and (min-width: 500px) and (max-width: 600px) {
    .application_link_button {
        left: 40%;
    }
}

@media screen and (min-width:600px) and (max-width: 700px) {
    .application_link_button {
        left: 49.5%;
    }
}

@media screen and (min-width:700px) and (max-width: 800px) {
    .application_link_button {
        left: 53%;
    }
}

@media screen and (min-width:801px) and (max-width: 925px) {
    .application_link_button {
        left: 35%;
    }
}

@media screen and (min-width:925px) and (max-width: 1020px) {
    .application_link_button {
        /* left:38%; */
        right: 32%;
    }
}

@media screen and (min-width:1020px) and (max-width: 1100px) {
    .application_link_button {
        left: 40%;
    }
}

@media screen and (min-width:1100px) and (max-width: 1200px) {
    .application_link_button {
        left: 46%;
    }
}

@media screen and (min-width:1200px) and (max-width: 1300px) {
    .application_link_button {
        left: 42%;
    }
}

@media screen and (min-width:1040px) {
    .application_form {
        margin-top: 80px !important;
    }
}

@media screen and (min-width:1301px) and (max-width:1449px) {
    .application_link_button {
        left: 39.5%;
    }

    .application_form {
        margin-top: 90px !important;
    }
}

@media screen and (min-width:1551px) and (max-width:1800px) {
    .application_link_button {
        margin-top: 25px;
    }

}
.disclosure_form_radio_button {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 80%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuity_disclosure_desc {
    width: 80%;
    display: flex;
    flex-direction: column;
}

.annuity_disclosure_desc_1 {
    color: #606060;
}

.annuity_disclosure_desc_2 {
    color: #606060;
    margin-top: 5px !important;
}

.radio_group_shift_right {
    margin-left: 15px;
}
* {
    box-sizing: border-box;
}

.annuitySearchBarTextField input {
    padding: 10px 18px !important;
}

.annuitySearchBarTextField {
    background-color: rgb(241, 241, 241);
}

.annuitySearchBarTextField fieldset {
    border: none;
}

.annuity_application_table_root {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;
    margin: 0 auto;
}

.page_title_annuity_application {
    background: #D9A22E !important;
}

.annuity_application_table {
    /* margin-top: 20px; */
    /* border:1px solid black; */
}

.annuity_application_table {
    white-space: nowrap;
}

.annuity_application_table thead {
    margin-bottom: 10px;
}

.annuity_application_table thead tr {
    border-radius: 50px !important;
}

.annuity_application_table {
    /* margin-top: 20px; */
    border-collapse: separate !important;
    border-spacing: 0 15px !important;
}

.annuity_application_table_head th:nth-of-type(1) {
    border-radius: 8px 0 0 8px;
}

.annuity_application_table_head th:last-of-type {
    border-radius: 0 8px 8px 0;
}

.annuity_application_table_head th {
    background-color: #D9A22E !important;
    color: #fff;
    /* font-weight: 600; */
    font-size: 16px;
    padding: 10px;
    /* border-bottom: 10px solid #fff; */
}

.annuity_application_table_head th:nth-of-type(1) {
    width: 30%;
}

.annuity_application_table_head th:nth-of-type(2) {
    width: 20%;
}

.annuity_application_table_head th:nth-of-type(3) {
    width: 20%;
}

.annuity_application_table_head th:nth-of-type(4) {
    width: 15%;
}

.annuity_application_table_head th:nth-of-type(5) {
    width: 15%;
}


.annuity_application_view_button button {
    background-color: #D9A22E !important;
    color: #fff;
    text-transform: capitalize;
    border-radius: 8px;
    padding: 2px 12px 2px 12px;
    margin-right: 10px;
}

.annuity_application_view_button button:disabled {
    background-color: #074a6e;
    color: #fff;
    opacity: 0.5;
}

.annuity_application_view_button button:hover {
    background-color: #074a6e;
    color: #fff;
    text-transform: capitalize;
    border-radius: 8px;
    padding: 2px 12px 2px 12px;
}

.annuity_application_table_data {
    background-color: #f1f1f1;
    margin-top: 10px;
    border-radius: 50px;
}

.annuity_application_table_data td {
    padding: 5px 10px;
    font-size: 15px;
    /* border-bottom: 10px solid #fff; */
}

.annuity_application_table_data td:nth-of-type(1) {
    border-radius: 8px 0 0 8px;
}

.annuity_application_table_data td:last-of-type {
    border-radius: 0 8px 8px 0;
}


@media only screen and (max-width: 1150px) {
    .annuity_application_table_root {
        width: 80%;
    }
}

@media only screen and (max-width: 930px) {
    .annuity_application_table_root {
        width: 90%;
    }
}

@media only screen and (max-width:800px) {
    .annuity_application_table {
        min-width: 730px;
    }
}
.StateName, .YearName{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    font-weight: 600
}
.MuiModal-root .MuiDialog-container .MuiPaper-root button{
    background-color: #D9A22E;
}
.grid_height{
    margin: 5px !important;
}
.delete{
    cursor: pointer;
}
.con_main_box_first_box {
    width: 90%;
    margin: 0 auto;
}
.popup_box{
    width: 500px;
    height: auto;
}

@media screen and (min-width:0px) and  (max-width:420px){
    .YearNameDiv{
        height: auto;
    }  
    .popup_box{
    width: 71vw;
    }
.main_box{
    display: flex;
    justify-content: center;
}
.first_name{
    width: 98%;

}
.state_grid{
    width: 96% !important;
    padding: 0 !important;
}
.con_main_box_first_box{
    /* max-width: 1070px;
    min-width: 820px; */
    width: 900px;
    margin: 10px 30px !important;
}
.period_main_grid{
    width: 98%;
}
.period_main_grid .MuiBox-root{
    padding: 20px !important;
}
.con_main_box_first_grid{
    /* width: 1220px !important;
    display: flex !important; */
    margin-left: 4%;
    justify-content: flex-start !important;
    /* margin: 10px 20px !important; */
}
.state_box{
    padding: 50px 10px !important;
}
}
@media screen and (min-width:421px) and  (max-width:820px){  
    .con_main_box_first_box{
       /* width: fit-content; */
       width: 900px;
        margin: 10px 30px !important;
    }
    .YearNameDiv{
        height: auto;
    }
    .con_main_box_first_grid{
        width: 1220px !important;
        display: flex !important;
        justify-content: flex-start !important;
        margin: 10px 20px !important;
    }
    .state_grid {
        width: 100%;
    }
    .state_box{
        padding: 20px !important;
    }
    .period_main_grid{
        width: 96%;
    }
}
.YearNameDiv{
    height: 400px;
}

@media screen and (min-width:821px) and  (max-width:900px){  
    .con_main_box_first_box{
        /* max-width: 90px;
        min-width: 800px; */
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: 10px 30px !important;
    }
    .YearNameDiv{
        height: auto;
    }
    .con_main_box_first_grid{
        width: 1520px !important;
        display: flex !important;
        justify-content: flex-start !important;
        margin: 10px 20px !important;
    }
    .state_box{
        padding: 50px 40px !important;
    }
    .period_main_grid{
        width: 96%;
    }
}
.btn_div1_admin{
    display: flex;
  
    width: 60%;
    margin: 10px auto;
    margin-bottom: 20px;

}
.btn_div1_admin button , .btn_div1_admin button:hover{
    background-color: #D9A22E;
}

.errAdmin, .msgAdmin{
    display: flex;
    justify-content: left;
    flex-direction: row;
    min-height: 50px;
    margin-bottom: 10px;
}
.state {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}
@media screen and (min-width:421px) and (max-width:630px){
    .popup_box{
        width: 70vw ;  
    }
} 

.Grid_box_Pdf_config {
    height: 71vh;
    overflow: auto;
}

.page_title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 55px;

    color: #FFFFFF;
    font-weight: 500;
    font-size: 23px;
}

.main_container_pdf_history {
    height: auto;
    width: 60%;
    margin: 0 auto !important;
    box-shadow: 0px 0px 4px #D9A22E;
}

.btns {
    margin: 20px 12px;
}

.Grid_main_Pdf_config p {
    margin: 0;
}

.btn_New_Pdf,
.btn1,
.btn2,
.btn3,
.btn4 {
    background-color: #D9A22E !important;
}

.btn_New_Pdf,
.btn1 {
    /* width:120px !important;
    padding: 18px !important; */
    margin-right: 10px !important;
}

/* .btn3{
    margin-left: 10px !important;
} */



.resourceTableHeaderRow {
    background-color: #D9A22E !important;
}

.resourceTableHeaderRow th {
    background-color: #D9A22E !important;
    color: #FFFFFF !important;
    padding: 12px 16px 12px 16px !important;
}

/* .resourceTableHeaderRow th {
    border-radius: 10px solid red;
} */

.resourceTableHeaderRow th:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.resourceTableHeaderRow th:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.resourceTableRow td:first-child {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
}

.resourceTableRow td:last-child {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.resourceTableRow {
    background-color: #f1f1f1 !important;
}

.resourceTableRow td {
    border-bottom: none !important;
}

.resourceTableRow td {
    padding: 10px 5px !important;
}

.resourceTable {
    border-collapse: separate !important;
    border-spacing: 0 15px !important;
}


.file-inputs input {
    height: 150px !important;
    width: 300px !important;
}

.file-inputs {
    background-color: aliceblue !important;
    margin: 0 !important;
}

.file-inputs span {

    color: #D9A22E !important;
}

.box_height {
    text-align: left !important;
}

.autocomplete div div div button {
    BACKGROUND: aliceblue !important;
    margin-top: -3px !important;
    color: black !important;
}

.btn-search-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* margin: 3%; */
}

.searchbar {
    background-color: aliceblue;
    margin: 20px 17px 10px 20px;
    height: 38px;
}

.edit_pdf_popup1 .MuiPaper-root {
    width: 600px;
    /* height: 400px !important; */
}

.edit_pdf_popup2 {
    width: 90% !important;
}

#filled-hidden-label-small {
    padding-top: 12px !important;
}

.MuiInputAdornment-root .MuiSvgIcon-root {
    margin-top: -10px !important;
}

.btn2,
.btn3 {
    margin-right: 10px !important;
}

@media screen and (min-width:0px) and (max-width:420px) {
    .btns {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 0 auto;
    }

    .main_container_pdf_history {
        width: 95% !important;
    }

    .btn-search-div {
        display: flex;
        justify-content: center;
        flex-direction: column-reverse;
    }

    .btn_New_Pdf,
    .btn1 {
        margin: 10px auto;
        width: 45%;
        max-height: 40px;

    }

    .searchbar .MuiFormControl-root {
        width: 100%;
    }

    .btn2,
    .btn3,
    .btn4 {
        width: 50%;
        max-height: 40px;
        margin: 10px auto;
    }

    .searchbar {
        margin: 5% 3%;
    }

}

@media screen and (min-width:421px) and (max-width:618px) {
    .btns {
        display: flex;
        justify-content: center;
        /* flex-direction: column; */
        margin: 0 auto;
    }

    .main_container_pdf_history {
        width: 95% !important;
    }

    .btn-search-div {
        display: flex;
        justify-content: center;
        flex-direction: column-reverse;
    }

    .btn_New_Pdf,
    .btn1 {
        margin: 10px auto;
        width: 45%;
        max-height: 40px;

    }

    .searchbar .MuiFormControl-root {
        width: 100%;
    }

    .btn2,
    .btn3 {
        width: 50%;
        max-height: 40px;
        margin: 10px auto;
    }

    .searchbar {
        margin: 5% 3%;
    }

}

@media screen and (min-width:618px) and (max-width:820px) {
    .btn3 {
        margin-top: 10px !important;
    }

    ;

    .btns {
        display: flex;
        justify-content: center;

    }

    ;

    .searchbar {
        width: -webkit-fit-content !important;
        width: -moz-fit-content !important;
        width: fit-content !important;
        margin: 20px auto 0px !important;
    }

    ;

    .btn-search-div {
        display: flex;
        justify-content: center;
        flex-direction: column-reverse;
    }

    ;
}

@media screen and (min-width:821px) and (max-width:867px) {
    .btn3 {
        margin-top: 10px !important;
    }
}

@media screen and (min-width:1200px) and (max-width:1301px) {
    .btn3 {
        margin-top: 10px !important;
    }
}

@media screen and (min-width:421px) and (max-width:1200px) {

    /* .btn1 {
        margin:  10px auto !important;
        width: 100% !important;
    } */
    .main_container_pdf_history {
        width: 90%;
    }

}

@media screen and (min-width:1200px) and (max-width:1587px) {

    .btn4 {
        margin-top: 10px !important;
        /* width: 100% !important; */
    }

}

@media screen and (min-width:627px) and (max-width:1058px) {

    .btn4 {
        margin-top: 10px !important;
        /* width: 100% !important; */
    }

}

.MuiAutocomplete-endAdornment .MuiAutocomplete-clearIndicator {
    display: none !important;
}

.variablebox,
.linktext {
    width: 100%;
}

.url {
    margin-right: 0px !important;
}

.pdficon_main {
    display: flex;
    justify-content: space-between;
    width: 100% !important;
}

.file_upload .MuiGrid-container {
    width: 100%;
}

.file_upload {
    font-size: small;
    display: flex;
    align-items: center;
    text-align: left;
    color: black;
    width: 100%;
}

.pdf_icon {
    /* width: 40px !important;
    height: 40px !important; */
    margin-left: -30px !important;
}

.pdf_icon2 img {
    margin-left: -20px !important;
}

.file_name {
    display: flex;
    align-items: center;
    /* margin-left: -100px !important; */
}

.delete_icon {
    display: flex;
    align-items: center;
}

.pdficon img {
    width: 40px !important;
    height: 40px !important;
    margin-left: -4px !important;
}

.category_edit .MuiInputBase-root {
    background: rgba(0, 0, 0, 0.1) !important;
}

.MuiInputLabel-root::after {
    content: "*";
    color: red;
}

.Grid_box {
    margin-top: 10px;
    height: 76.5vh;
    overflow-y: auto;
}

.head {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    color: white
}

.errmsg,
.err {
    display: flex !important;
    justify-content: start !important;
    flex-direction: initial !important;
    min-height: 20px !important;
    /* max-width: 450px !important; */
    margin: 0 auto;
    /* max-width: fit-content; */
}

@media screen and (min-width:0px) and (max-width:420px) {
    .file_name {
        text-align: center;
        margin: 0 15px;
    }

    .file_upload .MuiGrid-container {
        width: 100%
    }

    .file_name_txt {
        margin-left: 10px;
    }

    .btns {
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
        width: 100%;
        margin: auto;
    }

    /* .grid_item .MuiGrid-container {
        max-width: 600px;
    } */
    .Grid_main_Pdf_config>.MuiGrid-item {
        min-width: 700px !important;
        max-width: 1000px !important;
    }

    .Grid_box {
        height: auto;
    }

}

@media screen and (min-width:421px) and (max-width:600px) {
    .file_name {
        text-align: center;
        margin: 0;
    }

    .file_upload .MuiGrid-container {
        width: 100%
    }

    .Grid_main_Pdf_config>.MuiGrid-item {
        min-width: 700px !important;
        max-width: 1000px !important;
    }

    .Grid_box {
        height: auto;
    }
}

.file_name_txt,
.file_upload {


    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -webkit-hyphens: auto;
    hyphens: auto;


}

.editErr {
    width: 100% !important;
    display: flex;
    justify-content: start;
    margin: 10px auto;
    min-width: none !important;
    min-height: 10px !important;
}

.errPdfConfig {
    display: flex !important;
    justify-content: start !important;
    flex-direction: row !important;
    width: 100% !important;
    min-width: none !important;
    min-height: 10px !important;
}

/* .css-1xaekgw{
    margin: 10px ;
} */
.Grid_main_Pdf_config {
    max-width: 100% !important;
}

@media screen and (min-width:900px) {
    .Grid_box_Pdf_config {
        margin: 10px 10px 0px 10px;
    }
}

.MuiPaper-elevation8 {
    /* left: 51% !important; */
    min-width: 400px !important
}

.MuiPaper-elevation8 ul {
    max-width: 200px !important;
}
.file-inputs {
    /* padding: 1em; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px dashed #cbd5e0;
    background-color: #edf2f7;
    /* min-width: 486px; */
    min-height: 65px;
}

.file-inputs {
    position: relative;
    width: 100%;
}

.file-inputs input {
    position: relative;
    text-align: right;
    opacity: 0;
    z-index: 2;
    cursor: pointer;
    /* height: 46px; */
    padding: 0px;
    border: 0px;
    /* max-width: 200px; */
}

.file-inputs button {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    /* background-color: #f55e30; */
    font-size: 1.1rem;
    cursor: pointer;
    border-radius: 4px;
    padding: 0px;
    border: none;
    outline: none;
    transition: background-color 0.4s;
    box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.5);
}

.file-inputs button i {
    width: 1.5em;
    height: 1.5em;
    padding: 0.4em;
    background-color: #fff;
    /* color: #f55e30; */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.8em;
    font-size: 0.8em;
}

.file-inputs:hover button {
    /* background-color: #f15120; */
}

.main {
    font-weight: bold;
    font-size: 16px;
}

.info {
    font-size: 0.8rem;
}

.file-card {
    width: 100%;
}

.delete_container {
    display: flex;
    width: 100%;
    /* justify-content: space-between; */
}

.delete_icon {
    display: flex;
    justify-content: flex-end;
}

.file_item {
    margin: 3px;
}

.file_name {
    font-size: 15px;
}

.delete_onclick {
    cursor: pointer;
}

.file_size {
    font-size: 13px;
    color: #A6A6A6;
}
.errCategory {
    display: flex;
    justify-content: flex-start !important;
    flex-direction: row !important;
    min-width: none !important;
    min-height: 10px !important;
    width: 70% !important;
    margin: 0 auto;

}

.box_height {
    max-width: 100% !important;
}

@media screen and (min-width: 0px) and (max-width: 400px) {
    .btns_category {
        margin: 10px auto;
        width: 95%;
    }

    /* .css-1n0qnrx-MuiFormControl-root-MuiTextField-root
{
    width: 70% !important;
} */

}

@media screen and (min-width: 0px) and (max-width: 420px) {
    .edit_pdf_popup2 h4 {
        min-width: auto !important;
    }
}

.btns_category {
    margin: 10px auto;
    width: 95%;
}

/* .css-ypiqx9-MuiDialogContent-root{
    overflow-y: none !important
} */
/* .css-ypiqx9-MuiDialogContent-root{
    overflow-y: unset !important;
    padding: 20px !important;
} */
.edit_pdf_popup2_group {
    width: 90% !important;
}
.autocomplete_group{
    width: 70%;
    margin: 0 auto;
}
.autocomplete_group div div div button{
    margin-top: 0px !important;
    background-color: rgb(241, 241, 241) !important;
}
.btn_group {
    margin: 10px 30px;
}

.hint{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.hint img{
    width: 60px;
    margin: 0 auto;
}
.hint_span{
    width: 70%;
    margin: 0 auto;
    text-align: justify;
    color: red;
}
.span_all_data{
    width: 55%;
    margin: 0 auto;
    text-align: justify;
    color: red;
}
.Grid_main_group{
    margin: 20px auto !important;
    padding-bottom: 20px;
    width: 95% !important;
    /* padding-right: 25px; */
    
    justify-content: space-evenly;
}
.btn1_group{
    margin-right: 10px !important;
}
.btn2_group, .btn1_group {
    margin: 10px auto;
    /* width: 45%; */
   
    max-height: 40px;
    background-color:#D9A22E !important ;
}
.edit_pdf_popup2_group{
    min-width: 400px;
}
@media screen and (min-width: 421px) and (max-width: 618px){

    .btn2_group, .btn1_group {
        margin: 10px auto;
        width: 45%;
        max-height: 40px;
        background-color:#D9A22E !important ;
    }
    .MuiGrid-spacing-xs-2 .MuiDialogActions-root{
        overflow: hidden;
        }
        .edit_pdf_popup2_group{
            min-width: 0px !important;
        }
        .MuiGrid-spacing-xs-2 .MuiDialogActions-root{
            overflow: hidden;
            display:flex;
            flex-direction: column;
            }
}
@media screen and (min-width:0px) and (max-width:420px) {
    
    .Grid_main_group {
        min-width: 700px;
        max-width: 1000px;
    }
    .Grid_box_group{
        width: 95%;
        overflow-x: scroll;
        margin: 0px 0px 0px 10px;
    }
    .btn2_group, .btn1_group {
        margin: 10px auto;
        width: 45%;
        max-height: 40px;
        background-color:#D9A22E !important ;
    }
    .MuiGrid-spacing-xs-2 .MuiDialogActions-root{
        overflow: hidden;
        display:flex;
        flex-direction: column;
        }
}

@media screen and (min-width:421px) and (max-width:820px) {
    
    .Grid_main_group {
        min-width: 700px;
        max-width: 1000px;
    }
    .Grid_box_group{
        width: 95%;
        overflow-x: scroll;
        margin: 0px 0px 0px 10px;
    }
}
.first_box_view{
    margin: 20px auto;
    height: 210px;
    width: 50%;
}
.second_box_view { 
    height: 130px !important;
    margin: 20px 5px 15px 5px !important;
}
.first_view{
    width: 21%;
}
.second{
    width: 2%;
}
.name, .age,.period,.state,.rider,.amount{
    padding: 5px;
}
.btn_div1_view button{
    background: #D9A22E;
}

.btn_div1_view button:hover{
    background: #D9A22E;
}
.btn_div1_view {
    display: flex;
    width: 90%;
    margin: 14px auto;
    
}
.note{
        color: red;
        text-align: justify;
        width: 90%;
        margin-bottom: 10px;

}
.state_view{
    position: unset;
}
.main_container_view .MuiDivider-fullWidth {
    margin: 10px auto !important;
}
@media screen and (min-width:0px) and (max-width:420px) {
    .first_box_view{
        margin: 10px auto;
        height: auto;
        width: 90%;
    }
    .first_view {
        width: 37%;
    }
    .third_rider{
        max-width: 50%;
    }
   
    .btn_div1_view {
        display: flex;
      
        width: 90%;
        margin: 14px auto;
        
    }
    .btn_div1_view button{
        background: #D9A22E;
    }
    .note{
      margin: 0 auto;
      margin-bottom: 10px;
}

}


@media screen and (min-width:421px) and (max-width:615px) {
    .first_box_view{
        margin: 10px auto;
        height: auto;
        width: 90%;
    }
    .first_view {
        width: 30% !important;
    }
    .third{
        width: 70% !important;
    }
    .note{   
        margin: 0 auto;
        margin-bottom: 10px;
  }
  .second_box_view { 
    height: none !important;
    margin: 0 !important;
}
   
}
.first_view {
    width: 30% !important;
}
.third{
    width: 70% !important;
}
@media screen and (min-width:616px) and (max-width:820px) {
    .first_box_view{
        width: 37%;
        margin: 20px auto;
    height: auto;
    }
   
    .rightbox {
        width: 55%;
    }
  
    .first_part{
        display: flex;
    justify-content: space-evenly;
    }
    .second_box_view{
        margin: 20px 5px 15px 5px
    }
   
}
@media screen and (min-width:821px)  {
    .first_box_view{
       height: 210px !important; 
    }   
   
}
@media screen and (min-width:1671px)  {
    .btn_div1_view {
        width: 60%;
    }
   
}
.App-link {
    color: #61dafb;
}

.App-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 1.5vmin);
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.characters {
    list-style: none;
    padding-left: 0;
}

.characters li {
    display: flex;
    align-items: center;
    border: solid 2px #d0d0d0;
    border-radius: .2em;
    padding: .5em .8em .5em .5em;
    margin-bottom: 1em;
}

.characters p {
    max-width: none;
    font-weight: bold;
    margin: 0;
}

.characters-thumb {
    overflow: hidden;
    flex-shrink: 0;
    width: 2em;
    height: 2em;
    background-color: #e8e8e8;
    padding: .5em;
    margin-right: .5em;
}

.characters-thumb img {
    display: block;
    width: 100%;
    height: auto;
}




















/* .list_item_resource {
    padding-top: 0;
}

.application_box_root_resource {
    border-radius: 10px;
    background-color: #074A6E !important;
    color: #fff !important;
    margin-bottom: 10px !important;
    height: max-content !important;
}

.application_box_root_resource .list_item {
    padding-bottom: 0;
}

.application_box_root_resource a {
    text-decoration: none;
    color: #fff;
}

.application_box_root_resource a:hover {
    text-decoration: none;
    color: #D9A22E !important;
}


.application_box_root_resource {

    margin-left: 20px !important;
    height: max-content !important;
    WIDTH: 440px !important;
    margin-right: 10px !important;

}

.application_box_root_resource {
    max-width: 350px !important;
    min-width: 350px !important;
}


.resource_table {
    border-collapse: separate;
    border-spacing: 0 15px;
    width: 100%;
}

.resource_name {
    margin-bottom: 15px !important;
} */
.move_option .MuiPaper-elevation8 {
    min-width: auto !important;
}
* {
    box-sizing: border-box;

}

.ownerReplacementContainer {
    margin-top: 10px;
}

.radio_group_shift_right {
    margin-left: 15px;
}

.replacementTableHead {
    background-color: #074A6E !important;
    border-radius: 5px !important;
}

.replacementTableHead th {
    color: white !important;
    padding: 10px 10px 10px 14px;
}

.replacementTableRow {
    background-color: #F1F1F1 !important;
}

.replacementTableContainer {
    border-radius: 5px 5px 5px 5px !important;
    margin-top: 18px;
}

.replacementTableCell {
    border-bottom: 5px solid white !important;
    padding: 0px !important;
}

.replacementTableCellDisableColor {
    color: rgb(189, 189, 189) !important;
}

.annuitant_gender_and_dateofbirth_row .annuitant_dateofbirth_field input {
    width: 100%;
    color: black;

}

.dateCloseIcon {
    -webkit-transform: translateX(-30px);
            transform: translateX(-30px);
    z-index: 1;
}

.annuitant_gender_and_dateofbirth_row .annuitant_dateofbirth_field {
    width: 47.3%;
}

.annuitant_gender_and_dateofbirth_row .annuitant_dateofbirth_field .MuiFormControl-root {
    width: 100%;
}

.annuitant_input_fields .annuitant_gender_and_dateofbirth_row .annuitant_dateofbirth_field label,
.annuitant_owner_section .annuitant_gender_and_dateofbirth_row .annuitant_dateofbirth_field label {
    color: rgba(0, 0, 0, 0.6);
    bottom: 20px !important;
    left: -16px !important;
}

.dobColorClass label {
    color: rgba(0, 0, 0, 0.38) !important;
}

.annuitant_owner_section .annuitant_gender_and_dateofbirth_row .annuitant_gender_selection .input_field_width .MuiInput-root {
    width: 100% !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    min-width: 400px;
}

.application_page_root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

/* @media screen and (max-width: 520px) {
    .application_page_root{margin-top: 100px !important;}
} */

.application_form {
    width: 50%;
    margin-top: 30px;
    margin-bottom: 10px;
    /* border: 2px solid #074a6e; */
    border-radius: 5px;
    display: flex;
    justify-content: center;
    /* filter: drop-shadow(0px 0px 2px #074a6e); */
}


.application_form_card {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.application_form_head {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    color: #074a6e;
    font-weight: 600;
    font-size: 20px;
    width: 100%;
}

.application_form_head_bottom_line {
    height: 20px;
    width: 80%;
    margin: 0 auto;
    margin-top: 10px;
}

.application_form_head_bottom_line hr {
    color: #074a6e;
    background-color: #074a6e;
}

.annuitant_head_root {
    margin-top: 15px;
    display: flex;
    width: 80%;
    font-size: 15px;
    font-weight: 600;
    justify-content: space-between;
    align-items: center;
    color: #074a6e;
}

#head_root {
    width: 100%;
    margin-top: 15px;
    display: flex;
    font-size: 15px;
    font-weight: 600;
    justify-content: space-between;
    align-items: center;
    color: #074a6e;
    /* margin-bottom: 20px; */
}

.annuitant_input_fields {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 80%;
}

.annuitant_fullname_and_ssn_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_gender_and_dateofbirth_row,
.annuitant_streetaddress_and_zip_row,
.annuitant_city_and_state_row,
.annuitant_phone_and_email_row,
.annuitant_fullname_and_ssn_row {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

/* .annuitant_dateofbirth_field{
    height: 0em;
} */
::-webkit-input-placeholder {
    color: #B3B3B3;
    text-align: left;
}
::placeholder {
    color: #B3B3B3;
    text-align: left;
}

.annuitant_gender_selection {
    min-width: 215px !important;
    border: none;
    width: 50% !important;
}

.annuitant_dateofbirth_field {
    display: flex;
    justify-content: flex-start;

}

.annuitant_gender_selection_gender1 {
    width: 25% !important;
}

.annuitant_gender_selection_gender2 {
    width: 95%;
}

.annuitant_gender_selection_gender3 {
    width: 100% !important;
}

.annuitant_dateofbirth_field fieldset {
    /* min-width: 300px; */
    border: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid #B3B3B3 !important;
}

.annuitant_gender_and_dateofbirth_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_streetaddress_and_zip_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_city_and_state_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

/* .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper{
    max-width: 360px !important;
} */
.annuitant_phone_and_email_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_owner_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    flex-direction: column;
}

.left_section {
    width: 50%;
    display: flex;
    justify-content: flex-start;
}

.annuitant_gender_selection .annuitant_dateofbirth_field {
    width: 100%;
}

.input_field_width {
    min-width: 95% !important;

}

.date_field {
    width: 100%;
    border: 2px solid red;

}

/* .annuitant_head_root{
    margin-bottom: 10px;
} */

.right_section {
    display: flex;
    justify-content: flex-end;
    width: 50%;
}

.nor_states_select {
    display: flex;
    justify-content: flex-end;
    width: 55%;
    /* margin-left: 10px; */
}

/* .owner_replacement_first_question{
    font-family: sans-serif;
} */

.plan_rider_section {
    width: 80%;
    margin-top: 10px;
    margin-bottom: -10px;
}

.rider_section {
    margin-top: 10px;
}

.beneficiaries_section1 {
    margin-bottom: 10px;
}

.plan_rider_checkboxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: -15px !important;
}

.beneficiaries_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    flex-direction: column;
    margin-top: 10px !important;

}

.annuitant_dateofbirth_field label {
    margin-top: 15px;
}

.annuitant_dateofbirth_field input {
    padding-left: 0;
    padding-right: 14px;
    padding-bottom: 6px;
    margin-top: 10px;
}

.annuitant_dateofbirth_field {
    margin-bottom: 8px;
}

.annuitant_dateofbirth_field button {
    padding-bottom: 0px;
    padding-top: 21px;

}

.beneficiaries_section_fields {
    width: 100% !important;
    padding-top: 15px;
}

.beneficiaries_section_row_1,
.beneficiaries_section_row_2,
.beneficiaries_section_row_3 {
    padding-right: 10px;
}

.beneficiaries_section_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding-bottom: 10px;
}

.annuitant_streetaddress_and_zip_row,
.annuitant_gender_and_dateofbirth_row,
.annuitant_fullname_and_ssn_row,
.annuitant_city_and_state_row,
.annuitant_phone_and_email_row,
.policy_company_number_array_fields_row,
.policy_company_number_array_fields_row_2 {
    padding-top: 10px;
}

.isContractsHasBeenInvolved {
    width: 80%;
}

.req_fields label::after {
    content: '*';
    color: red;
}

.owner_replacement_not_required input {
    padding: 10px 14px !important;
    font-size: 15px !important;
}

/* .not_required input {
    padding: 10px 14px !important;
    font-size: 15px !important;
} */

.not_required fieldset {
    border: none !important;
}

.not_required label::after {
    content: '';
}

.ptqs_qualified_options>div>label,
.ptqs_non_qualified_options>div>label {
    margin-right: 50px !important;
}

.ptqs_non_qualified_options,
.ptqs_qualified_options {
    padding: 0 !important;
}

.annuitant_head_root:hover {
    cursor: pointer !important;
}

.section_open_close:hover {
    cursor: pointer;
}


.owner_replacement_question_margin_top {
    margin-top: 15px;
}

/* .policy_company_number_array_fields {
    margin-top: -10px;
} */
@media only screen and (min-width: 821px) and (max-width: 3000px) {
    .application_form {
        border: 2px solid #074a6e;
        margin-top: 70px !important;
    }
}

@media only screen and (min-width: 1000px) and (max-width: 3000px) {
    .dateCloseIcon {
        display: none !important;
    }
}

@media only screen and (min-width: 821px) and (max-width: 1050px) {
    .application_form {
        width: 95% !important;
        margin-top: 80px !important;

    }

    .owner_replacement_question_main {
        margin-top: 15px;
        margin-bottom: -10px;
    }

    .new_application_form .btn_send_this_link_to_applicant {
        display: flex;
        justify-content: center;
        margin-top: 5px;
    }

    .application_form .application_form_card .application_form_head {
        margin-top: 30px;
    }

    .application_form .application_form_card .application_form_head .application_form_head_bottom_line {
        width: 90%;
    }

    .application_form .application_form_card .annuitant_input_fields,
    .application_form .application_form_card .annuitant_owner_section,
    .application_form .application_form_card .plan_rider_section,
    .application_form .application_form_card .beneficiaries_section,
    .application_form .application_form_card .premium_and_tax_qualification_status_main {
        width: 88%;
    }

    .application_form .application_form_card .owner_replacement_question,
    .application_form .application_form_card .annuitant_head_root {
        width: 88% !important;

    }

    .application_form .application_form_card .annuitant_head_root {
        width: 88%;

    }

    .application_form .application_form_card .application_form_head .application_form_head_1 {
        font-size: revert;
    }

    .application_form .application_form_card .annuitant_input_fields .annuitant_gender_and_dateofbirth_row .annuitant_dateofbirth_field .input_field_width {
        width: 500px;
    }

    .application_form .application_form_card .safeguard_plus_width {
        width: 88% !important;
    }
}


@media only screen and (min-width:1050px) and (max-width: 1300px) {
    .application_form {
        border: 0 !important;
        margin-top: 80px;
    }
}

@media screen and (min-width:401px) and (max-width: 820px) {
    .application_form {
        margin-top: 70px !important;
    }

    .application_form .application_form_card .annuitant_owner_section_bottom {
        margin-bottom: 10px !important;
    }

    .not_required input {
        font-size: 14px !important;
    }
}

@media screen and (min-width:350px) and (max-width:820px) {
    .ptqs_qualified_box {
        width: 95% !important;
    }
}


@media only screen and (min-width:0px) and (max-width: 420px) {
    .application_form {
        width: 95% !important;
        margin-top: 30px !important;
    }

    .not_required input {
        font-size: 13px !important;
    }

    /* .application_link_button{
            left: 42.5% !important;
        } */
    .beneficiaries_section_row {
        padding-bottom: 0px !important;
    }

    .application_form .application_form_card .annuitant_input_fields .annuitant_gender_and_dateofbirth_row .annuitant_dateofbirth_field {
        width: 95%;
        margin-bottom: 0;
    }

    .application_form .application_form_card .header_1 {
        margin-top: 0px !important;
    }

    .application_form .application_form_card .annuitant_owner_section .annuitant_gender_and_dateofbirth_row .annuitant_dateofbirth_field .input_field_width {
        width: 90%;
    }

    .application_form .application_form_card .premium_and_tax_qualification_status_main .amount_to_paid_with_application {
        margin: 15px 0px !important;
    }

    .annuitant_streetaddress_and_zip_row .right_section,
    .annuitant_gender_and_dateofbirth_row .right_section,
    .annuitant_fullname_and_ssn_row .right_section,
    .annuitant_city_and_state_row .right_section,
    .annuitant_phone_and_email_row .right_section,
    .beneficiaries_section_row .right_section,
    .policy_company_number_array_fields_row .right_section,
    .policy_company_number_array_fields_row_2 .right_section {
        padding-top: 10px;
    }

    .application_form .application_form_card .owner_replacement_question_main {
        margin-top: 15px;
    }

    /* .application_form .application_form_card   .owner_replacement_question .policy_company_number_array_fields{
    margin-top: -20px;
} */
    .application_form .application_form_card .annuitant_input_fields .annuitant_gender_and_dateofbirth_row .annuitant_dateofbirth_field .input_field_width {
        width: 100%;
    }

    .application_form .application_form_card .annuitant_input_fields {
        width: 95%;
    }

    .application_form .application_form_card .annuitant_input_fields .annuitant_fullname_and_ssn_row,
    .application_form .application_form_card .annuitant_input_fields .annuitant_gender_and_dateofbirth_row,
    .application_form .application_form_card .annuitant_input_fields .annuitant_streetaddress_and_zip_row,
    .application_form .application_form_card .annuitant_input_fields .annuitant_city_and_state_row,
    .application_form .application_form_card .annuitant_input_fields .annuitant_phone_and_email_row {
        width: 100%;
        justify-content: center;
        flex-direction: column;
        margin-top: 5px;
        margin-bottom: -5px;
    }

    .application_form .application_form_card .application_form_head {
        margin-top: 20px;
    }

    .application_form .application_form_card .annuitant_owner_section {
        width: 100%;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .application_form .application_form_card .annuitant_head_root {
        margin-top: 20px;
        width: 90%;
        font-size: 14px;
    }

    .application_form .application_form_card .beneficiaries_section .beneficiaries_section_fields .beneficiaries_section_row .beneficiaries_section_row_1,
    .application_form .application_form_card .beneficiaries_section .beneficiaries_section_fields .beneficiaries_section_row .beneficiaries_section_row_2,
    .application_form .application_form_card .beneficiaries_section .beneficiaries_section_fields .beneficiaries_section_row .beneficiaries_section_row_3,
    .application_form .application_form_card .beneficiaries_section .beneficiaries_section_fields .beneficiaries_section_row .annuitant_gender_selection {
        width: 100% !important;
        margin-top: 10px !important;

    }

    .application_form .application_form_card .policy_company_number_array_fields .policy_company_number_array_fields_row {
        width: 100%;
        margin-top: 0px !important;

    }

    .application_form .application_form_card .safeguard_plus_qnnuity_wth_guarantee_period {
        width: 90% !important;
        margin-top: 15px !important;
    }

    /* .owner_replacement_question{
       margin-bottom: -27px !important;
   }   */

    .application_form .application_form_card .annuitant_input_fields .annuitant_gender_and_dateofbirth_row.annuitant_dateofbirth_field .input_field_width {
        width: 97% !important;
    }


    /* .policy_company_number_array_fields_row_1 .css-1u3bzj6-MuiFormControl-root-MuiTextField-root ,
    .application_form .application_form_card  .policy_company_number_array_fields  .policy_company_number_array_fields_row  
    .policy_company_number_array_fields_row_2 .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
        width: 100% !important;
     }
        .application_form .application_form_card  .beneficiaries_section  .beneficiaries_section_fields .beneficiaries_section_row 
         .annuitant_gender_selection .css-1nrlq1o-MuiFormControl-root .css-1ft4ntv-MuiInputBase-root-MuiInput-root{
             width: 100%;
    } */
    .new_application_form .btn_send_this_link_to_applicant {
        display: flex;
        justify-content: center;
        margin-top: -5px;
    }

    .application_form .application_form_card .beneficiaries_section,
    .application_form .application_form_card .plan_rider_section,
    .application_form .application_form_card .premium_and_tax_qualification_status_main,
    .application_form .application_form_card .owner_replacement_question {
        width: 90% !important;
        margin-top: 10px;
    }

    .application_form .application_form_card .owner_replacement_question {
        margin-top: 5px !important;
    }

    .application_form .application_form_card .premium_and_tax_qualification_status_main .ptqs_row .MuiFormGroup-root .ptqs_qualified_box {
        margin-top: 10px;
    }

    .application_form .application_form_card .annuitant_input_fields .annuitant_gender_and_dateofbirth_row .annuitant_dateofbirth_field .input_field_width label {
        left: -11px !important;
        bottom: 20px;
    }

    .application_form .application_form_card .annuitant_input_fields .annuitant_fullname_and_ssn_row .right_section,
    .application_form .application_form_card .annuitant_input_fields .annuitant_fullname_and_ssn_row .left_section,
    .application_form .application_form_card .annuitant_input_fields .annuitant_gender_and_dateofbirth_row .left_section,
    .application_form .application_form_card .annuitant_input_fields .annuitant_gender_and_dateofbirth_row .right_section,
    .application_form .application_form_card .annuitant_input_fields .annuitant_streetaddress_and_zip_row .left_section,
    .application_form .application_form_card .annuitant_input_fields .annuitant_streetaddress_and_zip_row .right_section,
    .application_form .application_form_card .annuitant_input_fields .annuitant_city_and_state_row .left_section,
    .application_form .application_form_card .annuitant_input_fields .annuitant_city_and_state_row .right_section,
    .application_form .application_form_card .annuitant_input_fields .annuitant_phone_and_email_row .left_section,
    .application_form .application_form_card .annuitant_input_fields .annuitant_phone_and_email_row .right_section {
        justify-content: center;
        width: 95% !important;
    }

    .application_form .application_form_card .annuitant_owner_section .annuitant_fullname_and_ssn_row,
    .application_form .application_form_card .annuitant_owner_section .annuitant_gender_and_dateofbirth_row,

    .application_form .application_form_card .annuitant_owner_section .annuitant_city_and_state_row,
    .application_form .application_form_card .annuitant_owner_section .annuitant_phone_and_email_row {
        width: 95%;
        justify-content: center;
        flex-direction: column;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
        /* margin-top: -5px;
    margin-bottom: -5px; */
    }

    .application_form .application_form_card .annuitant_owner_section .annuitant_streetaddress_and_zip_row {
        width: 95%;
        justify-content: center;
        flex-direction: column;
        margin-top: -8px !important;
        margin-bottom: 0px !important;
    }

    .application_form .application_form_card .annuitant_owner_section .annuitant_fullname_and_ssn_row .right_section,
    .application_form .application_form_card .annuitant_owner_section .annuitant_fullname_and_ssn_row .left_section,
    .application_form .application_form_card .annuitant_owner_section .annuitant_gender_and_dateofbirth_row .right_section,
    .application_form .application_form_card .annuitant_owner_section .annuitant_streetaddress_and_zip_row .left_section,
    .application_form .application_form_card .annuitant_owner_section .annuitant_streetaddress_and_zip_row .right_section,
    .application_form .application_form_card .annuitant_owner_section .annuitant_city_and_state_row .left_section,
    .application_form .application_form_card .annuitant_owner_section .annuitant_city_and_state_row .right_section,
    .application_form .application_form_card .annuitant_owner_section .annuitant_phone_and_email_row .left_section,
    .application_form .application_form_card .annuitant_owner_section .annuitant_phone_and_email_row .right_section {
        justify-content: center;
        width: 95%;
    }

    .application_form .application_form_card .annuitant_owner_section .annuitant_gender_and_dateofbirth_row .left_section {
        justify-content: center;
        padding-left: 1px !important;
        width: 95% !important;
    }

    .application_form .application_form_card .annuitant_owner_section .annuitant_gender_and_dateofbirth_row .left_section .input_field_width {
        width: 100%;
    }

    .application_form .application_form_card .annuitant_owner_section .annuitant_gender_and_dateofbirth_row .right_section {
        justify-content: center;
        width: 95% !important;
        padding-top: 0px;
    }

    .application_form .application_form_card .beneficiaries_section .beneficiaries_section_fields .beneficiaries_section_row {
        width: 100%;
        justify-content: center;
        flex-direction: column;
    }

    .policy_company_number_array_fields_row {
        margin-bottom: 10px;
    }

    .policy_company_number_array_fields_row_1 label {
        font-size: 0.901rem;
    }

    /* .application_form .application_form_card  .beneficiaries_section  .beneficiaries_section_fields .beneficiaries_section_row .MuiBox-root .css-wh8j8b-MuiFormControl-root-MuiTextField-root,
    .application_form .application_form_card  .beneficiaries_section  .beneficiaries_section_fields .beneficiaries_section_row .MuiBox-root .css-1iezzy6-MuiFormControl-root-MuiTextField-root ,
    .application_form .application_form_card  .beneficiaries_section  .beneficiaries_section_fields .beneficiaries_section_row .MuiBox-root .css-xmbdxf-MuiFormControl-root-MuiTextField-root ,
    .application_form .application_form_card  .beneficiaries_section  .beneficiaries_section_fields .beneficiaries_section_row .annuitant_gender_selection .input_field_width 
    {
        width: 100% ;
    }  */

    /* .application_form .application_form_card  .beneficiaries_section  .beneficiaries_section_fields .owner_replacement_question 
    .policy_company_number_array_fields .policy_company_number_array_fields_row .css-1u3bzj6-MuiFormControl-root-MuiTextField-root 
    {
        width: 100%;
    } */
    /* 
    .application_form {
        margin-top: 15px;
    }
    .annuitant_head_root {
        margin-top: 0px;
        width: 90%;
        font-size: 13px;
    }
    
    .annuitant_gender_selection.left_section{
        width: 95%;
        margin-left: 20px;
    }
    .MuiTypography-root {
        margin: 0;
        width: 100%;
    }

    
    

    .css-wh8j8b-MuiFormControl-root-MuiTextField-root{
        width: 100%;
    }
    #head_root {
        font-size: 13px;
        padding-bottom: 10px;
    }
    element.style .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root 
    {
        margin: 0px 5px;
        width: 100%;
    }
     */

}

/* 
@media screen and (min-width:1050px) and (max-width: 1300px)
{
    .application_form {
        width: 95% !important;
        margin-top: 20px !important;
        
    }
   
} */
@media only screen and (min-width: 1050px) and (max-width: 1300px) {
    .application_form {
        width: 95% !important;
        margin-top: 100px !important;
        border: 2px solid #074a6e !important;
    }

    .application_form_head {
        margin-top: 30px;
    }
}

@media only screen and (min-width: 401px) and (max-width: 820px) {
    .application_form {
        width: 85% !important;
        margin-top: 30px !important;

    }

    .application_form_head {
        margin-top: 20px;
    }

    .application_form .application_form_card .premium_and_tax_qualification_status_main .ptqs_row .MuiFormGroup-root .ptqs_qualified_box {
        margin-top: 15px !important;
    }
}

@media only screen and (min-width: 421px) and (max-width: 820px) {
    .application_form .application_form_card .beneficiaries_section .beneficiaries_section_fields .beneficiaries_section_row {
        width: 100%;
        justify-content: center;
        flex-direction: column;
    }

    .application_form .application_form_card .beneficiaries_section .beneficiaries_section_fields .beneficiaries_section_row .beneficiaries_section_row_1,
    .application_form .application_form_card .beneficiaries_section .beneficiaries_section_fields .beneficiaries_section_row .beneficiaries_section_row_2,
    .application_form .application_form_card .beneficiaries_section .beneficiaries_section_fields .beneficiaries_section_row .beneficiaries_section_row_3,
    .application_form .application_form_card .beneficiaries_section .beneficiaries_section_fields .beneficiaries_section_row .annuitant_gender_selection {
        width: 100% !important;
        margin-top: 10px !important;

    }

    .beneficiaries_field_width,
    .annuitant_gender_selection_gender2 {
        width: 100% !important;
    }
}

@media screen and (min-width:1300px) and (max-width:1613px) {
    .ptqs_qualified_box {
        margin-top: 10px;
    }
}
.previous_next_btn {
    display: flex;
    justify-content: space-between;
    padding-left: 120px;
    padding-right: 120px;
}


* {
    box-sizing: border-box;
}

.radio_group_shift_right {
    margin-left: 15px !important;
}

.suitability_full_name {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.current_age,
.suitability_full_name,
.gender,
.employment_status,
.current_occupation,
.dependents {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.Financial_Information_Of_Owner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.suitability_left_part {
    width: 33%;
    padding-top: 17px;
    display: flex;
    justify-content: flex-start !important;
}

.middle_part {
    width: 33%;
    display: flex;
    justify-content: flex-start;
}

.suitability_middle_part {
    width: 33%;
    display: flex;
    justify-content: flex-start;
}

.suitability_right_part {
    width: 33%;
    display: flex;
    justify-content: flex-start;
}

.suitability_analysis_page_root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.financial_situation_and_need_of_owner {
    margin-bottom: -20px;
}

.application_form {
    width: 50%;
    margin-top: 30px;
    margin-bottom: 10px;
    /* border:2px solid #074a6e; */
    border-radius: 5px;
    display: flex;
    justify-content: center;
    /* filter: drop-shadow(0px 0px 2px #074a6e); */
}

.first_question {
    margin-bottom: -5px !important;
}


.personal_information_main .gender .suitability_middle_part .suitability_middle_part_input_field_width,
.personal_information_main .gender .suitability_right_part .suitability_right_part_input_field_width,
.personal_information_main .employment_status .suitability_middle_part .suitability_middle_part_input_field_width,
.personal_information_main .employment_status .suitability_right_part .suitability_right_part_input_field_width {
    width: 195px !important;
}

.personal_information_main .current_age .suitability_middle_part .input_field_width,
.personal_information_main .current_age .suitability_right_part .input_field_width {
    min-width: 50% !important;
    max-width: 50% !important;
}

.application_form_card {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.application_form_head {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    color: #074a6e;
    font-weight: 600;
    font-size: 20px;
    width: 100%;
}

.application_form_head_bottom_line {
    height: 20px;
    width: 80%;
    margin: 0 auto;
    margin-top: 10px;
}

.application_form_head_bottom_line hr {
    color: #074a6e;
    background-color: #074a6e;
}

.suitability_section_head_root {
    margin-top: 15px !important;
    display: flex;
    width: 80%;
    font-size: 15px;
    font-weight: 600;
    justify-content: space-between;
    align-items: center;
    color: #074a6e;
}

.annuitant_input_fields {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 80%;
}

.annuitant_fullname_and_ssn_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

::-webkit-input-placeholder {
    color: #B3B3B3;
    text-align: left;
}

::placeholder {
    color: #B3B3B3;
    text-align: left;
}

.annuitant_gender_selection {
    min-width: 200px;
    border: none;
}

.annuitant_dateofbirth_field {
    display: flex;
    justify-content: flex-start;
}

.annuitant_dateofbirth_field fieldset {
    /* min-width: 300px; */
    border: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid #B3B3B3 !important;
}

.annuitant_gender_and_dateofbirth_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_streetaddress_and_zip_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_city_and_state_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_phone_and_email_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_owner_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    flex-direction: column;
}

.financial_section_labels label {
    color: #000;
    font-size: 15px;
}

.left_section {
    width: 50%;
    display: flex;
    justify-content: flex-start;
}

.input_field_width {
    min-width: 15% !important;
}

.right_section {
    display: flex;
    justify-content: flex-end;
    width: 50%;
}

.plan_rider_section {
    width: 80%;
}

.plan_rider_checkboxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.suitability_section_width {
    width: 80%;
}

.third_question_proper {
    flex-direction: column !important;
}

.third_question_proper1 {
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
}

.third_question_proper2 {
    vertical-align: middle !important;
}

.first_question,
.second_question,
.third_question,
.fourth_question,
.fifth_question {
    margin-bottom: 15px !important;
}

.third_question {
    margin-bottom: -3px !important;
}

.first_question_bottom {
    margin-bottom: -5px !important;
}

.fourth_question_top {
    margin-top: 15px !important;
}

.seventh_question {
    margin-bottom: 15px !important;
}

.seventh_question_top {
    margin-bottom: 5px !important;
}

@media screen and (min-width: 0px) and (max-width: 420px) {

    .income_tax_bracket,
    .total_outstanding_debt,
    .annual_household_income,
    .source_of_income {
        width: 100% !important;
        justify-content: center !important;
    }

    #menu- .MuiPaper-root {
        width: 70% !important;

    }

    .previous_next_btn {
        flex-direction: column;
    }

    .btn_prev {
        margin: 15px 10px !important;
        width: 100%;
    }

    .label_field label {
        font-size: 0.901rem;
        line-height: 2rem;
    }

    .req_fields label::after {
        content: '*';
        color: red;
    }

    .right_space {
        margin-right: 8px;
        margin-left: 0px !important;
    }

    .fourth_question {
        margin-top: 25px !important;
    }

    .seventh_question {
        margin-top: 15px !important;
    }

    .first_question_top {
        margin-top: 10px !important;
    }

    .first_question_sutability {
        margin-top: 20px !important;
    }

    .the_product_purchase {
        margin-bottom: 15px;
    }

    .second_question_text_field {
        width: 85% !important;
        padding-left: 0px;
    }

    .btn_next {
        margin: 15px 10px !important;
        width: 250%;
        left: -80%;
    }

    .suitability_section_width {
        width: 85% !important;
    }

    .financial_information_of_owner_main {
        margin-top: -5px;
        margin-bottom: 10px;
    }

    .financial_situation_and_need_of_owner,
    .the_product_purchase,
    .existing_account_information,
    .other {
        width: 85% !important;
    }

    .first_question_btn {
        flex-direction: row !important;
    }

    .second_question_1 {
        display: flex;
        align-items: center;
        width: 100%;
        flex-wrap: wrap;
    }

    .second_question_2 {
        margin-top: -18px;
        padding-left: 87%;
        padding-bottom: 5px;
    }

    .suitability_section_head_root {

        width: 85%;
        font-size: 14px;
        margin-bottom: 10px;
        margin-top: 10px !important;
    }

    .suitability_section_head_root_top {
        margin-top: 10px !important;
    }

    .Financial_Information_Of_Owner {
        flex-direction: column !important;

    }

    .financial_section_labels,
    .income_tax_bracket {
        margin-bottom: 20px;
    }

    /* .css-qfz70r-MuiFormGroup-root {
    flex-direction: column !important;
    margin-top: 10px    ;
} */

    .annual_household_income {
        margin-bottom: 20px;
    }
}

@media screen and (min-width: 0px) and (max-width: 420px) {
    #menu- .MuiPaper-root {
        width: 70% !important;
    }
}

@media screen and (min-width :421px) and (max-width: 820px) {
    #menu- .MuiPaper-root {
        width: 25% !important;

        min-width: 0 !important;
    }
}

@media screen and (min-width:401px) and (max-width: 820px) {
    .application_form {
        margin-top: 70px !important;
    }

}

@media screen and (max-width: 1250px) {
    .suitability_left_part {
        width: 28%;
    }

    .suitability_right_part {
        width: 36%;
    }
}

@media screen and (max-width:525px) {
    .suitability_left_part {
        width: 100%;
    }

    .suitability_middle_part {
        width: 100%;
    }

    .suitability_right_part {
        width: 100%;
    }

    .suitability_left_part {
        padding-top: 5px;
    }

    .suitability_middle_part_input_field_width {
        margin-top: 8px !important;
        min-width: 100% !important;
    }

    .suitability_right_part_input_field_width {
        margin-top: 8px !important;
        min-width: 100% !important;
    }

    .current_age,
    .suitability_full_name,
    .gender,
    .employment_status,
    .current_occupation,
    .dependents {
        margin-top: 5px;
    }
}

.MuiPaper-elevation8 {
    min-width: 200px;
}

@media screen and (min-width: 1200px) {
    .btn_next .btn_prev {
        width: 100%;
    }
}
.treeview {
  right: -10px;
  width: 335px;
  padding: 15px 20px 30px 5px;
  font-size: 15px;
}

.border {
  border-left: rgb(189, 189, 189);
}

.main_component {
  padding-right: 40px;
}

.editable_button:hover {
  color: #fff !important;
  background: #074A6E !important;

}

.spn {
  color: rgb(189, 189, 189);
}

.right_section_title {
  font-size: 18px;
}

.right_section_title:hover {
  cursor: pointer;
}

.application_form_section_name {
  padding: 2px;
}

.treeview ul {
  float: left;
  width: 100%;
  position: relative;
  border-left: 8px solid rgb(189, 189, 189);
  height: 50px;
  border-radius: 3px;
  padding: 10px 10px 5px 15px;

}

.application_link_button {
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  top: 180px;
  right: 5%;
}

.middle_line {
  font-weight: 600;
  vertical-align: super;
}

.individual_annuity_application_right_section {
  margin-top: 60px;
  padding-top: 5px;
}

.suitability_analysis_right_section {
  margin-top: 385px;
}

.notice_of_replacement_right_section {
  margin-top: 388px;
}

.transfer_exchange_right_section {
  margin-top: 905px;
}

.individual_annuity_application_right_section li:last-of-type {
  height: 27px;
}

.notice_of_replacement_right_section li:last-of-type {
  height: 27px;
}

.suitability_analysis_right_section li:last-of-type {
  height: 27px;
}

.suitability_analysis_right_section li:first-of-type {
  height: 43px;
}

.suitability_analysis_right_section_exception li:first-of-type {
  height: 43px;
}

.transfer_exchange_right_section li:last-of-type {
  height: 27px;
}

.transfer_exchange_right_section_exception {
  margin-top: 765px;
}

.transfer_exchange_right_section_exception li:last-of-type {
  height: 27px;
}

.suitability_analysis_right_section_exception {
  margin-top: 510px;
}

.suitability_analysis_right_section_exception li:last-of-type {
  height: 27px;
}

/* .current_page{
  color: #1976d2 !important;
} */
li {
  float: left;
  width: 100%;
  border-left: 2px solid rgb(189, 189, 189);
  padding: 10px 0;
  list-style-type: none;
}

@media screen and (max-width: 1450px) {
  .formsitemap .application_link_button .btn_send_this_link_to_applicant {
    position: relative;
    top: 3px;
    left: -24%;
  }
}

@media screen and (max-width: 1300px) {
  .formsitemap .application_link_button .btn_send_this_link_to_applicant {
    position: relative;
    top: 3px;
    left: -68%;
  }

  .editable_button {
    right: 3%;
  }
}

@media screen and (max-width: 1200px) {
  .formsitemap .application_link_button .btn_send_this_link_to_applicant {
    position: relative;
    top: 3px;
    left: -80%;
  }
}

@media screen and (max-width: 1100px) {
  .formsitemap .application_link_button .btn_send_this_link_to_applicant {
    position: relative;
    top: 3px;
    left: -63%;
  }
}

@media screen and (max-width: 800px) {
  .btn_send_this_link_to_applicant {
    position: relative;
    top: 38%;
    left: -80% !important;
  }
}

@media screen and (max-width: 1300px) {
  .editable_button {
    right: 3% !important;
    position: absolute !important;
  }
}


@media screen and (max-width: 520px) {
  .editable_button {
    top: calc(100px + 55px + 90px) !important;
    right: 35% !important;
    position: absolute;
  }
}

@media screen and (max-width: 590px) {
  .btn_send_this_link_to_applicant {
    position: relative;
    top: 38%;
    left: -60% !important;
  }
}

@media screen and (max-width: 520px) {
  .btn_send_this_link_to_applicant {
    position: relative;
    left: -20% !important;
  }
}

@media screen and (max-width: 1020px) {
  .formsitemap .application_link_button .btn_send_this_link_to_applicant {
    position: relative;
    top: 3px;
    left: -23%;
  }
}


@media screen and (max-width: 1800px) {
  .application_link_button {
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    top: 150px;
    right: 3%;
  }
}

@media screen and (max-width: 1650px) {
  .application_link_button {
    top: 150px;
    right: 2%;
  }
}

@media screen and (max-width: 1550px) {
  .application_link_button {
    top: 176px;
    right: 0;
  }
}

/* @media screen and (min-width: 1551px) {
  .application_link_button{
    top: 176px;
    right:0;
  }
} */

@media screen and (max-width: 1420px) {
  /* .application_link_button{
    top: 150px;
    right:-35px;
  } */
}

@media screen and (max-width: 1450px) {
  .treeview {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .btn_send_this_link_to_applicant {
    display: flex;
    flex-direction: column;
    /* position: absolute; */
    /* top: 39%;
    right: 30%; */

  }
}

/* @media screen and (max-width: 1050px) {
  .btn_send_this_link_to_applicant {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 38%;
    right: 30%;

   }
 }*/
/* @media screen and (min-width: 0px)  and (max-width: 500px) {  
  .btn_send_this_link_to_applicant {
    left:41%;
  }
}
 
 @media screen and (max-width: 1300px) {
  .btn_send_this_link_to_applicant {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 38%;
    right: 33%;

   }
 } */

@media screen and (min-width: 0px) and (max-width: 370px) {

  .application_link_button {
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    top: 198px;
    right: 33%;
    left: 33%;
    /* right: 100px;
    width: 250px; */
    /* left:40%; */
  }
}

@media screen and (min-width: 370px) and (max-width: 400px) {

  .application_link_button {
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    top: 168px;
    right: 33%;
    left: 33%;
    /* right: 100px;
    width: 250px; */
    /* left:40%; */
  }
}

@media screen and (min-width: 500px) and (max-width: 600px) {
  .application_link_button {
    left: 40%;
  }
}

@media screen and (min-width:600px) and (max-width: 700px) {
  .application_link_button {
    left: 49.5%;
  }
}

@media screen and (min-width:700px) and (max-width: 800px) {
  .application_link_button {
    left: 53%;
  }
}

@media screen and (min-width:801px) and (max-width: 925px) {
  .application_link_button {
    left: 35%;
  }
}

@media screen and (min-width:925px) and (max-width: 1020px) {
  .application_link_button {
    /* left:38%; */
    right: 32%;
  }
}

@media screen and (min-width:1020px) and (max-width: 1100px) {
  .application_link_button {
    left: 40%;
  }
}

@media screen and (min-width:1100px) and (max-width: 1200px) {
  .application_link_button {
    left: 46%;
  }
}

@media screen and (min-width:1200px) and (max-width: 1300px) {
  .application_link_button {
    left: 42%;
  }
}

@media screen and (min-width:1040px) {
  .application_form {
    margin-top: 80px !important;
  }
}

@media screen and (min-width:1301px) and (max-width:1449px) {
  .application_link_button {
    left: 39.5%;
  }

  .application_form {
    margin-top: 90px !important;
  }
}

@media screen and (min-width:1551px) and (max-width:1800px) {
  .application_link_button {
    margin-top: 25px;
  }

}
* {
    box-sizing: border-box;
}

.full_name {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.current_age,
.gender,
.employment_status,
.current_occupation,
.dependents {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;

}

.radio_group_shift_right {
    margin-left: 15px !important;
}

.btn_want {
    display: flex;
    justify-content: flex-end !important;
    font-size: small;
    padding: 0;
    margin-right: 10% !important;
    color: #074a6e !important;
    cursor: pointer;
}

.Financial_Information_Of_Owner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.left_part {
    width: 33%;
    display: flex;
    justify-content: flex-start;
}

.middle_part {
    width: 33%;
    display: flex;
    justify-content: flex-start;
}

.right_part {
    width: 33%;
    display: flex;
    justify-content: flex-start;
}

.transfer_exchange_request_page_root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.application_form {
    width: 50%;
    margin-top: 30px;
    margin-bottom: 10px;
    /* border:2px solid #074a6e; */
    border-radius: 5px;
    display: flex;
    justify-content: center;
    /* filter: drop-shadow(0px 0px 2px #074a6e); */
}

.Current_Insurance_Company {
    margin-top: -8px !important;
    font-size: 0.701rem;
    line-height: 1.7rem;
}

.plans_label {
    left: -14px !important;
}

.plans_transfer_request {
    margin-top: 5px;
}

.personal_information_main .gender .middle_part .input_field_width,
.personal_information_main .gender .right_part .input_field_width,
.personal_information_main .employment_status .middle_part .input_field_width,
.personal_information_main .employment_status .right_part .input_field_width {
    min-width: 80% !important;
    max-width: 80% !important;
}

.application_form_card {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.application_form_head {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    color: #074a6e;
    font-weight: 600;
    font-size: 20px;
    width: 100%;
}

.application_form_head_bottom_line {
    height: 20px;
    width: 80%;
    margin: 0 auto;
    margin-top: 10px;
}

.application_form_head_bottom_line hr {
    color: #074a6e;
    background-color: #074a6e;
}

.section_head_root {
    margin-top: 15px;
    display: flex;
    width: 80%;
    font-size: 15px;
    font-weight: 600;
    justify-content: space-between;
    align-items: center;
    color: #074a6e;
}

.annuitant_input_fields {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 80%;
}

.annuitant_fullname_and_ssn_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

::-webkit-input-placeholder {
    color: #B3B3B3;
    text-align: left;
}

::placeholder {
    color: #B3B3B3;
    text-align: left;
}

.existing ::-webkit-input-placeholder {
    font-size: 12px;
}

.existing ::placeholder {
    font-size: 12px;
}

.new_existing_account .MuiFormGroup-root .MuiInputBase-root ::-webkit-input-placeholder {
    font-size: 14px;
}

.new_existing_account .MuiFormGroup-root .MuiInputBase-root ::placeholder {
    font-size: 14px;
}

.annuitant_gender_selection {
    min-width: 200px;
    border: none;
}

.annuitant_dateofbirth_field {
    display: flex;
    justify-content: flex-start;
}

.annuitant_dateofbirth_field fieldset {
    /* min-width: 300px; */
    border: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid #B3B3B3 !important;
}

.annuitant_gender_and_dateofbirth_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_streetaddress_and_zip_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_city_and_state_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_phone_and_email_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_owner_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    flex-direction: column;
}

.left_section {
    width: 50%;
    display: flex;
    justify-content: flex-start;
}

.input_field_width {
    min-width: 95% !important;
}

.right_section {
    display: flex;
    justify-content: flex-end;
    width: 50%;
}

.plan_rider_section {
    width: 80%;
}

.plan_rider_checkboxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.not_required label::after {
    content: "" !important;
}

@media screen and (min-width:0px) and (max-width: 420px) {

    .Owner_Full_Name,
    .Owner_Tax_Id_or_SSN,
    .Street_Address,
    .Zip,
    .City,
    .State,
    .Annuitant_Insured,
    .Current_Insurance_Company,
    .Phone,
    .Account_Number {
        flex-direction: row !important;
        width: 100% !important;
    }

    .MuiInput-root {
        width: 100% !important;
    }

    .Current_Insurance_Company {
        margin-top: 2px !important;
    }

    .existing_annuity_status {
        margin-top: 25px !important;
    }

    .type_of_transfer_rollover {
        margin-top: 25px !important;
        margin-bottom: 25px !important;
    }

    .qualified_events_for_rollover_funds {
        margin-top: 15px !important;
        margin-bottom: 15px !important;
    }

    .non_qualified_or {
        margin: 2px 15px 0 -5px !important;
    }

    .previous_next_btn {
        flex-direction: column;
    }

    .btn_prev {
        margin: 15px 10px !important;
        width: 100%;

    }

    /* .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
        width: 100% !important;
    } */
    .btn_next {
        margin: 15px 10px !important;
        width: 250%;
        left: -80%;

    }

    /* .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
         width: 100% !important;
     } */
    .internal_exchange_1 {
        display: flex;
        align-items: center;
        width: 100%;
        flex-wrap: wrap;
    }

    .internal_exchange_2 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .btn_want {
        margin-right: 10% !important;
        margin-top: 6px !important;
    }
}

@media screen and (min-width:401px) and (max-width: 820px) {
    .application_form {
        margin-top: 20px !important;
    }

    .previous_next_btn {
        flex-direction: column;
    }

    .previous_next_btn .btn_next {
        margin-top: 20px !important;
    }

    .previous_next_btn .btn_prev {
        margin: 0px 20px !important;
    }

    .btn_want {
        margin-right: 10% !important;
        margin-top: 6px !important;
    }
}
.disclosure_form_radio_button {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 80%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuity_disclosure_desc {
    width: 80%;
    display: flex;
    flex-direction: column;
}

.annuity_disclosure_desc_1 {
    color: #606060;
}

.annuity_disclosure_desc_2 {
    color: #606060;
    margin-top: 10px !important;
}

.radio_group_shift_right {
    margin-left: 15px;
}
.noticeOfReplacementHead th {
    white-space: nowrap;
}

.notice_of_replace_date fieldset {
    border: none !important;
}

.notice_of_replace_date input {
    padding: 10px 14px !important;
    font-size: 15px !important;
}

.ma_textfield_one input {
    width: 175px !important;
}

.ma_textfield_two input {
    width: 130px !important;
}

.ma_textfield_three input {
    width: 300px !important;
}

.ma_textfield_four input {
    width: 140px !important;
}

.ma_textfield_five input {
    width: 88px !important;
}

.ma_textfield_six input {
    width: 80px !important;
}

.ma_textfield_five div {
    padding: 0px !important;
}

.ma_tbl {
    border-collapse: unset !important;
    border-spacing: 2px !important;
}

.mv_tbl_cell {
    border-bottom: none !important;
}

.notice_of_replace_date button {
    padding: 10px 0px 0px 0px !important;
}

.notice_of_replace_date svg {
    display: none !important;
}

.owner_replacement_not_required_amt input {
    padding: 10px 5px !important;
    font-size: 15px !important;
}


.important_notice_detail {
    width: 80%;
    display: flex;
    flex-direction: column;
}

.notice_detail_desc {
    color: #606060;
    margin-bottom: 15px !important;
}

.question_A_important_notice {
    color: #000;
    font-weight: 500;
}

.question_B_important_notice {
    color: #000;
    font-weight: 500;
}

.question_C_important_notice {
    color: #000;
    font-weight: 500;
}

.important_notice_question_c_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding-bottom: 10px;
}

.question_D_important_notice {
    color: #000;
    font-weight: 500;
    margin-top: 25px !important;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.question_d_input_field {
    margin-top: -22px !important;
    margin-left: 10px !important;
}

.broker_section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 80%;
}

.req_fields label::after {
    content: '*';
    color: red;
}

.radio_group_shift_right {
    margin-left: 15px !important;
}

@media (max-width:1580px) and (min-width:1301px) {
    .question_d_input_field {
        margin-top: 0px !important;
    }
}

@media (max-width:831px) {
    .question_d_input_field {
        margin-top: -2px !important;
    }
}
