.upload_tool_page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow-y: auto;
}

.upload_tool_type {
    text-align: left;
    color: black !important;
    font-size: 15px !important;
    /* border-bottom: 3px solid #d9a22e !important; */
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    padding-left: 15px !important;
    font-weight: 700 !important;
}

.upload_tool_type_item :hover {
    cursor: pointer !important;
    /* border-left: 2px solid #dcb664 !important; */
}

.upload_tool_type {
    border-radius: 5px !important;
}

.upload_tool_type_container {
    max-height: 200px;
    border-radius: 10px;
    padding: 10px 0px;
}

.form_type_change_select fieldset {
    border: none !important;
}

.form_type_change_select {
    background-color: #fcf6eb !important;
}

.form_type_change_select .MuiSelect-outlined {
    padding: 10px 14px;
}

.upload_tool_left_border {
    border-left: 4px solid #d9a22e !important;
    background-color: #FCF6EB !important;
}

.upload_tool_underline {
    border-bottom: 3px solid #d9a22e !important;
}

.upload_nav_item {
    height: 50px;
}

.iFrameBox {
    width: 40% !important;
    /* border-radius: 18px 18px 0px 0px !important; */
    /* box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.15) !important; */
}

@media screen and (min-width:500px) and (max-width:1000px) {
    .iFrameBox {
        width: 70% !important;
    }
}

@media screen and (min-width:0px) and (max-width:499px) {
    .iFrameBox {
        width: 90% !important;
    }
}

/* .tab_1 {
    padding: 0px 40px 0px 40px !important;
}

.tab_2 {
    padding: 0px 40px 0px 40px !important;
}

.tab_2 {
    padding: 0px 40px 0px 40px !important;
} */