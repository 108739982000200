.file-inputs {
    /* padding: 1em; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px dashed #cbd5e0;
    background-color: #edf2f7;
    /* min-width: 486px; */
    min-height: 65px;
}

.file-inputs {
    position: relative;
    width: 100%;
}

.file-inputs input {
    position: relative;
    text-align: right;
    opacity: 0;
    z-index: 2;
    cursor: pointer;
    /* height: 46px; */
    padding: 0px;
    border: 0px;
    /* max-width: 200px; */
}

.file-inputs button {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    /* background-color: #f55e30; */
    font-size: 1.1rem;
    cursor: pointer;
    border-radius: 4px;
    padding: 0px;
    border: none;
    outline: none;
    transition: background-color 0.4s;
    box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.5);
}

.file-inputs button i {
    width: 1.5em;
    height: 1.5em;
    padding: 0.4em;
    background-color: #fff;
    /* color: #f55e30; */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.8em;
    font-size: 0.8em;
}

.file-inputs:hover button {
    /* background-color: #f15120; */
}

.main {
    font-weight: bold;
    font-size: 16px;
}

.info {
    font-size: 0.8rem;
}

.file-card {
    width: 100%;
}

.delete_container {
    display: flex;
    width: 100%;
    /* justify-content: space-between; */
}

.delete_icon {
    display: flex;
    justify-content: flex-end;
}

.file_item {
    margin: 3px;
}

.file_name {
    font-size: 15px;
}

.delete_onclick {
    cursor: pointer;
}

.file_size {
    font-size: 13px;
    color: #A6A6A6;
}