.errCategory {
    display: flex;
    justify-content: flex-start !important;
    flex-direction: row !important;
    min-width: none !important;
    min-height: 10px !important;
    width: 70% !important;
    margin: 0 auto;

}

.box_height {
    max-width: 100% !important;
}

@media screen and (min-width: 0px) and (max-width: 400px) {
    .btns_category {
        margin: 10px auto;
        width: 95%;
    }

    /* .css-1n0qnrx-MuiFormControl-root-MuiTextField-root
{
    width: 70% !important;
} */

}

@media screen and (min-width: 0px) and (max-width: 420px) {
    .edit_pdf_popup2 h4 {
        min-width: auto !important;
    }
}

.btns_category {
    margin: 10px auto;
    width: 95%;
}

/* .css-ypiqx9-MuiDialogContent-root{
    overflow-y: none !important
} */
/* .css-ypiqx9-MuiDialogContent-root{
    overflow-y: unset !important;
    padding: 20px !important;
} */