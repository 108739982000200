.main_container_history {
    height: max-content;
    width: 60%;
    margin: 0 auto !important;
    box-shadow: 0px 0px 4px #D9A22E;

}

.Grid_main {
    margin: 20px auto !important;
    padding-bottom: 20px;
    width: 95% !important;
    /* padding-right: 25px; */
    justify-content: space-evenly;
}

.item1 {
    border-radius: 10px;
}

.itemtextcenter11 {
    color: white !important;
}

.item1 .MuiGrid-container {
    align-items: center;
    height: 35px !important;
    /* padding: 7px; */
    justify-content: space-between !important;

}

.itemtextcenter1 {
    /* text-align: center; */
    text-align: left !important;
    width: 70px !important;
}

.head {
    background-color: #D9A22E !important;
}

.calc_history_tbl {
    border-collapse: separate !important;
    border-spacing: 0px 8px !important;
    padding: 0px 15px 0px 15px;
}

.calc_history_tbl_header th {
    background-color: #D9A22E;
    color: white;
    padding: 13px 10px 13px 17px;
    white-space: nowrap;
    font-size: 16px;
}

.header_radius_left {
    border-radius: 10px 0px 0px 10px;
}

.header_radius_right {
    border-radius: 0px 10px 10px 0px;
}

.calc_history_tbl_row {
    background-color: #f1f1f1;
}

.calc_history_tbl_row td {
    padding: 10px 10px 10px 16px;
    font-size: 16px;
    /* white-space: nowrap; */
}

.grid_item {
    background-color: rgb(241, 241, 241);
    justify-content: center;
    align-items: center;

}

.view_btn {
    width: 100% !important;
    background-color: #D9A22E !important;
    color: white !important;
    padding: 2px !important;
    position: static !important;
}

.grid_item1 {
    background-color: #D9A22E !important;

}

.box_height,
.box_height_Cal_history {
    text-align: center !important;
    padding: 7px !important;
    margin-bottom: 10px !important;
    align-items: center;
    display: flex;

}

.btn_div1_history button {
    background-color: #D9A22E !important;
    margin-left: 10px;
}

.btn_div1_history {
    width: 60%;
    margin: 10px auto;
    justify-content: flex-end;
    display: flex;

}

/* .css-1loofyr-MuiGrid-root{
position: unset !important;
} */
@media screen and (min-width: 0px) and (max-width: 900px) {

    .Grid_box {
        margin-top: 20px !important;
        overflow-x: scroll !important;
        /* overflow-y: hidden !important; */
        overflow-y: auto;
        /* white-space: nowrap !important; */
    }

    .main_container_history,
    .btn_div1_history {
        width: 90%;
    }

    /* .grid_item .MuiGrid-container {
    width: 900px !important;
}  */
    .grid_item {
        width: 1500px;

    }
}

@media screen and (min-width:900px) and (max-width:1200px) {

    .main_container_history,
    .btn_div1_history {
        width: 90%;
    }
}

@media screen and (min-width:1201px) and (max-width:1600px) {

    .main_container_history,
    .btn_div1_history {
        width: 75%;
    }
}

@media screen and (min-width: 0px) and (max-width: 425px) {
    .btn_div1_history {
        display: flex;
        flex-direction: column !important;
    }

    .btn_div1_history button {
        margin: 0 auto 10px auto;
    }
}

.box_height_Cal_history {
    text-align: center !important;
    padding: 7px !important;
    margin-bottom: 10px !important;
    align-items: center;
    display: flex;
}

@media screen and (min-width:0px) and (max-width:900px) {
    .head .MuiGrid-container {
        padding: 7px;
    }
}

@media screen and (min-width:0px) and (max-width:900px) {
    .Grid_main_Pdf_config {
        margin: 20px auto !important;
        padding-bottom: 20px;
        justify-content: space-evenly;
        overflow-y: scroll;
        width: 95% !important;
        overflow-y: hidden;
    }
}