.treeview {
  right: -10px;
  width: 335px;
  padding: 15px 20px 30px 5px;
  font-size: 15px;
}

.border {
  border-left: rgb(189, 189, 189);
}

.main_component {
  padding-right: 40px;
}

.editable_button:hover {
  color: #fff !important;
  background: #074A6E !important;

}

.spn {
  color: rgb(189, 189, 189);
}

.right_section_title {
  font-size: 18px;
}

.right_section_title:hover {
  cursor: pointer;
}

.application_form_section_name {
  padding: 2px;
}

.treeview ul {
  float: left;
  width: 100%;
  position: relative;
  border-left: 8px solid rgb(189, 189, 189);
  height: 50px;
  border-radius: 3px;
  padding: 10px 10px 5px 15px;

}

.application_link_button {
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: center;
  top: 180px;
  right: 5%;
}

.middle_line {
  font-weight: 600;
  vertical-align: super;
}

.individual_annuity_application_right_section {
  margin-top: 60px;
  padding-top: 5px;
}

.suitability_analysis_right_section {
  margin-top: 385px;
}

.producer_disclosure_right_section {
  margin-top: 1000px;
}

.notice_of_replacement_right_section {
  margin-top: 388px;
}

.transfer_exchange_right_section {
  margin-top: 905px;
}

.individual_annuity_application_right_section li:last-of-type {
  height: 27px;
}

.notice_of_replacement_right_section li:last-of-type {
  height: 27px;
}

.suitability_analysis_right_section li:last-of-type {
  height: 27px;
}

.producer_disclosure_right_section li:last-of-type {
  height: 27px;
}

.suitability_analysis_right_section li:first-of-type {
  height: 43px;
}

.producer_disclosure_right_section li:first-of-type {
  height: 43px;
}

.suitability_analysis_right_section_exception li:first-of-type {
  height: 43px;
}

.producer_disclosure_right_section_exception li:first-of-type {
  height: 43px;
}

.transfer_exchange_right_section li:last-of-type {
  height: 27px;
}

.transfer_exchange_right_section_exception {
  margin-top: 765px;
}

.transfer_exchange_right_section_exception li:last-of-type {
  height: 27px;
}

.suitability_analysis_right_section_exception {
  margin-top: 510px;
}

.producer_disclosure_right_section_exception {
  margin-top: 1150px;
}

.suitability_analysis_right_section_exception li:last-of-type {
  height: 27px;
}

.producer_disclosure_right_section_exception li:last-of-type {
  height: 27px;
}

/* .current_page{
  color: #1976d2 !important;
} */
li {
  float: left;
  width: 100%;
  border-left: 2px solid rgb(189, 189, 189);
  padding: 10px 0;
  list-style-type: none;
}

@media screen and (max-width: 1450px) {
  .formsitemap .application_link_button .btn_send_this_link_to_applicant {
    position: relative;
    top: 3px;
    left: -24%;
  }
}

@media screen and (max-width: 1300px) {
  .formsitemap .application_link_button .btn_send_this_link_to_applicant {
    position: relative;
    top: 3px;
    left: -68%;
  }

  .editable_button {
    right: 3%;
  }
}

@media screen and (max-width: 1200px) {
  .formsitemap .application_link_button .btn_send_this_link_to_applicant {
    position: relative;
    top: 3px;
    left: -80%;
  }
}

@media screen and (max-width: 1100px) {
  .formsitemap .application_link_button .btn_send_this_link_to_applicant {
    position: relative;
    top: 3px;
    left: -63%;
  }
}

@media screen and (max-width: 800px) {
  .btn_send_this_link_to_applicant {
    position: relative;
    top: 38%;
    left: -80% !important;
  }
}

@media screen and (max-width: 1300px) {
  .editable_button {
    right: 3% !important;
    position: absolute !important;
  }
}


@media screen and (max-width: 520px) {
  .editable_button {
    top: calc(100px + 55px + 90px) !important;
    right: 35% !important;
    position: absolute;
  }
}

@media screen and (max-width: 590px) {
  .btn_send_this_link_to_applicant {
    position: relative;
    top: 38%;
    left: -60% !important;
  }
}

@media screen and (max-width: 520px) {
  .btn_send_this_link_to_applicant {
    position: relative;
    left: -20% !important;
  }
}

@media screen and (max-width: 1020px) {
  .formsitemap .application_link_button .btn_send_this_link_to_applicant {
    position: relative;
    top: 3px;
    left: -23%;
  }
}


@media screen and (max-width: 1800px) {
  .application_link_button {
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    top: 150px;
    right: 3%;
  }
}

@media screen and (max-width: 1650px) {
  .application_link_button {
    top: 150px;
    right: 2%;
  }
}

@media screen and (max-width: 1550px) {
  .application_link_button {
    top: 176px;
    right: 0;
  }
}

/* @media screen and (min-width: 1551px) {
  .application_link_button{
    top: 176px;
    right:0;
  }
} */

@media screen and (max-width: 1420px) {
  /* .application_link_button{
    top: 150px;
    right:-35px;
  } */
}

@media screen and (max-width: 1450px) {
  .treeview {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .btn_send_this_link_to_applicant {
    display: flex;
    flex-direction: column;
    /* position: absolute; */
    /* top: 39%;
    right: 30%; */

  }
}

/* @media screen and (max-width: 1050px) {
  .btn_send_this_link_to_applicant {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 38%;
    right: 30%;

   }
 }*/
/* @media screen and (min-width: 0px)  and (max-width: 500px) {  
  .btn_send_this_link_to_applicant {
    left:41%;
  }
}
 
 @media screen and (max-width: 1300px) {
  .btn_send_this_link_to_applicant {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 38%;
    right: 33%;

   }
 } */

@media screen and (min-width: 0px) and (max-width: 370px) {

  .application_link_button {
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    top: 198px;
    right: 33%;
    left: 33%;
    /* right: 100px;
    width: 250px; */
    /* left:40%; */
  }
}

@media screen and (min-width: 370px) and (max-width: 400px) {

  .application_link_button {
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: center;
    top: 168px;
    right: 33%;
    left: 33%;
    /* right: 100px;
    width: 250px; */
    /* left:40%; */
  }
}

@media screen and (min-width: 500px) and (max-width: 600px) {
  .application_link_button {
    left: 40%;
  }
}

@media screen and (min-width:600px) and (max-width: 700px) {
  .application_link_button {
    left: 49.5%;
  }
}

@media screen and (min-width:700px) and (max-width: 800px) {
  .application_link_button {
    left: 53%;
  }
}

@media screen and (min-width:801px) and (max-width: 925px) {
  .application_link_button {
    left: 35%;
  }
}

@media screen and (min-width:925px) and (max-width: 1020px) {
  .application_link_button {
    /* left:38%; */
    right: 32%;
  }
}

@media screen and (min-width:1020px) and (max-width: 1100px) {
  .application_link_button {
    left: 40%;
  }
}

@media screen and (min-width:1100px) and (max-width: 1200px) {
  .application_link_button {
    left: 46%;
  }
}

@media screen and (min-width:1200px) and (max-width: 1300px) {
  .application_link_button {
    left: 42%;
  }
}

@media screen and (min-width:1040px) {
  .application_form {
    margin-top: 80px !important;
  }
}

@media screen and (min-width:1301px) and (max-width:1449px) {
  .application_link_button {
    left: 39.5%;
  }

  .application_form {
    margin-top: 90px !important;
  }
}

@media screen and (min-width:1551px) and (max-width:1800px) {
  .application_link_button {
    margin-top: 25px;
  }

}