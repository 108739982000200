.verify_popup_title {
    margin-top: 20px !important;
    color: #D9A22E !important;
    font-weight: 600 !important;

}

.verify_popup_item {
    text-align: center;
    margin-bottom: 20px !important;
}

.verify_popup_phone_label {
    text-align: start;
    margin-bottom: 10px !important;
}

.verify_popup_phone_input {
    text-align: start;
    margin-bottom: 20px !important;
}

.verify_popup_phone_input button {
    background-color: white !important;
    margin-bottom: 10px !important;
}

.verify_popup_phone_img {}