.initialAccountTableCell {
    border-bottom: 2px solid white !important;
    padding: 5px 0px 5px 5px !important;
}

.initialAccountTableCell2 {
    border-bottom: 2px solid white !important;
    padding: 5px 0px 5px 10px !important;
}

.insuranceProducerTableCell {
    border-bottom: 2px solid white !important;
}

.initialAccountTextField fieldset {
    border: none !important;
}

.initialAccountTextField input {
    padding: 3px 0px !important;
    text-align: center;
}

.radio_group_shift_right_fixed {
    margin-left: 40px !important;
}

.beneficiaries_1 {
    width: 1% !important;
}

.beneficiaries_2 {
    width: 20% !important;
}

.beneficiaries_3 {
    width: 10% !important;
}

.beneficiaries_4 {
    width: 1% !important;
}

.beneficiaries_5 {
    width: 20% !important;
}

.beneficiaries_6 {
    width: 1% !important;
}