.page_title{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 55px;
    background: #074a6e;
    color:#FFFFFF;
    font-weight: 500;
    font-size: 23px;
}
.page_title_Annuty{
    background: #D9A22E !important;
}
.resources_main_part{
    padding: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.resources_main_part .replacement_form_card{
    width: 170px;
    height: 190px;
    border-radius: 10px;
    border: 1px solid #D9A22E;
    ;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.resources_main_part .replacement_form_card a{
    width: 100%;
}
.resources_main_part .replacement_form_card .box_hover_section{
    display: none;
    opacity: 0;
}
.resources_main_part .replacement_form_card .replacement_form_card_image{
    height: 150px;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.resources_main_part .replacement_form_card .replacement_form_card_bottom{
    height: 40px;
    width: 100%;
    text-align: center;
    background: #D9A22E !important;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
}
.resources_main_part .replacement_form_card .replacement_form_card_bottom p{
    color: #fff;
    font-weight: 500;
}
.resources_main_part .replacement_form_card:hover .box_hover_section{
    opacity: 1;
    background: rgba(0,0,0, .8) !important;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    border-radius: 10px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 50%;
    left: 50%;
    z-index: 11;
    transform: translate(-50%, -50%);
}

@media screen and (max-width: 420px){
    .resources_main_part{
        justify-content: center;
    }
}