/* .home_page_banner_root{
    background:url(../../assets/FarmersLife_Homepage_banner_field.png);
    background-repeat: no-repeat;
    height: 301px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin:auto 0;
} */

body {
    height: 100vh;

}

.home_page_banner_root {
    width: 100%;
    position: relative;
}


.start-e-app-btn-myga {
    background-color: #FCF6EB;
    border-radius: 20px;
    cursor: pointer;
}

.start-e-app-btn-fia {
    background-color: #e6edf0;
    border-radius: 20px;
    cursor: pointer;
}

.start_text_item {
    padding-bottom: 23px;
}

.start_text_myga {
    color: #D9A22E !important;
    font-size: 20px !important;
    font-weight: 500 !important;
    text-align: center;
}

.start_text_fia {
    color: #074A6E !important;
    font-size: 20px !important;
    font-weight: 500 !important;
    text-align: center;
}

.form_type {
    color: #000000 !important;
    font-weight: 500 !important;
    font-size: 20px !important;
}

.bottom_section {
    background-color: #FCF6EB !important;
    margin-top: 40px !important;
}

.resource_btns {
    font-size: 14px !important;
    color: white !important;
    text-transform: capitalize !important;
}


.illustration_btn,
.config_btn {
    background-color: #074A6E !important;
}

.history_btn,
.upload_btn {
    background-color: #D9A22E !important;
}

.illustration_btn_item {
    margin-right: 10px !important;
}


.btn_grp1 {
    width: 40%;
    display: flex;
    justify-content: start;
}

.btn_grp2 {
    width: 40%;
    display: flex;
    justify-content: center;
}

.go_file_select .MuiSelect-select {
    padding: 14px 14px 15px 14px !important;
}

.not_required_go_file label::after {
    content: "" !important;
}

/* .bold_application .css-10hburv-MuiTypography-root:hover{
    font-weight: bold !important;
} */

.myga_illu_btn {
    font-size: 14px !important;
    color: white !important;
    text-transform: capitalize !important;
    background-color: #D9A22E !important;
    width: 100% !important;
    white-space: nowrap;
}

.fia_illu_btn {
    font-size: 14px !important;
    color: white !important;
    text-transform: capitalize !important;
    background-color: #074A6E !important;
    width: 100% !important;
    white-space: nowrap;
}

.myga_start_btn {
    font-size: 14px !important;
    color: white !important;
    text-transform: capitalize !important;
    background-color: #D9A22E !important;
    width: 100% !important;
    white-space: nowrap;
}

.fia_start_btn {
    font-size: 14px !important;
    color: white !important;
    text-transform: capitalize !important;
    background-color: #074A6E !important;
    width: 100% !important;
    white-space: nowrap;
}

.btngroup {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 75%;
    margin-top: 30px;
    padding-right: 40px;
}

.btn_grp1 .MuiButton-root {
    margin-right: 5%;
}

.btn_grp2 .btn {
    margin-right: 5%;
}

.btn_new,
.btn_view {
    background-color: #D9A22E !important;
    text-transform: none !important;
    color: black !important;
    border: none !important;
    font-size: 18px !important;
    margin-bottom: 10px !important;
}

.btn_new {
    border: 3px solid #074A6E !important;
    background-color: #074A6E !important;
}

.btn_new1,
.btn_view1 {
    text-decoration: none;
    color: black !important;
}

.btn_new1 {
    color: #fff !important;
}

.btn_new:hover {
    background-color: #074A6E !important;
}

.btn_new:hover .btn_new1 {
    color: white !important;
}

.btn_view1:hover {
    color: white !important;
}

.btn_view:hover {
    background-color: #074A6E !important;
}

.home_page_banner_root img {
    width: 100%;
    height: 300px;
}

.home_page_banner_text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.home_page_banner_text {
    color: #fff;
    font-size: 30px;
    margin-top: 20px;
    font-weight: 500;
}

.home_page_main_content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.description_about_company {
    margin-top: 10px;
    width: 70%;
    font-size: 15px;
    font-weight: 400;
    text-align: left;
}

.btn_new_Config {
    padding: 10px;
    height: 50px;
    color: #074A6E !important;
    border: 1px solid #074A6E !important;
}

.group_of_box_root {
    /* width: 95%; */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 40px;
    margin-bottom: 20px;
}

.application_box_root {
    /* border: 1px solid #000; */
    border-radius: 10px;
    background-color: #074A6E !important;
    color: #fff !important;
    margin-bottom: 10px !important;
    height: max-content !important;
}

.application_box_header {
    background-color: #D9A22E !important;
    border-radius: 10px 10px 0 0;
    text-align: center;
    font-size: 17px !important;
    color: #000000 !important;
    position: relative !important;
}

.application_box_root .list_item {
    padding-bottom: 0;
}

.application_box_root a {
    text-decoration: none;
    color: #fff;
}

.application_box_root a:hover {
    text-decoration: none;
    color: #D9A22E !important;
    cursor: pointer;
}

.application_box_root .list_item:hover {
    cursor: context-menu !important;
}

.home_page_footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: absolute;
    /* bottom:-245px; */
    left: 0;
    width: 100%;
    background: #074A6E;
    padding: 10px 20px;
    border-top: 5px solid #D9A22E;
}

.contact_us_section_footer {
    min-width: 34%;
    max-width: 25%;
    display: flex;
    padding-left: 40px;
    /* justify-content: center; */
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
    color: #FFFFFF;
}

.contact_detail_footer {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 200px;
}

.imp_link_section1_footer {
    border: none;
    min-width: 33%;
    max-width: 25%;
    list-style: none;
    color: #fff;
    display: flex;
    justify-content: center;
}

.imp_link_section1_footer ul {
    display: flex;
    flex-direction: column;

}

.imp_link_section1_footer li {
    border-left: none;
    list-style: disc;
    color: #D9A22E;
    padding-bottom: 0;
    width: auto;
}

.imp_link_section1_footer ul,
.imp_link_section2_footer ul {
    padding-top: 0;
    margin-top: 0;
}

.imp_link_section1_footer li span {
    color: #fff;
}

.imp_link_section2_footer {
    border: none;
    min-width: 25%;
    max-width: 25%;
    list-style: none;
    color: #fff;
    display: flex;
    justify-content: flex-start;

}

.imp_link_section2_footer li {
    border-left: none;
    list-style: disc;
    color: #D9A22E;
    padding-bottom: 0;

}

.imp_link_section2_footer li span {
    color: #fff;
}

.financial_rating_desc {
    margin-top: 20px !important;
    font-size: 16px;
    text-align: left;
    margin-bottom: 20px !important;
}

.copyright_info {
    /* width: 90%; */
    margin: 0 auto;
    text-align: center;
    color: #fff;
}

.financial_section_footer {
    min-width: 33%;
    max-width: 25%;
    display: flex;
    padding-left: 10px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}

.footer_links_with_hover {
    color: #fff !important;
    text-decoration: none !important;
}

.footer_links_with_hover:hover {
    color: #D9A22E !important;
    cursor: pointer !important;
}

.application_box_root {
    /* margin-left: 20px !important; */
    height: max-content !important;
    width: 20vw !important;
}

.box_margin_right {
    margin-right: 10px !important;
}

.group_of_box_root .MuiBox-root {
    display: flex;
    flex-direction: row;
}

.notice_icon .MuiListItemIcon-root {
    min-width: 21px !important;
}

.notice_icon {
    min-width: 21px !important;
}

.Config {

    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

@media screen and (max-width: 900px) {
    .application_box_root {
        width: 42vw !important;
    }
}

@media screen and (max-width: 470px) {
    .group_of_box_root {
        justify-content: center !important;
    }
}

@media (max-width:900px) {
    .contact_us_section_footer {
        min-width: 50%;
        max-width: 50%;
        padding: 0;
    }

    .imp_link_section1_footer {
        min-width: 50%;
        max-width: 50%;
        justify-content: flex-start;
    }

    .imp_link_section2_footer {
        min-width: 50%;
        max-width: 50%;
        margin-top: 20px;
    }

    .financial_section_footer {
        max-width: 50%;
        min-width: 50%;
        margin-top: 20px;
        justify-content: flex-start;
        align-items: flex-start;
    }




    .btn_grp1 {
        width: 100%;
        justify-content: space-evenly;
        display: flex;
    }

    .btn_grp2 {
        width: 100%;
        justify-content: space-evenly;
        display: flex;
    }

    .btngroup {
        flex-direction: column;
        padding: 0;
        justify-content: center;
    }

    .btn_grp1 .MuiButton-root {
        margin-right: 0;
    }

    .btn_grp2 .btn {
        margin-right: 0;
    }
}

@media (max-width:470px) {
    .contact_us_section_footer {
        min-width: 100%;
        max-width: 100%;
        padding: 0;
    }

    .Config {
        width: 80%;
        display: flex;
        margin: 0 auto;
        flex-direction: column;
        margin-bottom: 10px;
    }

    .contact_detail_footer {
        width: 100%;
    }

    .imp_link_section1_footer {
        min-width: 100%;
        max-width: 100%;
        justify-content: center;
        margin-top: 20px;
    }

    .imp_link_section2_footer {
        min-width: 50%;
        max-width: 50%;
        margin-top: 20px;
    }

    .financial_section_footer {
        max-width: 100%;
        min-width: 100%;
        margin-top: 20px;
        justify-content: center;
        align-items: center;
    }

    .application_box_root {
        max-width: 350px !important;
        min-width: 350px !important;
    }

    .btn_grp1 {
        width: 80%;
        justify-content: space-between;
        display: flex;
        margin: 0 auto;
        flex-direction: column;
    }

    .btn_grp2 {
        width: 80%;
        justify-content: center;
        display: flex;
        margin: 0 auto;
        flex-direction: column;
    }

    .btngroup {
        flex-direction: column;
        padding: 0;
        justify-content: center;
    }

    .btn_grp1 .MuiButton-root {
        margin-right: 0;
    }

    .btn_grp2 .btn {
        margin-right: 0;
    }
}

@media screen and (min-width:0px) and (max-width:420px) {
    .description_about_company {
        width: 83%;
        text-align: justify;
    }

    .Config {
        width: 80%;
        display: flex;
        margin: 0 auto;
        flex-direction: column;
        margin-bottom: 10px;
    }

}

@media screen and (min-width:377px) and (max-width:899px) {
    .illustration_btn {
        width: 105% !important;
    }

    .myga_illu_btn {
        width: 90% !important;
    }

    .config_btn {
        width: 100% !important;
    }

    .myga_start_btn {
        width: 90% !important;
    }

    .fia_start_btn {
        width: 90% !important;
    }

    .fia_illu_btn {
        width: 90% !important;
    }

    .upload_btn {
        width: 106% !important;
    }
}

@media screen and (min-width:0px) and (max-width:376px) {
    .illustration_btn {
        width: 90vw !important;
    }

    .myga_illu_btn {
        width: 70vw !important;
    }

    .config_btn {
        width: 90vw !important;
        margin-bottom: 5px !important;
    }

    .myga_start_btn {
        width: 70vw !important;
        margin-bottom: 5px !important;
    }

    .fia_start_btn {
        width: 70vw !important;
        margin-bottom: 5px !important;
    }

    .fia_illu_btn {
        width: 70vw !important;
    }

    .start_text_fia {
        padding: 0 10px;
    }

    .upload_btn {
        width: 90vw !important;
    }

    .history_btn {
        width: 90vw !important;
    }
}

@media screen and (min-width:900px) and (max-width:1077px) {
    .btngroup {
        width: 84%;
    }

    .description_about_company {
        width: 84%;
        text-align: justify;
    }

}

@media screen and (min-width:901px) and (max-width:1350px) {
    .btngroup {
        flex-wrap: unset;
    }

    .btn_grp1 {
        width: 45%;
    }

    .Config .MuiButtonBase-root {
        height: 75px;
        align-items: center;
        display: flex;
        margin-right: 5%;
    }
}

.listItemIcon {
    padding-left: 6px;
}

.listItemIcon svg {
    margin-right: 5px !important;
}

.listItemButton {
    margin: 0px -20px !important;
}