* {
    box-sizing: border-box;

}

.ownerReplacementContainer {
    margin-top: 10px;
}

.radio_group_shift_right {
    margin-left: 15px;
}

.replacementTableHead {
    background-color: #074A6E !important;
    border-radius: 5px !important;
}

.replacementTableHead th {
    color: white !important;
    padding: 10px 10px 10px 14px;
}

.replacementTableRow {
    background-color: #F1F1F1 !important;
}

.replacementTableContainer {
    border-radius: 5px 5px 5px 5px !important;
    margin-top: 18px;
}

.replacementTableCell {
    border-bottom: 5px solid white !important;
    padding: 0px !important;
}

.replacementTableCellDisableColor {
    color: rgb(189, 189, 189) !important;
}

.annuitant_gender_and_dateofbirth_row .annuitant_dateofbirth_field input {
    width: 100%;
    color: black;

}

.dateCloseIcon {
    transform: translateX(-30px);
    z-index: 1;
}

.annuitant_gender_and_dateofbirth_row .annuitant_dateofbirth_field {
    width: 47.3%;
}

.annuitant_gender_and_dateofbirth_row .annuitant_dateofbirth_field .MuiFormControl-root {
    width: 100%;
}

.annuitant_input_fields .annuitant_gender_and_dateofbirth_row .annuitant_dateofbirth_field label,
.annuitant_owner_section .annuitant_gender_and_dateofbirth_row .annuitant_dateofbirth_field label {
    color: rgba(0, 0, 0, 0.6);
    bottom: 20px !important;
    left: -16px !important;
}

.dobColorClass label {
    color: rgba(0, 0, 0, 0.38) !important;
}

.annuitant_owner_section .annuitant_gender_and_dateofbirth_row .annuitant_gender_selection .input_field_width .MuiInput-root {
    width: 100% !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    min-width: 400px;
}

.application_page_root {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

/* @media screen and (max-width: 520px) {
    .application_page_root{margin-top: 100px !important;}
} */

.application_form {
    width: 50%;
    margin-top: 30px;
    margin-bottom: 10px;
    /* border: 2px solid #074a6e; */
    border-radius: 5px;
    display: flex;
    justify-content: center;
    /* filter: drop-shadow(0px 0px 2px #074a6e); */
}


.application_form_card {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.application_form_head {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    color: #074a6e;
    font-weight: 600;
    font-size: 20px;
    width: 100%;
}

.application_form_head_bottom_line {
    height: 20px;
    width: 80%;
    margin: 0 auto;
    margin-top: 10px;
}

.application_form_head_bottom_line hr {
    color: #074a6e;
    background-color: #074a6e;
}

.annuitant_head_root {
    margin-top: 15px;
    display: flex;
    width: 80%;
    font-size: 15px;
    font-weight: 600;
    justify-content: space-between;
    align-items: center;
    color: #074a6e;
}

#head_root {
    width: 100%;
    margin-top: 15px;
    display: flex;
    font-size: 15px;
    font-weight: 600;
    justify-content: space-between;
    align-items: center;
    color: #074a6e;
    /* margin-bottom: 20px; */
}

.annuitant_input_fields {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 80%;
}

.annuitant_fullname_and_ssn_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_gender_and_dateofbirth_row,
.annuitant_streetaddress_and_zip_row,
.annuitant_city_and_state_row,
.annuitant_phone_and_email_row,
.annuitant_fullname_and_ssn_row {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

/* .annuitant_dateofbirth_field{
    height: 0em;
} */
::placeholder {
    color: #B3B3B3;
    text-align: left;
}

.annuitant_gender_selection {
    min-width: 215px !important;
    border: none;
    width: 50% !important;
}

.annuitant_dateofbirth_field {
    display: flex;
    justify-content: flex-start;

}

.annuitant_gender_selection_gender1 {
    width: 25% !important;
}

.annuitant_gender_selection_gender2 {
    width: 95%;
}

.annuitant_gender_selection_gender3 {
    width: 100% !important;
}

.annuitant_dateofbirth_field fieldset {
    /* min-width: 300px; */
    border: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid #B3B3B3 !important;
}

.annuitant_gender_and_dateofbirth_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_streetaddress_and_zip_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_city_and_state_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

/* .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper{
    max-width: 360px !important;
} */
.annuitant_phone_and_email_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.annuitant_owner_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    flex-direction: column;
}

.left_section {
    width: 50%;
    display: flex;
    justify-content: flex-start;
}

.annuitant_gender_selection .annuitant_dateofbirth_field {
    width: 100%;
}

.input_field_width {
    min-width: 95% !important;

}

.date_field {
    width: 100%;
    border: 2px solid red;

}

/* .annuitant_head_root{
    margin-bottom: 10px;
} */

.right_section {
    display: flex;
    justify-content: flex-end;
    width: 50%;
}

.nor_states_select {
    display: flex;
    justify-content: flex-end;
    width: 55%;
    /* margin-left: 10px; */
}

/* .owner_replacement_first_question{
    font-family: sans-serif;
} */

.plan_rider_section {
    width: 80%;
    margin-top: 10px;
    margin-bottom: -10px;
}

.rider_section {
    margin-top: 10px;
}

.beneficiaries_section1 {
    margin-bottom: 10px;
}

.plan_rider_checkboxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: -15px !important;
}

.beneficiaries_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    flex-direction: column;
    margin-top: 10px !important;

}

.annuitant_dateofbirth_field label {
    margin-top: 15px;
}

.annuitant_dateofbirth_field input {
    padding-left: 0;
    padding-right: 14px;
    padding-bottom: 6px;
    margin-top: 10px;
}

.annuitant_dateofbirth_field {
    margin-bottom: 8px;
}

.annuitant_dateofbirth_field button {
    padding-bottom: 0px;
    padding-top: 21px;

}

.beneficiaries_section_fields {
    width: 100% !important;
    padding-top: 15px;
}

.beneficiaries_section_row_1,
.beneficiaries_section_row_2,
.beneficiaries_section_row_3 {
    padding-right: 10px;
}

.beneficiaries_section_row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding-bottom: 10px;
}

.annuitant_streetaddress_and_zip_row,
.annuitant_gender_and_dateofbirth_row,
.annuitant_fullname_and_ssn_row,
.annuitant_city_and_state_row,
.annuitant_phone_and_email_row,
.policy_company_number_array_fields_row,
.policy_company_number_array_fields_row_2 {
    padding-top: 10px;
}

.isContractsHasBeenInvolved {
    width: 80%;
}

.req_fields label::after {
    content: '*';
    color: red;
}

.owner_replacement_not_required input {
    padding: 10px 14px !important;
    font-size: 15px !important;
}

/* .not_required input {
    padding: 10px 14px !important;
    font-size: 15px !important;
} */

.not_required fieldset {
    border: none !important;
}

.not_required label::after {
    content: '';
}

.ptqs_qualified_options>div>label,
.ptqs_non_qualified_options>div>label {
    margin-right: 50px !important;
}

.ptqs_non_qualified_options,
.ptqs_qualified_options {
    padding: 0 !important;
}

.annuitant_head_root:hover {
    cursor: pointer !important;
}

.section_open_close:hover {
    cursor: pointer;
}


.owner_replacement_question_margin_top {
    margin-top: 15px;
}

/* .policy_company_number_array_fields {
    margin-top: -10px;
} */
@media only screen and (min-width: 821px) and (max-width: 3000px) {
    .application_form {
        border: 2px solid #074a6e;
        margin-top: 70px !important;
    }
}

@media only screen and (min-width: 1000px) and (max-width: 3000px) {
    .dateCloseIcon {
        display: none !important;
    }
}

@media only screen and (min-width: 821px) and (max-width: 1050px) {
    .application_form {
        width: 95% !important;
        margin-top: 80px !important;

    }

    .owner_replacement_question_main {
        margin-top: 15px;
        margin-bottom: -10px;
    }

    .new_application_form .btn_send_this_link_to_applicant {
        display: flex;
        justify-content: center;
        margin-top: 5px;
    }

    .application_form .application_form_card .application_form_head {
        margin-top: 30px;
    }

    .application_form .application_form_card .application_form_head .application_form_head_bottom_line {
        width: 90%;
    }

    .application_form .application_form_card .annuitant_input_fields,
    .application_form .application_form_card .annuitant_owner_section,
    .application_form .application_form_card .plan_rider_section,
    .application_form .application_form_card .beneficiaries_section,
    .application_form .application_form_card .premium_and_tax_qualification_status_main {
        width: 88%;
    }

    .application_form .application_form_card .owner_replacement_question,
    .application_form .application_form_card .annuitant_head_root {
        width: 88% !important;

    }

    .application_form .application_form_card .annuitant_head_root {
        width: 88%;

    }

    .application_form .application_form_card .application_form_head .application_form_head_1 {
        font-size: revert;
    }

    .application_form .application_form_card .annuitant_input_fields .annuitant_gender_and_dateofbirth_row .annuitant_dateofbirth_field .input_field_width {
        width: 500px;
    }

    .application_form .application_form_card .safeguard_plus_width {
        width: 88% !important;
    }
}


@media only screen and (min-width:1050px) and (max-width: 1300px) {
    .application_form {
        border: 0 !important;
        margin-top: 80px;
    }
}

@media screen and (min-width:401px) and (max-width: 820px) {
    .application_form {
        margin-top: 70px !important;
    }

    .application_form .application_form_card .annuitant_owner_section_bottom {
        margin-bottom: 10px !important;
    }

    .not_required input {
        font-size: 14px !important;
    }
}

@media screen and (min-width:350px) and (max-width:820px) {
    .ptqs_qualified_box {
        width: 95% !important;
    }
}


@media only screen and (min-width:0px) and (max-width: 420px) {
    .application_form {
        width: 95% !important;
        margin-top: 30px !important;
    }

    .not_required input {
        font-size: 13px !important;
    }

    /* .application_link_button{
            left: 42.5% !important;
        } */
    .beneficiaries_section_row {
        padding-bottom: 0px !important;
    }

    .application_form .application_form_card .annuitant_input_fields .annuitant_gender_and_dateofbirth_row .annuitant_dateofbirth_field {
        width: 95%;
        margin-bottom: 0;
    }

    .application_form .application_form_card .header_1 {
        margin-top: 0px !important;
    }

    .application_form .application_form_card .annuitant_owner_section .annuitant_gender_and_dateofbirth_row .annuitant_dateofbirth_field .input_field_width {
        width: 90%;
    }

    .application_form .application_form_card .premium_and_tax_qualification_status_main .amount_to_paid_with_application {
        margin: 15px 0px !important;
    }

    .annuitant_streetaddress_and_zip_row .right_section,
    .annuitant_gender_and_dateofbirth_row .right_section,
    .annuitant_fullname_and_ssn_row .right_section,
    .annuitant_city_and_state_row .right_section,
    .annuitant_phone_and_email_row .right_section,
    .beneficiaries_section_row .right_section,
    .policy_company_number_array_fields_row .right_section,
    .policy_company_number_array_fields_row_2 .right_section {
        padding-top: 10px;
    }

    .application_form .application_form_card .owner_replacement_question_main {
        margin-top: 15px;
    }

    /* .application_form .application_form_card   .owner_replacement_question .policy_company_number_array_fields{
    margin-top: -20px;
} */
    .application_form .application_form_card .annuitant_input_fields .annuitant_gender_and_dateofbirth_row .annuitant_dateofbirth_field .input_field_width {
        width: 100%;
    }

    .application_form .application_form_card .annuitant_input_fields {
        width: 95%;
    }

    .application_form .application_form_card .annuitant_input_fields .annuitant_fullname_and_ssn_row,
    .application_form .application_form_card .annuitant_input_fields .annuitant_gender_and_dateofbirth_row,
    .application_form .application_form_card .annuitant_input_fields .annuitant_streetaddress_and_zip_row,
    .application_form .application_form_card .annuitant_input_fields .annuitant_city_and_state_row,
    .application_form .application_form_card .annuitant_input_fields .annuitant_phone_and_email_row {
        width: 100%;
        justify-content: center;
        flex-direction: column;
        margin-top: 5px;
        margin-bottom: -5px;
    }

    .application_form .application_form_card .application_form_head {
        margin-top: 20px;
    }

    .application_form .application_form_card .annuitant_owner_section {
        width: 100%;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .application_form .application_form_card .annuitant_head_root {
        margin-top: 20px;
        width: 90%;
        font-size: 14px;
    }

    .application_form .application_form_card .beneficiaries_section .beneficiaries_section_fields .beneficiaries_section_row .beneficiaries_section_row_1,
    .application_form .application_form_card .beneficiaries_section .beneficiaries_section_fields .beneficiaries_section_row .beneficiaries_section_row_2,
    .application_form .application_form_card .beneficiaries_section .beneficiaries_section_fields .beneficiaries_section_row .beneficiaries_section_row_3,
    .application_form .application_form_card .beneficiaries_section .beneficiaries_section_fields .beneficiaries_section_row .annuitant_gender_selection {
        width: 100% !important;
        margin-top: 10px !important;

    }

    .application_form .application_form_card .policy_company_number_array_fields .policy_company_number_array_fields_row {
        width: 100%;
        margin-top: 0px !important;

    }

    .application_form .application_form_card .safeguard_plus_qnnuity_wth_guarantee_period {
        width: 90% !important;
        margin-top: 15px !important;
    }

    /* .owner_replacement_question{
       margin-bottom: -27px !important;
   }   */

    .application_form .application_form_card .annuitant_input_fields .annuitant_gender_and_dateofbirth_row.annuitant_dateofbirth_field .input_field_width {
        width: 97% !important;
    }


    /* .policy_company_number_array_fields_row_1 .css-1u3bzj6-MuiFormControl-root-MuiTextField-root ,
    .application_form .application_form_card  .policy_company_number_array_fields  .policy_company_number_array_fields_row  
    .policy_company_number_array_fields_row_2 .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
        width: 100% !important;
     }
        .application_form .application_form_card  .beneficiaries_section  .beneficiaries_section_fields .beneficiaries_section_row 
         .annuitant_gender_selection .css-1nrlq1o-MuiFormControl-root .css-1ft4ntv-MuiInputBase-root-MuiInput-root{
             width: 100%;
    } */
    .new_application_form .btn_send_this_link_to_applicant {
        display: flex;
        justify-content: center;
        margin-top: -5px;
    }

    .application_form .application_form_card .beneficiaries_section,
    .application_form .application_form_card .plan_rider_section,
    .application_form .application_form_card .premium_and_tax_qualification_status_main,
    .application_form .application_form_card .owner_replacement_question {
        width: 90% !important;
        margin-top: 10px;
    }

    .application_form .application_form_card .owner_replacement_question {
        margin-top: 5px !important;
    }

    .application_form .application_form_card .premium_and_tax_qualification_status_main .ptqs_row .MuiFormGroup-root .ptqs_qualified_box {
        margin-top: 10px;
    }

    .application_form .application_form_card .annuitant_input_fields .annuitant_gender_and_dateofbirth_row .annuitant_dateofbirth_field .input_field_width label {
        left: -11px !important;
        bottom: 20px;
    }

    .application_form .application_form_card .annuitant_input_fields .annuitant_fullname_and_ssn_row .right_section,
    .application_form .application_form_card .annuitant_input_fields .annuitant_fullname_and_ssn_row .left_section,
    .application_form .application_form_card .annuitant_input_fields .annuitant_gender_and_dateofbirth_row .left_section,
    .application_form .application_form_card .annuitant_input_fields .annuitant_gender_and_dateofbirth_row .right_section,
    .application_form .application_form_card .annuitant_input_fields .annuitant_streetaddress_and_zip_row .left_section,
    .application_form .application_form_card .annuitant_input_fields .annuitant_streetaddress_and_zip_row .right_section,
    .application_form .application_form_card .annuitant_input_fields .annuitant_city_and_state_row .left_section,
    .application_form .application_form_card .annuitant_input_fields .annuitant_city_and_state_row .right_section,
    .application_form .application_form_card .annuitant_input_fields .annuitant_phone_and_email_row .left_section,
    .application_form .application_form_card .annuitant_input_fields .annuitant_phone_and_email_row .right_section {
        justify-content: center;
        width: 95% !important;
    }

    .application_form .application_form_card .annuitant_owner_section .annuitant_fullname_and_ssn_row,
    .application_form .application_form_card .annuitant_owner_section .annuitant_gender_and_dateofbirth_row,

    .application_form .application_form_card .annuitant_owner_section .annuitant_city_and_state_row,
    .application_form .application_form_card .annuitant_owner_section .annuitant_phone_and_email_row {
        width: 95%;
        justify-content: center;
        flex-direction: column;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
        /* margin-top: -5px;
    margin-bottom: -5px; */
    }

    .application_form .application_form_card .annuitant_owner_section .annuitant_streetaddress_and_zip_row {
        width: 95%;
        justify-content: center;
        flex-direction: column;
        margin-top: -8px !important;
        margin-bottom: 0px !important;
    }

    .application_form .application_form_card .annuitant_owner_section .annuitant_fullname_and_ssn_row .right_section,
    .application_form .application_form_card .annuitant_owner_section .annuitant_fullname_and_ssn_row .left_section,
    .application_form .application_form_card .annuitant_owner_section .annuitant_gender_and_dateofbirth_row .right_section,
    .application_form .application_form_card .annuitant_owner_section .annuitant_streetaddress_and_zip_row .left_section,
    .application_form .application_form_card .annuitant_owner_section .annuitant_streetaddress_and_zip_row .right_section,
    .application_form .application_form_card .annuitant_owner_section .annuitant_city_and_state_row .left_section,
    .application_form .application_form_card .annuitant_owner_section .annuitant_city_and_state_row .right_section,
    .application_form .application_form_card .annuitant_owner_section .annuitant_phone_and_email_row .left_section,
    .application_form .application_form_card .annuitant_owner_section .annuitant_phone_and_email_row .right_section {
        justify-content: center;
        width: 95%;
    }

    .application_form .application_form_card .annuitant_owner_section .annuitant_gender_and_dateofbirth_row .left_section {
        justify-content: center;
        padding-left: 1px !important;
        width: 95% !important;
    }

    .application_form .application_form_card .annuitant_owner_section .annuitant_gender_and_dateofbirth_row .left_section .input_field_width {
        width: 100%;
    }

    .application_form .application_form_card .annuitant_owner_section .annuitant_gender_and_dateofbirth_row .right_section {
        justify-content: center;
        width: 95% !important;
        padding-top: 0px;
    }

    .application_form .application_form_card .beneficiaries_section .beneficiaries_section_fields .beneficiaries_section_row {
        width: 100%;
        justify-content: center;
        flex-direction: column;
    }

    .policy_company_number_array_fields_row {
        margin-bottom: 10px;
    }

    .policy_company_number_array_fields_row_1 label {
        font-size: 0.901rem;
    }

    /* .application_form .application_form_card  .beneficiaries_section  .beneficiaries_section_fields .beneficiaries_section_row .MuiBox-root .css-wh8j8b-MuiFormControl-root-MuiTextField-root,
    .application_form .application_form_card  .beneficiaries_section  .beneficiaries_section_fields .beneficiaries_section_row .MuiBox-root .css-1iezzy6-MuiFormControl-root-MuiTextField-root ,
    .application_form .application_form_card  .beneficiaries_section  .beneficiaries_section_fields .beneficiaries_section_row .MuiBox-root .css-xmbdxf-MuiFormControl-root-MuiTextField-root ,
    .application_form .application_form_card  .beneficiaries_section  .beneficiaries_section_fields .beneficiaries_section_row .annuitant_gender_selection .input_field_width 
    {
        width: 100% ;
    }  */

    /* .application_form .application_form_card  .beneficiaries_section  .beneficiaries_section_fields .owner_replacement_question 
    .policy_company_number_array_fields .policy_company_number_array_fields_row .css-1u3bzj6-MuiFormControl-root-MuiTextField-root 
    {
        width: 100%;
    } */
    /* 
    .application_form {
        margin-top: 15px;
    }
    .annuitant_head_root {
        margin-top: 0px;
        width: 90%;
        font-size: 13px;
    }
    
    .annuitant_gender_selection.left_section{
        width: 95%;
        margin-left: 20px;
    }
    .MuiTypography-root {
        margin: 0;
        width: 100%;
    }

    
    

    .css-wh8j8b-MuiFormControl-root-MuiTextField-root{
        width: 100%;
    }
    #head_root {
        font-size: 13px;
        padding-bottom: 10px;
    }
    element.style .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root 
    {
        margin: 0px 5px;
        width: 100%;
    }
     */

}

/* 
@media screen and (min-width:1050px) and (max-width: 1300px)
{
    .application_form {
        width: 95% !important;
        margin-top: 20px !important;
        
    }
   
} */
@media only screen and (min-width: 1050px) and (max-width: 1300px) {
    .application_form {
        width: 95% !important;
        margin-top: 100px !important;
        border: 2px solid #074a6e !important;
    }

    .application_form_head {
        margin-top: 30px;
    }
}

@media only screen and (min-width: 401px) and (max-width: 820px) {
    .application_form {
        width: 85% !important;
        margin-top: 30px !important;

    }

    .application_form_head {
        margin-top: 20px;
    }

    .application_form .application_form_card .premium_and_tax_qualification_status_main .ptqs_row .MuiFormGroup-root .ptqs_qualified_box {
        margin-top: 15px !important;
    }
}

@media only screen and (min-width: 421px) and (max-width: 820px) {
    .application_form .application_form_card .beneficiaries_section .beneficiaries_section_fields .beneficiaries_section_row {
        width: 100%;
        justify-content: center;
        flex-direction: column;
    }

    .application_form .application_form_card .beneficiaries_section .beneficiaries_section_fields .beneficiaries_section_row .beneficiaries_section_row_1,
    .application_form .application_form_card .beneficiaries_section .beneficiaries_section_fields .beneficiaries_section_row .beneficiaries_section_row_2,
    .application_form .application_form_card .beneficiaries_section .beneficiaries_section_fields .beneficiaries_section_row .beneficiaries_section_row_3,
    .application_form .application_form_card .beneficiaries_section .beneficiaries_section_fields .beneficiaries_section_row .annuitant_gender_selection {
        width: 100% !important;
        margin-top: 10px !important;

    }

    .beneficiaries_field_width,
    .annuitant_gender_selection_gender2 {
        width: 100% !important;
    }
}

@media screen and (min-width:1300px) and (max-width:1613px) {
    .ptqs_qualified_box {
        margin-top: 10px;
    }
}